import React, { useState, useEffect } from "react";
import MiscInput from "./SubComponent/MiscInput";
import { useDispatch, useSelector } from "react-redux";
import { setMiscellaneousComments } from "../redux/actions/ConstructionOptions";

function Miscellaneous() {
  const dispatch = useDispatch();
  const { miscComments } = useSelector((state) => state.constructionOptions);

  const [count, setCount] = useState([]);
  const [misc, setMisc] = useState([]);

  useEffect(() => {
    if (miscComments?.length > 0) {
      // miscComments.forEach(comment => addOption());
      let newCount = miscComments.map((comment, i) => i);
      setCount(newCount);
    }

    setMisc(miscComments);
  }, []);

  const addOption = () => {
    setCount([...count, count.length]);
  };

  useEffect(() => {
    if (misc?.length > 0) {
      dispatch(setMiscellaneousComments(misc));
    }
  }, [misc]);

  const handleDelete = (index, id) => {
    const newCount = [...count];
    newCount.splice(index, 1);
    setCount(newCount);
    const newMisc = misc.filter((x) => x.id !== id);
    setMisc(newMisc);
  };

  return (
    <div>
      {count.map((count, i) => (
        <MiscInput
          key={count}
          count={count}
          index={i}
          handleDelete={handleDelete}
          misc={misc}
          setMisc={setMisc}
        />
      ))}
      <div className="row d-flex justify-content-center align-items-center mt-3">
        <button className="btn btn-lg btn-dark" onClick={addOption}>
          Add Miscellaneous Option
        </button>
      </div>
    </div>
  );
}

export default Miscellaneous;
