import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import ErrorModal from "./SubComponent/ErrorModal";
import API from "../utils/API";

function ResetRequest() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [showError, setShowError] = useState(false);

  const handleReset = () => {
    API.userSearch(email).then((info) => {
      if (info.data.data.length === 0) {
        setMessage("An account does not exist for this email");
        setShowError(true);
      } else {
        const emailData = {
          fullName: `${info.data.data[0].firstName} ${info.data.data[0].lastName}`,
          email: email,
          subject: "PASSWORD RESET REQUESTED",
          message: `You are receiving this email because you requested a password reset. \n 
              Reset your password here https://slrprototype.netlify.app/reset/${info.data.data[0]._id} \n 
              If you did not make this request then please ignore this email`,
        };
        API.sendEmail(emailData);
        setMessage("Password reset request sent");
        setShowError(true);
      }
    });
  };

  const styles = {
    marginBottom: {
      marginBottom: "1rem",
    },
    marginBottom2: {
      marginBottom: "1.3rem",
    },
    form: {
      width: "80vw",
      maxWidth: "800px",
      margin: "0 auto",
      borderRadius: "10px",
    },
  };

  return (
    <div className="container mt-5">
      <ErrorModal
        showError={showError}
        setShowError={setShowError}
        message={message}
      />
      <form className="proposal-container" style={styles.form}>
        <h1>Reset Password</h1>
        <div className="row" style={{ width: "105%" }}>
          <div className="col-12">
            <TextField
              className="input-width"
              label="Enter your email"
              style={styles.marginBottom2}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></TextField>
          </div>
        </div>
      </form>
      <div className="row d-flex justify-content-around">
        <button className="change-order mb-4" onClick={handleReset}>
          Send Email
        </button>
      </div>
    </div>
  );
}

export default ResetRequest;
