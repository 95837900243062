import React from 'react';
import './GlobalFooter.css';
import OnntekLogo from '../img/OnntekLogo.png';

const GlobalFooter = () => {
  return (
    <div className='footerDiv'>
      <p>
        powered by
        <img
          className='onntekLogo'
          src={OnntekLogo}
          alt='onntek_logo.png'
        ></img>
      </p>
    </div>
  );
};

export default GlobalFooter;
