import React, { useState } from 'react';

function BackButton({ setBack, handleNext }) {
  const styles = {
    button: {
      height: '75px',
      width: '200px',
      fontSize: '30px',
      boxShadow: '2px 2px 20px -10px black',
    },
  };

  return (
    <div className='row d-flex justify-content-around'>
      <button
        className='back-button mt-5 mb-3'
        style={styles.button}
        onClick={() => setBack(true)}
      >
        <i className='fas fa-arrow-left'></i> Back
      </button>
      <button
        className='submit-button mt-5 mb-3'
        style={styles.button}
        onClick={handleNext}
      >
        Next <i className='fas fa-arrow-right'></i>
      </button>

      {/* <button
        className="btn btn-warning btn-lg mt-5 mb-3 font-weight-bold"
        style={styles.button}
        onClick={() => setBack(true)}
      >
        Back
      </button>
      <button
        className="btn btn-success btn-lg mt-5 mb-3 font-weight-bold"
        style={styles.button}
        onClick={handleNext}
      >
        Next
      </button> */}
    </div>
  );
}

export default BackButton;
