import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import BaseMaterialType from "./SubComponent/BaseMaterialType";
import Roof from "./Roof";
import { setBaseType } from "../redux/actions/MaterialsActions";
import ImageModal from "./ImageModal";
import BackButton from "./SubComponent/BackButton";
import RoofOption from "./RoofOption";
import { showErrorModal } from "../redux/actions/FormActions";

function BaseMaterial() {
  // Connection which allows this component to send information to redux
  const dispatch = useDispatch();

  // Grabs the current roofType from redux
  const { roofType, base } = useSelector((state) => state.materials);

  // State to hold the appropriate array of base materials
  const [options, setOptions] = useState([]);

  // State to hold the selected base material
  const [material, setMaterial] = useState(null);

  // State that determines if the next component should be revealed or not
  const [showNext, setShowNext] = useState(false);
  const [back, setBack] = useState(false);

  // State that determines if the image modal should be revealed or not
  const [showImage, setShowImage] = useState(false);

  // Functions that handle the showing and closing of the image modal
  const handleClose = () => setShowImage(false);
  const handleShow = () => setShowImage(true);

  // The array of base materials if the roofType is flat
  const flatTypes = [
    {
      type: "Wood",
      image: "https://pbs.twimg.com/media/D-EK9ZOWwAAydCs.jpg",
    },
    {
      type: "Concrete",
      image: "https://5.imimg.com/data5/LA/YJ/MY-3299963/concrete-roof-waterproofing-500x500.jpg",
    },
    {
      type: "Steel",
      image: "https://poolenclosuresinc.com/sites/default/files/BW%20PC%20PICS%20005%20BEFORE%20GLAZING.jpg",
    },
  ];

  // The array of base materials if the roofType is sloped
  const slopeTypes = [
    {
      type: "Shingle",
      image: "https://www.pickardroofing.com/hubfs/blog-shingles.jpg",
    },
    {
      type: "Tile",
      image:
        "https://dynastybuildingsolutions.com/sarasota/wp-content/uploads/sites/3/2020/05/tile.jpg",
    },
    {
      type: "Metal",
      image:
        "https://www.metalsales.us.com/wp-content/uploads/2020/02/2012-11-28_129_DorianPhotoInc.jpg",
    },
  ];

  // Function that determines which of the above arrays to set as the material options
  useEffect(() => {
    if (roofType === "Flat") {
      setOptions(flatTypes);
    } else {
      setOptions(slopeTypes);
    };
    if (base) {
      setMaterial(base);
    };
  }, []);

  // Function that sends the selected base material to redux
  useEffect(() => {
    dispatch(setBaseType(material));
  }, [material]);

  const handleNext = () => {
    if (material) {
      setShowNext(true);
    } else {
      dispatch(showErrorModal(true));
    };
  };

  if (back) {
    return <RoofOption type={roofType} />
  };

  if (showNext) {
    return <Roof />;
  };

  return (
    <div className="container my-5">
      <h1 className="text-center mt-5">
        Base Material{" "}
      </h1>
      <div className="row align-items-end text-center d-flex justify-content-center">
        {/* Maps through the selected options array and creates a BaseMaterialType component for each option */}
        {options.map((type) => (
          <BaseMaterialType
            type={type.type}
            key={type.type}
            material={material}
            image={type.image}
            setMaterial={setMaterial}
          />
        ))}
      </div>
      <ImageModal show={showImage} handleClose={handleClose} images={options} />
      {/* The Roof component will be revealed only when the showNext state is set to true */}
      {/* {showNext ? <Roof /> : null} */}
      <BackButton setBack={setBack} handleNext={handleNext}/>
    </div>
  );
}

export default BaseMaterial;
