import React from 'react';
import Modal from 'react-bootstrap/Modal';

function SignatureModal({ show, setShow, handleComplete }) {
  return (
    <Modal centered size='md' show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Southland Roofing</Modal.Title>
      </Modal.Header>
      <h2 className='text-center p-5'>
        By selecting "I Accept", you consent to the legally binding terms and
        conditions of this Agreement.
      </h2>
      <div className='btnDiv' style={{ textAlign: 'center' }}>
        <div
          className='btn btn-lg btn-primary mb-4'
          style={{ width: '50%' }}
          onClick={handleComplete}
        >
          I ACCEPT
        </div>
      </div>
    </Modal>
  );
}

export default SignatureModal;
