import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import LogInfoModal from './LogInfoModal';

const ProposalLogItem = ({ proposal }) => {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className='card text-center p-3 mb-3'>
      <h3>{proposal.projectAddress}</h3>
      <p>{proposal.date.substring(0, 10)}</p>
      <div
        className='btn btn-dark btn-md my-1'
        style={buttonStyle}
        onClick={handleShow}
      >
        More Info
      </div>
      <LogInfoModal show={show} handleClose={handleClose} proposal={proposal} />
    </div>
  );
};

const buttonStyle = {
  width: '8rem',
  margin: '0 auto',
};

export default ProposalLogItem;
