const constructionOptionsReducer = (state = {}, action) => {
  let newState;
  switch (action.type) {
    case 'SET_CONSTRUCTION_OPTIONS':
      newState = {
        ...state,
        allOptions: action.payload,
      };
      return newState;
    case 'SET_MISCELLANEOUS_COMMENTS':
      if (action.payload === null) {
        newState = { ...state };
        delete newState.miscComments;
      } else {
        newState = {
          ...state,
          miscComments: action.payload,
        };
      }
      return newState;
    case 'SET_SINGLE_OR_TORCH':
      if (action.payload === null) {
        newState = { ...state };
        delete newState.singleOrTorch;
      } else {
        newState = {
          ...state,
          singleOrTorch: action.payload,
        };
      }
      return newState;
    case 'SET_PITCH':
      if (action.payload === null) {
        newState = { ...state };
        delete newState.pitch;
      } else {
        newState = {
          ...state,
          pitch: action.payload,
        };
      }
      return newState;
    case 'SET_PROJECT_TYPE':
      if (action.payload === null) {
        newState = { ...state };
        delete newState.projectType;
      } else {
        newState = {
          ...state,
          projectType: action.payload,
        };
      }
      return newState;
    case 'SET_DEMO_SQUARES':
      if (action.payload === null) {
        newState = { ...state };
        delete newState.demoSquares;
      } else {
        newState = {
          ...state,
          demoSquares: action.payload,
        };
      }
      return newState;
    case 'SET_LAYERS':
      if (action.payload === null) {
        newState = { ...state };
        delete newState.layers;
      } else {
        newState = {
          ...state,
          layers: action.payload,
        };
      }
      return newState;
    case 'SET_CONSTRUCTION_SQUARES':
      if (action.payload === null) {
        newState = { ...state };
        delete newState.constructionSquares;
      } else {
        newState = {
          ...state,
          constructionSquares: action.payload,
        };
      }
      return newState;
    case 'SET_AREA':
      if (action.payload === null) {
        newState = { ...state };
        delete newState.area;
      } else {
        newState = {
          ...state,
          area: action.payload,
        };
      }
      return newState;
    case 'GET_TOTAL':
      newState = {
        ...state,
        priceInfo: action.payload,
      };
      return newState;
    case 'SET_DESCRIPTIONS':
      newState = {
        ...state,
        allOptions: action.payload,
      };
      console.log(newState);
      return newState;
    case 'CONSTRUCTION_RESTART':
      return {};
    default:
      return state;
  }
};

export default constructionOptionsReducer;
