import React, { useState, useEffect, useRef } from "react";
import InputModal from "./InputModal";
import { useSelector } from "react-redux";

function SingleOption({
  option,
  measurement,
  description,
  state,
  setState,
  i,
  price,
}) {
  const { allOptions, constructionSquares } = useSelector(
    (state) => state.constructionOptions
  );
  const { error } = useSelector((state) => state.formDetails);
  // State that holds the option information
  const [input, setInput] = useState({
    type: option,
    measurementType: measurement,
    measure: "",
    description,
    price,
  });

  // State that holds the appropriate font-weight for this option
  const [fontweight, setFontweight] = useState("normal");
  // State that holds the background color for this option
  const [bgcolor, setBgcolor] = useState("none");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [inputColor, setInputColor] = useState("white");

  const checkbox = useRef();

  useEffect(() => {
    if (allOptions) {
      allOptions.forEach((material) => {
        if (material.type === option) {
          setInput(material);
          checkbox.current.checked = true;
        }
      });
    }
  }, []);

  // Function that handles the option being checked or not
  const handleCheck = () => {
    // If the state already contains the selected option, this will remove it and change the font-weight to normal
    if (state) {
      let newState;
      let flag = false;
      state.forEach((x) => {
        if (x.type === option) {
          flag = true;
        }
      });
      if (flag) {
        newState = state.filter((x) => x.type !== option);
      } else {
        // Inserts this option into the state
        newState = [...state, input];
        // handleShow();
      }
      setState(newState);
    } else {
      setState([input]);
      // handleShow();
    }
  };

  useEffect(() => {
    if (state) {
      let flag = false;
      state.forEach((material) => {
        if (material.type === option) {
          flag = true;
        }
      });
      if (flag) {
        setFontweight("bold");
      } else {
        setFontweight("normal");
        setInput({ ...input, measure: "" });
        if (inputColor === "red") {
          setInputColor("white");
        }
      }
    }
  }, [state]);

  // Function that updates this option's input in the global state whenever the input text changes
  useEffect(() => {
    if (input.measure !== "") {
      checkbox.current.checked = true;
      handleCheck();
      if (inputColor === "red") {
        setInputColor("white");
      }
    }
    if (state) {
      let flag = false;
      state.forEach((x) => {
        if (x.type === option) {
          flag = true;
        }
      });
      if (flag) {
        let newState = state.filter((x) => x.type !== option);
        newState = [...newState, input];
        setState(newState);
      }
    }
  }, [input]);

  useEffect(() => {
    if (checkbox.current.checked === true) {
      if (measurement === "Area") {
        setInput({ ...input, measure: constructionSquares });
      }
    }
  }, [checkbox?.current?.checked]);

  // Function that determines if the index of this option is even or not, and changes the background color accordingly
  useEffect(() => {
    if (i % 2 === 0) {
      setBgcolor("lightgray");
    }
  }, []);

  useEffect(() => {
    if (error) {
      if (state) {
        state.forEach((material) => {
          if (material.type === option) {
            if (input.measure === "" || input.measure <= 0) {
              setInputColor("red");
            }
          }
        });
      }
    }
  }, [error]);

  const styles = {
    check: {
      marginTop: "34px",
      zIndex: 2,
    },
    text: {
      fontWeight: `${fontweight}`,
      backgroundColor: `${bgcolor}`,
      fontSize: "30px",
    },
  };
  return (
    <div className="row align-items-center pl-lg-1" style={styles.text}>
      <div className="col-12 col-lg-7">
        <div className="row d-flex justify-content-center justify-content-lg-start">
          {/* <div className="col-1 d-flex justify-content-start"> */}
          <input
            type="checkbox"
            className="mr-2"
            style={styles.check}
            ref={checkbox}
            onChange={handleCheck}
          />
          {/* </div> */}
          <div className="col-11 ml-n3 d-flex justify-content-start">
            <p className="mt-3 mr-lg-5">{option}</p>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-5">
        <div className="row d-flex justify-content-center justify-content-lg-end">
          <label className="mt-1 mt-lg-2 mr-1">{measurement}: </label>
          <input
            type="number"
            min="0"
            value={input.measure}
            className="mb-3 mb-lg-0"
            style={{ backgroundColor: inputColor }}
            onChange={(e) => setInput({ ...input, measure: e.target.value })}
          />
        </div>
      </div>
      <InputModal
        show={show}
        handleClose={handleClose}
        title={option}
        input={input}
        setInput={setInput}
        label={measurement}
      />
    </div>
  );
}

export default SingleOption;
