import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import API from "../utils/API";
import EditDescription from "./SubComponent/EditDescription";
import { setCurrentSelections } from "../redux/actions/CurrentSelections";

function EditSelections() {
  const dispatch = useDispatch();
  const selections = useSelector((state) => state.currentSelections.selections);

  let keys;

  useEffect(() => {
    API.getSelections().then((res) => {
      dispatch(setCurrentSelections(res.data.data));
    });
  }, []);

  return (
    <div>
      {selections ? (
        <div>
          <div style={{ visibility: "hidden" }}>
            {(keys = Object.keys(selections))}
          </div>
          <EditDescription
            title={'2" Perimeter Flashing'}
            selection={selections.perimeterFlashing2}
            objKey={keys[0]}
          />
          <EditDescription
            title={'3" Perimeter Flashing'}
            selection={selections.perimeterFlashing3}
            objKey={keys[1]}
          />
          <EditDescription
            title={"Custom Perimeter Flashing"}
            selection={selections.perimeterFlashingC}
            objKey={keys[2]}
          />
          <EditDescription
            title={'1" ISO Insulation'}
            selection={selections.isoInsulation1}
            objKey={keys[3]}
          />
          <EditDescription
            title={'2" ISO Insulation'}
            selection={selections.isoInsulation2}
            objKey={keys[4]}
          />
          <EditDescription
            title={'3" ISO Insulation'}
            selection={selections.isoInsulation3}
            objKey={keys[5]}
          />
          <EditDescription
            title={'4" ISO Insulation'}
            selection={selections.isoInsulation4}
            objKey={keys[6]}
          />
          <EditDescription
            title={"AC Pads"}
            selection={selections.acPad}
            objKey={keys[7]}
          />
          <EditDescription
            title={"Skylight"}
            selection={selections.skylight}
            objKey={keys[8]}
          />
          <EditDescription
            title={"Flat Drain"}
            selection={selections.flatDrain}
            objKey={keys[9]}
          />
          <EditDescription
            title={"Recessed Drain"}
            selection={selections.recessedDrain}
            objKey={keys[10]}
          />
          <EditDescription
            title={"Roof Hatch"}
            selection={selections.roofHatch}
            objKey={keys[11]}
          />
          <EditDescription
            title={"Penetrations"}
            selection={selections.penetrations}
            objKey={keys[12]}
          />
          <EditDescription
            title={"Vapor Barrier"}
            selection={selections.vaporBarrier}
            objKey={keys[13]}
          />
          <EditDescription
            title={"Densglass"}
            selection={selections.densglass}
            objKey={keys[14]}
          />
          <EditDescription
            title={"Underlay"}
            selection={selections.underlay}
            objKey={keys[15]}
          />
          <EditDescription
            title={"Scupper 4x4"}
            selection={selections.scupper4x4}
            objKey={keys[16]}
          />
          <EditDescription
            title={"Scupper 4x6"}
            selection={selections.scupper4x6}
            objKey={keys[17]}
          />
          <EditDescription
            title={"Scupper 6x6"}
            selection={selections.scupper6x6}
            objKey={keys[18]}
          />
          <EditDescription
            title={"Walk Pads"}
            selection={selections.walkPads}
            objKey={keys[19]}
          />
          <EditDescription
            title={"Liquid TPO"}
            selection={selections.liquidTPO}
            objKey={keys[20]}
          />
          <EditDescription
            title={"Chimney"}
            selection={selections.chimney}
            objKey={keys[21]}
          />
          <EditDescription
            title={"Chimney Waterproofing"}
            selection={selections.chimneyWaterproofing}
            objKey={keys[22]}
          />
          <EditDescription
            title={"Valley"}
            selection={selections.valley}
            objKey={keys[23]}
          />
          <EditDescription
            title={"Ridge"}
            selection={selections.ridge}
            objKey={keys[24]}
          />
          <EditDescription
            title={"Plywood"}
            selection={selections.plywood}
            objKey={keys[25]}
          />
          <EditDescription
            title={'1" Fiber Insulation'}
            selection={selections.fiberInsulation1}
            objKey={keys[26]}
          />
          <EditDescription
            title={'2" Fiber Insulation'}
            selection={selections.fiberInsulation2}
            objKey={keys[27]}
          />
          <EditDescription
            title={'3" Fiber Insulation'}
            selection={selections.fiberInsulation3}
            objKey={keys[28]}
          />
          <EditDescription
            title={'4" Fiber Insualtion'}
            selection={selections.fiberInsulation4}
            objKey={keys[29]}
          />
          <EditDescription
            title={"Tar Paper Underlay"}
            selection={selections.tarPaperUnderlay}
            objKey={keys[30]}
          />
          <EditDescription
            title={"UV Coating"}
            selection={selections.uvCoating}
            objKey={keys[31]}
          />
          <EditDescription
            title={"1GL Silicone Coating"}
            selection={selections.siliconeCoating1GL}
            objKey={keys[32]}
          />
          <EditDescription
            title={"2GL Silicone Coating"}
            selection={selections.siliconeCoating2GL}
            objKey={keys[33]}
          />
          <EditDescription
            title={"3GL Silicone Coating"}
            selection={selections.siliconeCoating3GL}
            objKey={keys[34]}
          />
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default EditSelections;
