import React, { useState, useEffect } from "react";
import AreaButtons from "./SubComponent/AreaButtons";
import { useDispatch, useSelector } from "react-redux";
import { setArea } from "../redux/actions/ConstructionOptions";
import Options from "./Options";
import BackButton from "./SubComponent/BackButton";
import Color from "./Color";
import { showErrorModal } from "../redux/actions/FormActions";

function Area({type}) {
  // Connection which allows this component to send information to redux
  const dispatch = useDispatch();

  // Grabs the current roofType from redux
  const { roofType } = useSelector((state) => state.materials);
  const { area } = useSelector(state => state.constructionOptions);
  const { data } = useSelector(state => state.formDetails);

  // State that determines if this component should be passed through or not
  const [showArea, setShowArea] = useState(false);

  // State that will hold the selected area
  const [selectedArea, setSelectedArea] = useState(null);

  // State that determines if the Options component should be revealed or not
  const [showOptions, setShowOptions] = useState(false);
  const [back, setBack] = useState(false);

  useEffect(() => {
    if (area) {
      setSelectedArea(area);
    };
  });

  // Function that determines if this component should be passed through or not
  useEffect(() => {
    if (type === "Flat") {
      return setShowArea(true);
    } else {
      setShowArea(false);
    }
  });

  // Function that sends the selected area to redux
  useEffect(() => {
    dispatch(setArea(selectedArea));
  }, [selectedArea]);

  const handleNext = () => {
    if (selectedArea) {
      setShowOptions(true);
    } else {
      dispatch(showErrorModal(true));
    };
  };

  // Array of all the area options
  const areas = [
    {
      size: "45 Mil",
      value: 100
    }, 
    {
      size: "60 Mil",
      value: 80
    }, 
    {
      size: "80 Mil",
      value: 60
    }, 
    {
      size: "90 Mil",
      value: 40
    }, 
    {
      size: "115 Mil",
      value: 20
    }];

    if (back) {
      return <Color />;
    };

    if (showOptions) {
      return <Options roofType={roofType}/>;
    };

  return (
    <div>
      {/* If the showArea state is false, this component will be skipped, but if it is true, it will be shown */}
      {showArea ? (
        <div className="container my-5">
          <h1 className="text-center">Area</h1>
          <div className="row align-items-center text-center justify-content-center">
            {/* Maps through the area array and creates an AreaButtons component for each area */}
            {areas.map((area) => (
              <AreaButtons
                key={area.size}
                area={area.size}
                value={area.value}
                selectedArea={selectedArea}
                setSelectedArea={setSelectedArea}
              />
            ))}
          </div>
          <BackButton setBack={setBack} handleNext={handleNext}/>
        </div>
      ) : (
        <Options roofType={roofType}/>
      )}
    </div>
  );
}

export default Area;
