import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import API from '../utils/API';
import './SignupForm.css';
import { TextField, FormLabel } from '@material-ui/core';
import PhoneInput from 'react-phone-number-input/input';
import { makeStyles, StylesProvider } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

function SignupForm() {
  const [signupForm, setSignupForm] = useState({
    firstName: '',
    lastName: '',
    companyId: '',
    role: 0,
    email: '',
    phoneNumber: '',
    password: '',
  });

  const [redirect, setRedirect] = useState({
    change: false,
  });

  useEffect(() => {
    
  }, [signupForm]);

  // const createUserData = (data) => {
  //   const userData = {userId: data.id, companyId: signupForm.companyId}
  //   return userData;
  // }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      !signupForm.firstName ||
      !signupForm.lastName ||
      // !signupForm.companyId ||
      !signupForm.role ||
      signupForm.role <= 0 ||
      !signupForm.email ||
      !signupForm.phoneNumber ||
      !signupForm.password
    ) {
      alert('Please provide all user information.');
    } else {
      API.createUser(signupForm).then(async (res) => {
        // if (res.data.data.status === 1) {
        //   const userData = await createUserData(JSON.parse(atob(res.data.data.token.split('.')[1])));
        //   API.addUserToCompany(userData).then((res) => {
        if (!res.data.error) {
          alert('Please proceed to login');
          setRedirect({ change: true });
        }
        //   });
        // }
      });
    }
  };

  const marginBottom = {
    marginBottom: '1.2rem',
  };

  const marginBottom2 = {
    marginBottom: '2rem',
  };

  const signupDiv = {
    width: '30rem',
  };

  return (
    <div style={signupDiv} className='container mt-5'>
      <div className='signup-container'>
        <form
          onSubmit={handleSubmit}
          className='signup-styles'
          noValidate
          autoComplete='off'
        >
          <h1>SIGNUP</h1>
          <TextField
            style={marginBottom}
            id='standard-basic'
            label='First Name'
            value={signupForm.firstName}
            onChange={(e) =>
              setSignupForm({ ...signupForm, firstName: e.target.value })
            }
          />

          <TextField
            style={{ marginBottom: '2.5rem' }}
            id='outlined-basic'
            label='Last Name'
            value={signupForm.lastName}
            onChange={(e) =>
              setSignupForm({ ...signupForm, lastName: e.target.value })
            }
          />

          {/* <TextField
            style={{ marginBottom: "2.5rem" }}
            id="outlined-basic"
            label="Company ID"
            value={signupForm.companyId}
            onChange={(e) =>
              setSignupForm({ ...signupForm, companyId: e.target.value })
            }
          /> */}

          <FormControl variant='filled' style={marginBottom}>
            <InputLabel id='demo-simple-select-filled-label'>
              Enter role
            </InputLabel>
            <Select
              labelId='demo-simple-select-filled-label'
              id='demo-simple-select-filled'
              value={signupForm.role}
              onChange={(e) =>
                setSignupForm({ ...signupForm, role: +e.target.value })
              }
            >
              <MenuItem value={0}>Role</MenuItem>
              <MenuItem value={1}>Super Admin</MenuItem>
              <MenuItem value={2}>Admin</MenuItem>
              <MenuItem value={3}>Employee</MenuItem>
            </Select>
          </FormControl>

          <TextField
            style={{ marginBottom: '2.3rem' }}
            id='standard-basic'
            type='email'
            label='Enter email'
            value={signupForm.email}
            onChange={(e) =>
              setSignupForm({ ...signupForm, email: e.target.value })
            }
          />

          <FormLabel className='phone-label'>Phone Number</FormLabel>
          <div>
            <PhoneInput
              country='US'
              type='tel'
              className='input-width-phone'
              style={{ marginBottom: '1rem' }}
              value={signupForm.phoneNumber}
              placeholder='(xxx) xxx-xxxx'
              onChange={(e) => setSignupForm({ ...signupForm, phoneNumber: e })}
            />
          </div>

          <TextField
            style={marginBottom}
            id='standard-basic'
            type='password'
            label='Password'
            value={signupForm.password}
            onChange={(e) =>
              setSignupForm({ ...signupForm, password: e.target.value })
            }
          />
          <div className='submit-margin'>
            <button href='#' className='submit-button' style={marginBottom}>
              Submit
            </button>
          </div>
          <small className='form-text'>
            <a href='/login' className='redirect mt-4'>
              Already have an account? Log in here
            </a>
          </small>
          {redirect.change ? (
            <Redirect to='/login' />
          ) : (
            <Redirect to='/signup' />
          )}
        </form>
      </div>
    </div>
  );
}

export default SignupForm;
