import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setColor } from "../redux/actions/MaterialsActions";
import Area from "./Area";
import MetalStyle from "./MetalStyle";
import BackButton from "./SubComponent/BackButton";
import ColorOptions from "./SubComponent/ColorOptions";
import Roof from "./Roof";
import { showErrorModal } from "../redux/actions/FormActions";

function Color() {
  // Connection which allows this component to send information to redux
  const dispatch = useDispatch();

  // Grabs the current base material from redux
  const { base, color, metalStyle, roofType } = useSelector((state) => state.materials);

  // Arrays of the colors based on the base material
  const flatColors = ["White", "Tan", "Gray"];
  const slopeColors = ["Gray", "Brown", "Green", "Black"];

  // State that will hold one of the above arrays
  const [options, setOptions] = useState([]);

  // State that will hold the selected color
  const [selectedColor, setSelectedColor] = useState(null);

  // State that determines if the Area component should be revealed or not
  const [showArea, setShowArea] = useState(false);
  const [back, setBack] = useState(false);
  const [type, setType] = useState(null);

  useEffect(() => {
    setType(roofType);
  }, []);

  useEffect(() => {
    if (color) {
      setSelectedColor(color);
    };
  }, [color]);

  // Function that determines which of the color arrays to set to the 'options' state
  useEffect(() => {
    switch (base) {
      case "Wood":
      case "Steel":
      case "Concrete":
        setOptions(flatColors);
        break;
      default:
        setOptions(slopeColors);
    }
  }, [base]);

  // Function that sends the selected color to redux
  useEffect(() => {
    dispatch(setColor(selectedColor));
  }, [selectedColor]);

  const handleNext = () => {
    if (selectedColor) {
      setShowArea(true);
    } else {
      dispatch(showErrorModal(true));
    };
  };

  if (back) {
    if (metalStyle) {
      return <MetalStyle show="true" />;
    } else {
      return <Roof />;
    };
  };

  if (showArea) {
    return <Area type={type}/>;
  };

  return (
    <div className=" container my-5">
      <h1 className="text-center">Color</h1>
      <div className="row d-flex justify-content-center text-center mt-3">
        {/* Maps through the selected color options array and creates a ColorOptions component for each option */}
        {options.map((color) => (
          <ColorOptions
            color={color}
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
            key={color}
          />
        ))}
      </div>
      <BackButton setBack={setBack} handleNext={handleNext} />
    </div>
  );
}

export default Color;
