import React, { useState, useEffect } from "react";

function RoofMaterial({ mat, checked, material, setMaterial }) {
  // Variables that hold the bootstrap code for the color the button should display
  let unClicked = "btn-secondary";
  let clicked = "btn-primary";

  // State that holds one of the above variables
  const [color, setColor] = useState(unClicked);

  // Function that handles the selection of roofing materials
  const handleMaterials = (e) => {
    // If the multiple materials checkbox is checked, this will check if the clicked material is already in the array, and will either remove it or add it
    if (checked) {
      if (typeof material == "object") {
        if (material.includes(e.target.value)) {
          let newMaterial = material.filter(
            (material) => material !== e.target.value
          );
          setMaterial(newMaterial);
        } else {
          setMaterial([...material, e.target.value]);
        }
      } else {
        // If the checkbox is checked but the array is currently empty, this will input the selected material into an array, and put that array into the 'material' state
        setMaterial([e.target.value]);
      }
    } else {
      // If the multiple materials checkbox is not checked, this will check if the clicked material is already set to the 'material' state, and if it is, it will remove it and hide the next component
      if (material === mat) {
        setMaterial(null);
      } else {
        // Sets the current material to the 'material' state
        setMaterial(e.target.value);
      }
    }
  };

  // Function that determines what color the buttons should display
  useEffect(() => {
    if (checked) {
      if (typeof material == "object") {
        if (material?.includes(mat)) {
          setColor(clicked);
        } else {
          setColor(unClicked);
        }
      }
    } else {
      if (material === mat) {
        setColor(clicked);
      } else {
        setColor(unClicked);
      }
    }
  }, [material, checked]);

  const styles = {
    button: {
      height: "100px",
      width: "200px",
      fontSize: "30px",
    },
  };

  return (
    <div className="col col-md-4">
      <button
        style={styles.button}
        className={"btnshadow my-3 btn " + color}
        value={mat}
        onClick={(e) => handleMaterials(e)}
      >
        {mat}
      </button>
    </div>
  );
}

export default RoofMaterial;
