import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  addPrivateComments,
  showErrorModal,

} from "../redux/actions/FormActions";
// import { setSchematicData } from "../redux/actions/SchematicData"
import AdminModal from "./AdminModal";
import Calculator from "./Calculator";
import PrivateComments from "./PrivateComments";
import ErrorModal from "./SubComponent/ErrorModal";
import WorkProposal from "./WorkProposal";
import API from "../utils/API";


function Everything() {
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.formDetails);
  // State to determine if the calculator modal is shown or not
  const [show, setShow] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const [allComments, setAllComments] = useState([]);
  const [comment, setComment] = useState('');
  const [showError, setShowError] = useState(false);
  const [showAdmin, setShowAdmin] = useState(false);

  // Functions to handle open and close of calculator modal
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseComment = async () => {
    if (comment !== '') {
      await setAllComments([...allComments, comment]);
      setComment('');
    }
    setShowComment(false);
  };

  useEffect(() => {
    dispatch(addPrivateComments(allComments));
  }, [allComments]);

  const handleErrorClose = () => {
    dispatch(showErrorModal(null));
    setShowError(false);
  };
  const handleErrorShow = () => setShowError(true);

  // useEffect(() => {  
  //   let token = JSON.parse(atob(localStorage.getItem("token").split('.')[1]));
    
  //   API.getSchematicByUserId(token.id)
  //     .then(schematicInfo => {
  //       dispatch(setSchematicData(schematicInfo.data.data));
  //     })
  // }, []);

  useEffect(() => {
    if (error) {
      handleErrorShow();
    }
  });



  const styles = {
    fixed: {
      position: 'fixed',
      right: '2vw',
      top: '2vh',
      zIndex: '2',
      // width: "125px",
      textAlign: 'center',
    },
    leftFixed: {
      position: 'fixed',
      left: '2vw',
      top: '2vh',
      zIndex: '2',
    },
    btn: {
      cursor: 'pointer',
      backgroundColor: 'rgb(181, 181, 181)',
      borderRadius: '15px',
      padding: '8px',
      opacity: '.65',
      // textAlign: "right",
    },
  };
  return (
    <div>
      <ErrorModal
        showError={showError}
        setShowError={handleErrorClose}
        message={error?.message}
      />
      <Calculator show={show} handleClose={handleClose} />
      <PrivateComments
        show={showComment}
        handleClose={handleCloseComment}
        comment={comment}
        setComment={setComment}
        allComments={allComments}
        setAllComments={setAllComments}
      />
      <AdminModal show={showAdmin} handleClose={setShowAdmin} />
      <div style={styles.fixed}>

        <p style={styles.btn} onClick={handleShow} className="shadowhover">
          View calculator <i className="fas fa-calculator"></i>

        </p>
        <p
          style={styles.btn}
          onClick={() => setShowComment(true)}
          className='shadowhover'
        >
          Add Info
        </p>
      </div>
      <div style={styles.leftFixed}>
        <p
          style={styles.btn}
          className='shadowhover'
          onClick={() => setShowAdmin(true)}
        >
          Admin
        </p>
      </div>
      <WorkProposal />
    </div>
  );
}

export default Everything;
