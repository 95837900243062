import React, { useState, useEffect } from "react";

function AreaButtons({
  area,
  value,
  selectedArea,
  setSelectedArea,
}) {
  // Variables that hold the bootstrap code for the color the button should display
  let unClicked = "btn-secondary";
  let clicked = "btn-primary";

  // State that holds one of the above variables
  const [color, setColor] = useState(unClicked);

  // Function that handles the selection of an area
  const handleArea = () => {
    // If the clicked area is already set to the 'selectedArea' state, this will remove it and hide the Options component until another area is selected
    if (selectedArea?.area === area) {
      setSelectedArea(null);
    } else {
      // Sets the current area to the 'selectedArea' state
      setSelectedArea({ area, value });
    }
  };

  // Function that determines the color the button should display
  useEffect(() => {
    if (selectedArea?.area === area) {
      setColor(clicked);
    } else {
      setColor(unClicked);
    }
  }, [selectedArea]);

  const styles = {
    button: {
      height: "75px",
      width: "200px",
      fontSize: "30px",
    },
  };
  return (
    <div className="col col-md-4">
      <button
        style={styles.button}
        className={"btnshadow my-3 btn " + color}
        onClick={handleArea}
      >
        {area}
      </button>
    </div>
  );
}

export default AreaButtons;
