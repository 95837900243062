import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { setPerimFlashMaterial } from '../redux/actions/MaterialsActions';
import FlashingColor from './FlashingColor';

function FlashingMetal({checked}) {
    const dispatch = useDispatch();
    const [steelColor, setSteelColor] = useState("btn-secondary");
    const [copperColor, setCopperColor] = useState("btn-secondary");
    const [choice, setChoice] = useState(null);

    const handleFlash = e => {
        if (choice === e.target.value) {
            setChoice(null);
        } else {
            setChoice(e.target.value);
        };
    };

    useEffect(() => {
        dispatch(setPerimFlashMaterial(choice));
        if (choice === "Galvanized Steel") {
            setSteelColor("btn-primary");
            setCopperColor("btn-secondary");
        } else if (choice === "Copper") {
            setSteelColor("btn-secondary");
            setCopperColor("btn-primary");
        } else {
            setSteelColor("btn-secondary");
            setCopperColor("btn-secondary");
        }
    }, [choice]);

    useEffect(() => {
        if (!checked) {
            setChoice(null);
            dispatch(setPerimFlashMaterial(null));
        };
    }, [checked]);

    const styles = {
        button: {
            height: "75px",
            width: "200px",
            fontSize: "23px",
        }
    };
    return (
        <div className="container mt-3 mb-4">
            <p className="text-center">Perimeter Flashing Material</p>
            <div className="row align-items-center text-center justify-content-center">
                <div className="col col-md-4 my-2">
                    <button style={styles.button} className={"btn " + steelColor} onClick={e => handleFlash(e)} value="Galvanized Steel">Galvanized Steel</button>
                </div>
                <div className="col col-md-3 my-2">
                <button style={styles.button} className={"btnshadow btn " + copperColor} onClick={e => handleFlash(e)} value="Copper">Copper</button>
                </div>
            </div>
            {choice ? <FlashingColor /> : null}
        </div>
    )
}

export default FlashingMetal;
