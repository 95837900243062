import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setPitchType, setSingleOrTorch } from '../redux/actions/ConstructionOptions';
import { showErrorModal } from '../redux/actions/FormActions';
import RoofOption from './RoofOption';
import RoofType from './RoofType';
import BackButton from './SubComponent/BackButton';

function SingleOrTorch() {
    const dispatch = useDispatch();
    const { singleOrTorch } = useSelector(state => state.constructionOptions);

    const [singlePly, setSinglePly] = useState("btn-secondary");
    const [torchDown, setTorchDown] = useState("btn-secondary");
    const [selection, setSelection] = useState(singleOrTorch ? singleOrTorch : null);

    const [show, setShow] = useState(false);
    const [back, setBack] = useState(false);

    useEffect(() => {
        dispatch(setPitchType(null));
    }, []);

    const setType = e => {
            if (selection === e.target.value) {
                setSelection(null);
            } else {
                setSelection(e.target.value);
            };
    };

    useEffect(() => {
        dispatch(setSingleOrTorch(selection));
        if (selection === "Single Ply") {
            setSinglePly("btn-primary");
            setTorchDown("btn-secondary");
        } else if (selection === "Torch Down") {
            setSinglePly("btn-secondary");
            setTorchDown("btn-primary");
        } else {
            setSinglePly("btn-secondary");
            setTorchDown("btn-secondary");
        };
    }, [selection]);

    const handleNext = () => {
        if (selection) {
            setShow(true);
        } else {
            dispatch(showErrorModal(true));
        };
    };

    const styles = {
        button: {
            height: "75px",
            width: "200px",
            fontSize: "30px"
          }
    };

    if (back) {
        return <RoofType />
    };

    if (show) {
        return <RoofOption type="Flat" />
    };

    return (
        <div className="container my-5">
            <h1 className="text-center">Single Ply or Torch Down</h1>
            <div className="row my-5 d-flex justify-content-center">
                <button 
                    style={styles.button}
                    className={"btnshadow btn my-2 mr-1 " + singlePly}
                    value="Single Ply"
                    onClick={e => setType(e)}
                >
                    Single Ply
                </button>
                <button
                    style={styles.button}
                    className={"btnshadow btn my-2 ml-1 " + torchDown}
                    value="Torch Down"
                    onClick={e => setType(e)}
                >
                    Torch Down
                </button>
            </div>
            <BackButton setBack={setBack} handleNext={handleNext}/>
        </div>
    )
}

export default SingleOrTorch;
