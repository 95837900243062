import React, { useState, useEffect, useRef } from "react";

function MiscInput({ count, index, handleDelete, misc, setMisc }) {
  const [comment, setComment] = useState("");
  const [price, setPrice] = useState(null);
  const [showComment, setShowComment] = useState(false);

  const checkbox = useRef();

  useEffect(() => {
    if (misc) {
      if (misc[index]) {
        setComment(misc[index].comment);

        setPrice(misc[index].price);
        if (misc[index].showOnPdf) {
          setShowComment(true);
          checkbox.current.checked = true;
        } else {
          setShowComment(false);
        }
      }
    }
  }, []);

  useEffect(() => {
    let newMisc;
    if (misc) {
      newMisc = misc.filter((x) => x.id !== count);
    } else {
      newMisc = [];
    }
    const object = {
      id: count,
      comment,
      price: +price,
      showOnPdf: showComment,
    };
    newMisc.push(object);
    setMisc(newMisc.sort((a, b) => a - b));
  }, [comment, price, checkbox?.current?.checked]);

  //
  const handleCheck = () => {
    if (checkbox.current.checked === true) {
      setShowComment(true);
    } else {
      setShowComment(false);
    }
  };

  const styles = {
    check: {
      transform: "scale(3)"
    },
    misc: {
      fontSize: "30px",
    },
    trash: {
      cursor: "pointer",
    },
  };
  return (
    <div
      className="row d-flex justify-content-center align-items-center pl-lg-1 mt-3"
      style={styles.misc}
    >
      <div className="col-12 col-lg-7">
        <div className="row d-flex justify-content-center justify-content-lg-start">
          <i
            className="fas fa-trash-alt mt-5 mr-2 delete"
            style={styles.trash}
            onClick={() => handleDelete(index, count)}
          />
          <textarea
            cols="25"
            type="text"
            className="mt-3 mb-3 mb-lg-0 mr-lg-5"
            placeholder="Miscellaneous Options"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
        <div className="row d-flex justify-content-center justify-content-lg-start align-items-center mb-2 ml-lg-4">
          <input
            type="checkbox"
            ref={checkbox}
            className="mb-2"
            style={styles.check}
            onChange={handleCheck}
          />
          <label className="ml-4">Show on PDF</label>
        </div>
      </div>
      <div className="col-12 col-lg-5">
        <div className="row d-flex justify-content-center justify-content-lg-end">
          <label className="mt-1 mt-lg-2 mr-1">Price: </label>
          <input
            type="number"
            value={price}
            min="0"
            className="mb-3 mb-lg-5"
            onChange={(e) => setPrice(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}

export default MiscInput;
