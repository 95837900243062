import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { TextField } from "@material-ui/core";
import {
  setClientSignature,
  setContractDetails,
} from "../redux/actions/ClientSignature";
import { PDFViewer, pdf } from "@react-pdf/renderer";
import API from "../utils/API";
import SignatureModal from "./SubComponent/SignatureModal";
import ErrorModal from "./SubComponent/ErrorModal";
import ClientDone from "./ClientDone";
import ClientChangeOrderPreview from "./ClientChangeOrderPreview";

function ClientChangeOrder() {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [projectAddress, setProjectAddress] = useState("");

  const params = useParams();

  const [signature, setSignature] = useState("");
  const [changeOrder, setChangeOrder] = useState({});

  const [number, setNumber] = useState("");
  const [isReady, setIsReady] = useState(false);
  const [done, setDone] = useState(false);

  const [show, setShow] = useState(false);
  const [showError, setShowError] = useState(false);

  const doc = (
    <ClientChangeOrderPreview
      store={store}
      phone={number}
      index={+params.index}
    />
  );

  const handlePDF = () => {
    if (signature === "") {
      setShowError(true);
    } else {
      const clientSignature = {
        signature: signature,
      };
      dispatch(setClientSignature(clientSignature));
      setIsReady(true);
    }
  };

  const handleSign = () => {
    setShow(true);
  };

  const handleBack = () => {
    setIsReady(false);
  };

  const handleComplete = async () => {
    let fd = new FormData();
    const blob = await pdf(doc).toBlob();

    fd.append("file", blob, "signature-co.pdf");
    API.uploadImage(fd).then((res) => {
      const emailData = {
        fullName: `${firstName} ${lastName}`,
        email: email,
        subject: `SIGNATURE CONFIRMATION FOR: ${projectAddress}`,
        message: `Your change order for ${firstName} ${lastName} at ${projectAddress} has been signed. The signed document is attached below`,
        images: [
          {
            description: `${projectAddress.replace(
              / /g,
              "-"
            )}-Client-Signature-CO`,
            url: res.data,
          },
        ],
      };
      API.sendEmail(emailData);
      API.updateChangeOrder(params, {
        url: res.data,
        scopeOfWork: changeOrder.scopeOfWork,
        priceChange: changeOrder.priceChange,
        newContractTotal: changeOrder.newContractTotal,
        add: changeOrder.add,
      }).then(() => {
        setIsReady(false);
        setDone(true);
      });
    });
  };

  const formatNumber = (number) => {
    let formatted = number.split("");
    formatted.splice(0, 2);
    formatted.unshift("(");
    formatted.splice(4, 0, ")");
    formatted.splice(5, 0, " ");
    formatted.splice(9, 0, "-");
    setNumber(formatted.join(""));
  };

  useEffect(() => {
    API.proposalDetails(params.id).then((res) => {
      dispatch(setContractDetails(res.data.data));
      setFirstName(res.data.data.firstName);
      setLastName(res.data.data.lastName);
      setEmail(res.data.data.email);
      setProjectAddress(res.data.data.projectAddress);
      formatNumber(res.data.data.phoneNumber);
      setChangeOrder(res.data.data.changeOrders[+params.index - 1]);
    });
  }, []);

  const styles = {
    marginBottom: {
      marginBottom: "1rem",
    },
    marginBottom2: {
      marginBottom: "1.3rem",
    },
    marginBottom3: {
      marginBottom: "1.7rem",
    },
    marginBottom4: {
      marginBottom: "2.2rem",
    },
    notice: {
      backgroundColor: "red",
      color: "white",
      padding: "20px",
    },
    form: {
      width: "80vw",
      maxWidth: "800px",
      margin: "0 auto",
    },
    pdf: {
      height: "100vh",
      width: "60vw",
    },
  };

  if (done) {
    return <ClientDone />;
  }

  if (isReady) {
    return (
      <div>
        <SignatureModal
          show={show}
          setShow={setShow}
          handleComplete={handleComplete}
        />
        <div className="row d-flex justify-content-center mt-3">
          <PDFViewer style={styles.pdf}>
            <ClientChangeOrderPreview
              store={store}
              index={+params.index}
              phone={number}
            />
          </PDFViewer>
        </div>
        <div className="row d-flex justify-content-around">
          <button className="email-back-button mb-3" onClick={handleBack}>
            <i className="fas fa-arrow-left mr-2"></i>
            Back
          </button>
          <button className="change-order mb-3" onClick={handleSign}>
            Sign <i className="fas fa-file-signature"></i>
          </button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <ErrorModal
        showError={showError}
        setShowError={setShowError}
        message="Please fill out each section of the form before moving on"
      />
      <form className="proposal-container top-corners" style={styles.form}>
        <h1>Client Signature</h1>
        <div className="row" style={{ width: "105%" }}>
          <div className="col-12">
            <TextField
              className="input-width"
              label="Signature"
              style={styles.marginBottom2}
              value={signature}
              onChange={(e) => setSignature(e.target.value)}
            ></TextField>
          </div>
        </div>
      </form>
      <div className="row d-flex justify-content-around">
        <button className="change-order mb-3" onClick={handlePDF}>
          Preview Signature
        </button>
      </div>
    </div>
  );
}

export default ClientChangeOrder;
