import React, { useState, useEffect } from "react";
import HelveticaItalic from "../fonts/Helvetica-Italic.ttf";

import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

function ChangeOrderPDF({ store, phone }) {
  const { firstName, lastName, email, projectAddress, date, changeOrders } =
    store.formDetails.changeOrder.proposalData;
  const { scopeOfWork, priceChange, newContractTotal, add, subtract } =
    store.formDetails.changeOrder;

  Font.register({
    family: "Helvetica-Italic",
    format: "truetype",
    src: HelveticaItalic,
  });

  const styles = StyleSheet.create({
    page: {
      fontSize: 13,
      backgroundColor: "#FFF",
      width: "100%",
      orientation: "portrait",
      padding: "30rem 30rem 50rem 30rem",
    },
    initialsHeader: {
      fontSize: 15,
      marginBottom: 20,
      marginRight: 20,
      textAlign: "right",
    },
    initialsContainer: {
      display: "flex",
      justifyContent: "flex-end",
    },
    contactSection: {
      marginTop: "2rem",
    },
    southlandIMG: {
      width: "215rem",
      height: "auto",
    },

    // -------- Margins --------
    margin10: {
      marginTop: "10rem",
    },
    margin15: {
      marginTop: "15rem",
    },

    // -------- Heading/Labels/Descriptions --------
    mainHeading: {
      textAlign: "center",
      fontFamily: "Helvetica-Bold",
      fontSize: 30,
    },
    materialHeading: {
      textDecoration: "underline",
      fontWeight: "bold",
      fontFamily: "Helvetica-Bold",
    },
    acceptanceHeading: {
      textAlign: "center",
      textDecoration: "underline",
      fontFamily: "Helvetica-Bold",
    },
    acceptanceDescription: {
      marginTop: "10rem",
    },
    // -------- Containers --------
    topContainer: {
      flexDirection: "row",
      marginTop: "10rem",
    },
    projectInfoContainer: {
      flexDirection: "column",
      marginTop: "57rem",
      marginLeft: "10rem",
      fontWeight: "bold",
    },
    nameDateContainer: {
      flexDirection: "row",
      marginTop: "15rem",
    },
    projectAddressContainer: {
      marginTop: "15rem",
    },
    emailPhoneContainer: {
      flexDirection: "row",
      marginTop: "15rem",
    },
    scopeOfWorkContainer: {
      flexDirection: "row",
      marginTop: "10rem",
      marginLeft: "20rem",
    },
    totalHeadingContainer: {
      flexDirection: "row",
      marginTop: "15rem",
      width: "75%",
    },
    materialHeadingContainer: {
      backgroundColor: "aqua",
    },
    totalLabelContainer: {
      backgroundColor: "orange",
      marginLeft: "100rem",
    },
    materialDescriptionsContainer: {
      marginTop: "15rem",
    },
    termsAgreementContainer: {
      marginTop: "15rem",
    },
    bottomSignatureContainer: {
      width: "75%",
      flexDirection: "row",
      marginTop: "15rem",
    },
    southlandSignatureContainer: {
      marginRight: "120rem",
    },

    // -------- Project Info --------
    dottedLine: {
      border: "none",
      borderTop: "2px dotted #000",
      color: "#fff",
      backgroundColor: "#fff",
      height: "30rem",
      width: "4rem",
      display: "flex",
      alignSelf: "flex-end",
    },
    footer: {
      position: "absolute",
      fontSize: 10,
      bottom: 30,
      left: 550,
      right: 20,
      marginTop: 20,
    },
    footerText: {
      position: "absolute",
      fontSize: 10,
      bottom: 15,
      left: 30,
      textAlign: "center",
    },
    map: {
      flexDirection: "column",
    },
    bold: {
      fontFamily: "Helvetica-Bold",
    },
    italic: {
      fontFamily: "Helvetica-Italic",
    },
    underline: {
      textDecoration: "underline",
    },

    // ------------- CHANGE ORDER STYLES --------------

    topHeader: {
      textDecoration: "underline",
      textAlign: "center",
      fontSize: 30,
      fontFamily: "Helvetica-Bold",
      marginBottom: "15rem",
    },
    columnContainer: {
      flexDirection: "column",
    },
    nameMargin: {
      marginBottom: "12rem",
    },
    changeOrderHeading: {
      marginRight: "300rem",
    },
    marginBottom25: {
      marginBottom: "22rem",
    },
    centerTextBold: {
      fontFamily: "Helvetica-Bold",
      textAlign: "center",
    },
    signatureSection: {
      marginTop: "25rem",
    },
    scopeHeading: {
      textDecoration: "underline",
    },
    scopeHeadingContainer: {
      textDecoration: "underline",
      marginTop: "10rem",
    },
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <Text style={styles.topHeader}>Southland Roofing Inc.</Text>
        </View>

        <View style={styles.topContainer}>
          <Text style={styles.changeOrderHeading}>
            <Text style={styles.bold}>Date: </Text>
            {date.substring(0, 10)}
          </Text>
          <Text style={styles.bold}>
            CHANGE ORDER #{changeOrders.length + 1}
          </Text>
        </View>

        <View style={styles.topContainer}>
          <View style={styles.columnContainer}>
            <Text style={styles.nameMargin}>
              <Text style={styles.bold}>Name:</Text> {firstName} {lastName}
            </Text>
            <Text style={styles.nameMargin}>
              <Text style={styles.bold}>Project Address:</Text> {projectAddress}
            </Text>
            <Text style={styles.nameMargin}>
              <Text style={styles.bold}>Tel:</Text> {phone}
            </Text>
            <Text style={styles.nameMargin}>
              <Text style={styles.bold}>Email:</Text> {email}
            </Text>
          </View>
        </View>

        <View style={styles.topContainer}>
          <Text>
            Change order estimate is based on the additional work proposed by{" "}
            {firstName} {lastName} as prewritten request via email.
          </Text>
        </View>

        <View style={styles.scopeHeadingContainer}>
          <Text style={styles.bold}>Scope of Work (SOW):</Text>
        </View>

        <View style={styles.scopeOfWorkContainer}>
          <View style={styles.italic}>
            <Text style={styles.italic}>{scopeOfWork}</Text>
          </View>
        </View>

        <View style={styles.margin10}>
          <Text>
            We propose the above changes in the scope of work for the total{" "}
            {add ? "added sum" : "subtracted difference"} of: $
            <View style={styles.nameMargin}>
              <Text style={styles.bold}>{priceChange}</Text>
            </View>
          </Text>
          <Text>
            The new contract amount is:{" "}
            <Text style={styles.bold}>${newContractTotal}</Text>
          </Text>
        </View>

        <View style={styles.topContainer}>
          <Text>
            All material and work are guaranteed to be as specified. All work to
            be completed in a substantial workmanlike manner according to
            specifications submitted, per standard practices. Any alteration or
            deviation from above specifications involving extra costs will be
            executed only upon written orders, and will become an extra charge
            over and above the estimate. This change order will require
            additional timing from the original agreement and it may impact the
            overall construction scheduled. Southland Roofing inc will provide
            all necessary labor force and/ or submittals within 15 business days
            maximum. However, any and all agreements contingent upon strikes,
            accidents or delays beyond our control cannot be held accountable or
            used as legal action against Southland Roofing inc. Owner to carry
            fire, tornado and other necessary insurance. Our workers are fully
            covered by Workmen’s Compensation Insurance. If either party
            commences legal action to enforce its rights pursuant to this
            agreement, the prevailing party in said legal action shall be
            entitled to recover its reasonable attorney’s fees and costs of
            litigation relating to said legal action, as determined by a court
            of competent jurisdiction.
          </Text>
        </View>

        <View style={styles.margin15}>
          <Text>
            Note: This Change Order will be executed only upon all balances due
            are paid. This proposal may be withdrawn by Southland Roofing inc.
            if not accepted within 10 days.
          </Text>
        </View>

        <View style={styles.margin15}>
          <Text
            style={{
              textAlign: "center",
              marginBottom: "7rem",
              fontFamily: "Helvetica-Bold",
            }}
          >
            ACCEPTANCE OF CHANGE ORDER
          </Text>
          <Text>
            The above prices, specifications and conditions are satisfactory and
            are hereby accepted. You are authorized to do the work as specified.
            Payment will be made as outlined above.
          </Text>
        </View>

        <View style={styles.signatureSection}>
          <Text style={styles.nameMargin}>
            {"                       "}Name: __________________________________
          </Text>
          <Text style={styles.nameMargin}>
            {"                 "}Signature: __________________________________
          </Text>
          <Text style={styles.nameMargin}>
            Date of Acceptance: __________________________________
          </Text>
        </View>
      </Page>
    </Document>
  );
}

export default ChangeOrderPDF;
