const formReducer = (state = {}, action) => {
  let newState;
  switch (action.type) {
    case "SET_FORM_DETAILS":
      newState = {
        ...state,
        info: action.payload,
      };
      return newState;
    case "SET_CHANGE_ORDER":
      newState = {
        ...state,
        changeOrder: action.payload,
      };
      return newState;
    case "DELETE_CHANGE_ORDER":
      newState = { ...state };
      delete newState.changeOrder;
      return newState;
    case "ADD_PRIVATE_COMMENTS":
      newState = {
        ...state,
        privateComments: action.payload,
      };
      return newState;
    case "ADD_IMAGE":
      const newImages = state.images
        ? [...state.images, action.payload]
        : [action.payload];
      newState = {
        ...state,
        images: newImages,
      };
      return newState;
    case "ADD_PDF":
      newState = {
        ...state,
        pdf: action.payload,
      };

      return newState;
    case "DELETE_IMAGE":
      const filterImages = state.images.filter(
        (image) => image.url !== action.payload
      );
      if (filterImages.length < 1) {
        newState = { ...state };
        delete newState.images;
      } else {
        newState = {
          ...state,
          images: filterImages,
        };
      }
      return newState;
    case "ADJUST_PRICE":
      if (action.payload === 0) {
        newState = { ...state };
        delete newState.priceAdjustment;
      } else {
        newState = {
          ...state,
          priceAdjustment: action.payload,
        };
      }
      return newState;
    case "ADD_COMMISSION":
      if (action.payload === 0) {
        newState = { ...state };
        delete newState.employeeCommission;
      } else {
        newState = {
          ...state,
          employeeCommission: action.payload,
        };
      }
      return newState;
    case "OVERRIDE_PRICE":
      if (action.payload === 0) {
        newState = { ...state };
        delete newState.priceOverride;
      } else {
        newState = {
          ...state,
          priceOverride: action.payload,
        };
      }
      return newState;
    case "ADD_CUSTOM_TITLE":
      if (action.payload === "") {
        newState = { ...state };
        delete newState.customTitle;
      } else {
        newState = {
          ...state,
          customTitle: action.payload,
        };
      }
      return newState;
    case "SHOW_ERROR":
      if (action.payload === null) {
        newState = { ...state };
        delete newState.error;
      } else {
        newState = {
          ...state,
          error: action.payload,
        };
      }
      return newState;
    case "ADD_ANOTHER_PROPOSAL":
      newState = {
        info: action.payload.formDetails.info,
      };
      if (state.data) {
        newState.data = [...state.data, action.payload];
      } else {
        newState.data = [action.payload];
      }
      return newState;
    case "FORM_RESTART":
      return {};
    default:
      return state;
  }
};

export default formReducer;
