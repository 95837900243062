import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { constructionRestart } from '../redux/actions/ConstructionOptions';
import { materialRestart } from '../redux/actions/MaterialsActions';
import { formRestart } from '../redux/actions/FormActions';
import ProposalLogItem from './SubComponent/ProposalLogItem';
import WorkProposal from './WorkProposal';
import API from '../utils/API';
import ChangeOrder from './ChangeOrder';

const ProposalLog = ({ token }) => {
  if (!token) {
    token = JSON.parse(atob(localStorage.getItem('token').split('.')[1]));
  }
  const dispatch = useDispatch();
  const store = useSelector((state) => state);

  const [proposals, setProposals] = useState([]);
  const [restart, setRestart] = useState(false);

  useEffect(() => {
    API.getProposals(token.id).then((res) => {
      setProposals(res.data.data);
    });
  }, []);

  const handleNewProposal = () => {
    
    setRestart(true);
    dispatch(constructionRestart());
    dispatch(materialRestart());
    dispatch(formRestart());
  };

  if (restart) {
    return <WorkProposal />;
  } else if (store.formDetails.changeOrder) {
    return <ChangeOrder data={store.formDetails.changeOrder.proposalData} />;
  } else {
    return (
      <div className='text-center'>
        <div className='new-proposal-button mt-5' onClick={handleNewProposal}>
          NEW WORK PROPOSAL
        </div>
        <div style={proposalsStyle}>
          {proposals
            .slice(0)
            .reverse()
            .map((proposal) => (
              <ProposalLogItem proposal={proposal} />
            ))}
        </div>
      </div>
    );
  }
};

const proposalsStyle = {
  margin: '50px 15%',
};

export default ProposalLog;
