import React from 'react';
import { Container, Row } from 'react-bootstrap';
import SignupForm from '../component/SignupForm';

function Signup() {
  const body = {
    padding: '3rem',
  };
  return (
    // <Container style={bodyStyle} className="container">
    //   <Row style={center}>
    //     <SignupForm />
    //   </Row>
    // </Container>
    <div style={body}>
      <SignupForm />
    </div>
  );
}

export default Signup;
