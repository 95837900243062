export const setClassification = payload => {
    return { type: "SET_CLASSIFICATION", payload };
};

export const setRoofType = payload => {
    return { type: "SET_ROOF_TYPE", payload };
};

export const setMaterials = payload => {
    return { type: "SET_MATERIALS", payload };
};

export const setBaseType = payload => {
    return { type: "SET_BASE_TYPE", payload };
};

export const setColor = payload => {
    return { type: 'SET_COLOR', payload };
};

export const setMetalStyle = payload => {
    return { type: "SET_METAL_STYLE", payload };
};

export const setPerimFlashMaterial = payload => {
    return { type: "SET_PERIMETER_FLASHING_MATERIAL", payload };
};

export const setPerimFlashColor = payload => {
    return { type: "SET_PERIMETER_FLASHING_COLOR", payload };
};

export const materialRestart = () => {
    return { type: "MATERIAL_RESTART", payload: null };
};