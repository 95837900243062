import React, { useState, useEffect } from "react";

function StyleOptions({ style, selectedStyle, setSelectedStyle, setShowNext }) {
  // Variables that hold the boostrap code for the color the button should display
  let unClicked = "btn-secondary";
  let clicked = "btn-primary";

  // State that holds one of the above variables
  const [color, setColor] = useState(unClicked);

  // Function that handles the selection of a style
  const handleStyle = (e) => {
    // If the clicked style was already set to the 'selectedStyle' state, this will remove the style and hide the next component until another style is selected
    if (selectedStyle === style) {
      setSelectedStyle(null);
      // setShowNext(false);
    } else {
      // Sets the current style to the 'selectedStyle' state
      setSelectedStyle(style);
      // Reveals the next component
      // setShowNext(true);
    }
  };

  // Function that determines what color the button should display
  useEffect(() => {
    if (selectedStyle === style) {
      setColor(clicked);
    } else {
      setColor(unClicked);
    }
  }, [selectedStyle]);

  const styles = {
    button: {
      height: "75px",
      width: "200px",
      fontSize: "30px",
    },
  };
  return (
    <div className="col col-md-4">
      <button
        style={styles.button}
        className={"btnshadow my-3 btn " + color}
        onClick={handleStyle}
      >
        {style}
      </button>
    </div>
  );
}

export default StyleOptions;
