import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import RoofOption from "./RoofOption";
import PitchType from "./SubComponent/PitchType";
import { setPitchType, setSingleOrTorch } from "../redux/actions/ConstructionOptions";
import ImageModal from "./ImageModal";
import BackButton from "./SubComponent/BackButton";
import RoofType from "./RoofType";
import { showErrorModal } from "../redux/actions/FormActions";

function Pitch() {
  // Connection which allows this component to send information to redux
  const dispatch = useDispatch();
  const pitchRedux = useSelector(state => state.constructionOptions.pitch);

  // State that holds the selected pitch type
  const [pitch, setPitch] = useState(pitchRedux ? pitchRedux : null);

  // State that determines if the next component should be revealed or not
  const [show, setShow] = useState(false);
  const [back, setBack] = useState(false);

  // State that determines if the image modal should appear or not
  const [showImage, setShowImage] = useState(false);

  // Functions to handle the showing and closing of the image modal
  const handleClose = () => setShowImage(false);
  const handleShow = () => setShowImage(true);

  // Function that sends the selected pitch to redux every time the 'pitch' state changes
 const handlePitch = type => {
    setPitch(type);
    dispatch(setPitchType(type));
  };

  useEffect(() => {
    dispatch(setSingleOrTorch(null));
  }, []);

  const handleNext = () => {
    if (pitch) {
      setShow(true);
    } else {
      dispatch(showErrorModal(true));
    };
  };

  // Array of different pitches, their values, and reference images
  const options = [
    {
      type: "Low",
      value: 0.1,
      image:
        "https://malarkeyroofing.com/uploads/1565721714-MRP-ecoasis-nex-hero2.png",
    },
    {
      type: "Medium",
      value: 0.2,
      image: "https://bit.ly/304cv3c",
    },
    {
      type: "Medium High",
      value: 0.3,
      image: "https://bit.ly/3raC4v6",
    },
    {
      type: "High",
      value: 0.4,
      image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQuOciHn-c6GPEdDVOdLDTs8_OWjDXwm0k1uQ&usqp=CAU",
    },
    {
      type: "Very High",
      value: 0.5,
      image:
        "https://i.pinimg.com/originals/3d/7a/e4/3d7ae4d3e53d57fbc0c0a9134e9a44ad.jpg",
    },
  ];

  if (back) {
    return <RoofType />;
  };

  if (show) {
    return <RoofOption type="Sloped" /> ;
  };

  return (
    <div className="container my-5">
      <h1 className="text-center">
        Pitch{" "}
      </h1>
      <div className="row align-items-end text-center justify-content-center">
        {/* Mapping through the options array and creating a PitchType component for each option */}
        {options.map((option) => (
          <PitchType
            key={option.value}
            type={option.type}
            value={option.value}
            pitch={pitch}
            image={option.image}
            setPitch={handlePitch}
            setShow={setShow}
            handleShow={handleShow}
          />
        ))}
      </div>
      <ImageModal show={showImage} handleClose={handleClose} images={options} />
      <BackButton setBack={setBack} handleNext={handleNext}/>
    </div>
  );
}

export default Pitch;
