const currentSelectionsReducer = (state = {}, action) => {
  let newState;
  switch (action.type) {
    case "SET_CURRENT_SELECTIONS":
      newState = {
        ...state,
        selections: action.payload,
      };
      return newState;
    case "UPDATE_SELECTION":
      let selection = action.payload.target;
      newState = {
        ...state,
      };
      newState.selections[selection] = {
        variableMeasurement: action.payload.variableMeasurement,
        description: action.payload.description,
      };
    default:
      return state;
  }
};

export default currentSelectionsReducer;
