import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import API from "../utils/API";
import { addImage } from "../redux/actions/FormActions";

function PrivateComments({
  show,
  handleClose,
  comment,
  setComment,
  allComments,
  setAllComments,
}) {
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [uploaded, setUploaded] = useState(false);
  const [description, setDescription] = useState("");

  const submitComment = async () => {
    if (comment !== "") {
      await setAllComments([...allComments, comment]);
      setComment("");
    }
  };

  const handleUpload = () => {
    if (image) {
      let fd = new FormData();
      fd.append("file", image);
      API.uploadImage(fd).then((res) => {
        const index = res.data.lastIndexOf(".");
        const image = res.data.substr(0, index) + ".pdf";

        dispatch(
          addImage({
            description: description ? description : "Uploaded Image",
            url: image,
          })
        );
        setUploaded(true);
        setImage(null);
        setDescription("");
        setTimeout(() => {
          setUploaded(false);
        }, 2000);
      });
    }
  };

  return (
    <Modal centered show={show} onHide={handleClose} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Add Comments / Pictures</Modal.Title>
      </Modal.Header>
      <h4 className="text-center pt-2">Private Comment</h4>
      <textarea
        rows="5"
        className="mx-5 mt-1 mb-3"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />
      <div className="row d-flex justify-content-center">
        <button
          className="btn btn-lg btn-info mx-5 mb-3"
          style={{ width: "35%" }}
          onClick={submitComment}
        >
          Submit
        </button>
      </div>
      <h4
        className="text-center pt-2"
        style={{ borderTop: "1px solid lightgray" }}
      >
        Add Image
      </h4>
      <div style={{ width: "90%", margin: "0 auto", padding: "0" }}>
        <div className="input-group mb-3" style={{ width: "100%" }}>
          <div className="custom-file">
            <input
              type="file"
              className="my-3 custom-file-input"
              aria-describedby="inputGroupFileAddon04"
              style={{ margin: "0 auto", width: "100%" }}
              onChange={(e) => setImage(e.target.files[0])}
            />
            <label className="custom-file-label">
              {uploaded ? "Image uploaded" : image ? image.name : "Choose File"}
            </label>
          </div>
        </div>
      </div>
      <div className="row d-flex justify-content-center mb-3">
        <label className="mr-1 mt-1">Image description: </label>
        <input
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      <button
        className="btn btn-dark mb-3"
        style={{ width: "150px", margin: "0 auto" }}
        onClick={handleUpload}
      >
        Upload Image
      </button>
    </Modal>
  );
}
export default PrivateComments;
