import { combineReducers, createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { composeWithDevTools } from "redux-devtools-extension";
import materialsReducer from "./reducers/MaterialsReducer";
import formReducer from "./reducers/FormReducer";
import constructionOptionsReducer from "./reducers/ConstructionOptionsReducer";
import clientSignatureReducer from "./reducers/ClientSignatureReducer";
import currentSelectionsReducer from "./reducers/CurrentSelectionsReducer";

// const persistConfig = {
//     key: 'root',
//     storage
// };

// const persistedReducer = persistReducer(persistConfig, materialsReducer);

const store = createStore(
  combineReducers({
    formDetails: formReducer,
    materials: materialsReducer,
    constructionOptions: constructionOptionsReducer,
    clientSignature: clientSignatureReducer,
    currentSelections: currentSelectionsReducer,
  }),
  composeWithDevTools()
);

// const persistor = persistStore(store);

// export {store, persistor};
export { store };
