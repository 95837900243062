import React from 'react';
import { Container, Row } from 'react-bootstrap';
import LoginForm from '../component/LoginForm';

function Login() {
  const body = {
    padding: '3rem',
    textAlign: 'center',
  };

  return (
    <div style={body}>
      <LoginForm></LoginForm>
    </div>
  );
}

export default Login;
