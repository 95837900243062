import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  numConstructionSquares,
  numDemoSquares,
  numLayers,
  projectType,
} from "../redux/actions/ConstructionOptions";
import { showErrorModal } from "../redux/actions/FormActions";
import BaseMaterial from "./BaseMaterial";
import Pitch from "./Pitch";
import SingleOrTorch from "./SingleOrTorch";
import BackButton from "./SubComponent/BackButton";
import InputModal from "./SubComponent/InputModal";

function RoofOption({ type }) {
  // Variables to hold the bootstrap code for which color the buttons should display
  let active = "btn-primary";
  let inactive = "btn-secondary";

  // Connection which allows this component to send information to redux
  const dispatch = useDispatch();
  const redux = useSelector((state) => state.constructionOptions);

  // States that hold the correct color that each button should display
  const [demoColor, setDemoColor] = useState(inactive);
  const [constructColor, setConstructColor] = useState(inactive);
  const [reconstructColor, setReconstructColor] = useState(inactive);
  const [reroofColor, setReroofColor] = useState(inactive);

  // State that determines if the demolition button has been clicked or not
  const [demo, setDemo] = useState(false);

  // State that gets set to the number of demolition layers
  const [layers, setLayers] = useState(0);
  // State that gets set with either new construction or re-construction
  const [status, setStatus] = useState(null);
  const [roofType, setRoofType] = useState(null);

  // States that hold the number of squares for demolition and construction
  const [demoSquares, setDemoSquares] = useState(0);
  const [constructionSquares, setConstructionSquares] = useState(0);

  // State that determines if the BaseMaterial component should be revealed or not
  const [show, setShow] = useState(false);

  const [back, setBack] = useState(false);

  useEffect(() => {
    if (redux.projectType) {
      setStatus(redux.projectType);
    }
    if (redux.constructionSquares) {
      setConstructionSquares(redux.constructionSquares);
    }
    if (redux.layers) {
      setLayers(redux.layers);
      setDemo(true);
    }
    if (redux.demoSquares) {
      setDemoSquares(redux.demoSquares);
      setDemo(true);
    }
    setRoofType(type);
  }, []);

  // Function that sends the status, number of layers, number of demolition squares, and number of construction squares to redux every time any of them change, as well as handles what color the buttons should display
  useEffect(() => {
    dispatch(projectType(status));
    if (+layers > 0) {
      dispatch(numLayers(layers));
    } else {
      dispatch(numLayers(null));
    }
    if (+constructionSquares > 0) {
      dispatch(numConstructionSquares(constructionSquares));
    } else {
      dispatch(numConstructionSquares(null));
    }
    if (status) {
      if (status === "New Roof") {
        setConstructColor(active);
        setReconstructColor(inactive);
        setReroofColor(inactive);
        setDemo(false);
      } else if (status === "Roof Re-cover") {
        setReconstructColor(active);
        setConstructColor(inactive);
        setReroofColor(inactive);
        setDemo(false);
      } else {
        setReconstructColor(inactive);
        setConstructColor(inactive);
        setReroofColor(active);
        setDemo(true);
        setDemoSquares(constructionSquares);
      }
    } else {
      setConstructColor(inactive);
      setReconstructColor(inactive);
      setReroofColor(inactive);
      setDemo(false);
    }
  }, [status, layers, constructionSquares]);

  useEffect(() => {
    if (+demoSquares > 0) {
      dispatch(numDemoSquares(demoSquares));
    } else {
      dispatch(numDemoSquares(null));
    }
  }, [demoSquares]);

  useEffect(() => {
    if (demo) {
      setDemoColor(active);
      dispatch(numLayers(layers));
      dispatch(numDemoSquares(demoSquares));
    } else {
      setDemoColor(inactive);
      dispatch(numLayers(null));
      dispatch(numDemoSquares(null));
    }
  }, [demo]);

  // Function that handles whether new construction or re-construction has been clicked
  const handleConstruct = (e) => {
    if (status === e.target.value) {
      setStatus(null);
    } else {
      setStatus(e.target.value);
    }
  };

  const handleNext = () => {
    if (status && constructionSquares > 0) {
      if (demo && demoSquares > 0) {
        if (layers > 0) {
          return setShow(true);
        }
      } else if (!demo) {
        return setShow(true);
      }
    }
    dispatch(
      showErrorModal({ message: "Please provide all requested information" })
    );
  };

  if (back) {
    if (roofType === "Flat") {
      return <SingleOrTorch />;
    } else {
      return <Pitch />;
    }
  }

  if (show) {
    return <BaseMaterial />;
  }

  const styles = {
    button: {
      height: "100px",
      width: "200px",
      fontSize: "30px",
    },
  };

  return (
    <div className="my-5">
      <h1 className="text-center">{type} Roof</h1>
      <div className="row d-flex justify-content-center my-3">
        <button
          style={styles.button}
          className={"btnshadow btn my-2 mr-1 " + constructColor}
          value="New Roof"
          onClick={(e) => handleConstruct(e)}
        >
          New Roof
        </button>
        {roofType === "Flat" ? (
          <button
            style={styles.button}
            className={"btnshadow btn my-2 mx-1 " + reconstructColor}
            value="Roof Re-cover"
            onClick={(e) => handleConstruct(e)}
          >
            Roof Re-cover
          </button>
        ) : null}
        <button
          style={styles.button}
          className={"btnshadow btn my-2 ml-1 " + reroofColor}
          value="Re-Roof"
          onClick={(e) => handleConstruct(e)}
        >
          Re-Roof
        </button>
      </div>
      <div className="row d-flex justify-content-center mb-4">
        <label className="mr-1">Squares: </label>
        <input
          type="number"
          value={constructionSquares}
          onChange={(e) => setConstructionSquares(e.target.value)}
        />
      </div>
      {/* The demolition squares input is only revealed if the demolition button is clicked */}
      {demo ? (
        <div>
          {/* <div className="row d-flex justify-content-center">
            <button
              style={styles.button}
              className={"btnshadow btn my-3 " + demoColor}
              onClick={() => setDemo(!demo)}
            >
              Demolition
            </button>
          </div> */}
          <h1 className="text-center">Demolition</h1>
          <div className="row mt-3 d-flex justify-content-center">
            <label className="mr-3">Layers: </label>
            <input
              type="number"
              min="0"
              max="4"
              value={layers}
              style={{ width: "150px" }}
              onChange={(e) => setLayers(e.target.value)}
            />
            </div>
            <div className="row mt-2 mb-2 d-flex justify-content-center">
              <label className="mr-1">Demolition Squares: </label>
              <input
                type="number"
                style={{ width: "150px" }}
                min="0"
                value={demoSquares}
                onChange={(e) => setDemoSquares(e.target.value)}
              />
            </div>
          </div>
       
      ) : null}
      <BackButton setBack={setBack} handleNext={handleNext} />
    </div>
  );
}

export default RoofOption;
