import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setFormDetails } from "../redux/actions/FormActions";
import Classification from "./Classification";
import ErrorModal from "./SubComponent/ErrorModal";
import PhoneInput from "react-phone-number-input/input";
import "./WorkProposal.css";
import { TextField } from "@material-ui/core";
import { FormLabel } from "@material-ui/core";
import ProposalLog from "./ProposalLog";
// import NumberFormat from 'react-number-format';

function WorkProposal({ inputComponent }) {
  // Connection which allows this component to send information to redux
  const dispatch = useDispatch();
  const history = useHistory();
  const { info } = useSelector((state) => state.formDetails);
  let token = JSON.parse(atob(localStorage.getItem("token").split(".")[1]));

  // States that hold the information from the form as it is typed in
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [number, setNumber] = useState("");
  const [date, setDate] = useState(
    new Date().toLocaleDateString("pt-br").split("/").reverse().join("-")
  );

  const [contractLog, setContractLog] = useState(false);

  const [next, setNext] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (info) {
      setFirstName(info.firstName);
      setLastName(info.lastName);
      setEmail(info.email);
      setAddress(info.address);
      setNumber(info.number);
      setDate(info.date);
    }
  }, []);

  const handleNext = () => {
    if (
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      address === "" ||
      number.length < 12
    ) {
      setShowError(true);
    } else {
      const formDetails = { firstName, lastName, email, address, number, date };
      dispatch(setFormDetails(formDetails));
      setNext(true);
    }
  };

  const styles = {
    marginBottom: {
      marginBottom: "1rem",
    },
    marginBottom2: {
      marginBottom: "1.3rem",
    },
    marginBottom3: {
      marginBottom: "1.7rem",
    },
    marginBottom4: {
      marginBottom: "2.2rem",
    },
    notice: {
      backgroundColor: "red",
      color: "white",
      padding: "20px",
    },
    form: {
      width: "80vw",
      maxWidth: "800px",
      margin: "0 auto",
    },
  };

  if (next) {
    return <Classification />;
  }

  if (contractLog) {
    return <ProposalLog />;
  }

  return (
    <div>
      <ErrorModal
        showError={showError}
        setShowError={setShowError}
        message="Please fill out each section of the form before moving on"
      />
      <div className="container mt-5 mb-5">
        <form className="proposal-container top-corners" style={styles.form}>
          {/* <h1>WORK PROPOSAL</h1> */}
          <h1>CLIENT INFORMATION</h1>
          <TextField
            className="input-width"
            label="First Name"
            style={styles.marginBottom2}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          ></TextField>
          <TextField
            className="input-width"
            label="Last Name"
            style={styles.marginBottom2}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          ></TextField>
          <TextField
            className="input-width"
            label="Email Address"
            style={styles.marginBottom2}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></TextField>
          <TextField
            className="input-width"
            label="Project Address"
            style={styles.marginBottom4}
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          ></TextField>
          <div className="row" style={{ width: "105%" }}>
            <div className="col-md-6">
              <FormLabel className="phone-label">Phone Number</FormLabel>
              <div>
                <PhoneInput
                  country="US"
                  type="tel"
                  className="input-width-phone"
                  style={styles.marginBottom3}
                  value={number}
                  placeholder="(xxx) xxx-xxxx"
                  onChange={setNumber}
                />
              </div>

              <FormLabel className="phone-label">Current Date</FormLabel>
              <div className="input-width-date">
                <TextField
                  className="input-width-date"
                  id="date"
                  type="date"
                  defaultValue={date}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </div>
          </div>
        </form>
        <div className="proposal-bottom" style={styles.form}>
          <div style={styles.notice} className="bottom-corners mb-3">
            <h3>
              <i>NOTICE:</i>
            </h3>
            <p>
              I/WE, the Owner(s) of the premises described above authorize
              Southland Roofing hereinafter referred to as “Contractor”, to
              furnish all materials and labor necessary to roof and/or improve
              these premises in a good, workmanlike and substantial manner
              according to the following terms, specifications and provisions:
            </p>
          </div>
          <div className="row d-flex justify-content-around">
            <button
              href="#"
              className="back-button mt-5 mb-3"
              onClick={() => setContractLog(true)}
            >
              Contract Log
            </button>
            &nbsp;
            {token.role === 1 ? (
              <button
                href="#"
                className="back-button mt-5 mb-3"
                onClick={() => history.push("/edit")}
              >
                Edit Selections
              </button>
            ) : null}
            &nbsp;
            <button
              href="#"
              className="submit-button mt-5 mb-3"
              onClick={handleNext}
            >
              Next <i className="fas fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkProposal;
