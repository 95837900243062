import React from "react";
import Modal from "react-bootstrap/Modal";

function InputModal({ show, handleClose, title, input, setInput, label }) {
  return (
    <Modal centered size="sm" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <label className="text-center mt-1">{label}: </label>
      <input
        type="number"
        min="0"
        className="mb-4 mx-2"
        value={input.measure}
        onChange={(e) => setInput({ ...input, measure: e.target.value })}
      />
    </Modal>
  );
}

export default InputModal;
