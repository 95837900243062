import React, { useState, useEffect } from "react";

function ColorOptions({
  color,
  selectedColor,
  setSelectedColor
}) {
  // Variables that hold the bootstrap code for the color the button should display
  const inactive = "btn-secondary";
  const active = "btn-primary";

  // State that holds one of the two above variables
  const [btnColor, setBtnColor] = useState(inactive);

  // Function that handles the selection of a color
  const handleColor = () => {
    // If the clicked color was already set to the 'selectedColor' state, this will remove it, and if the component was passed a setShowArea prop, it will hide the Area component
    if (selectedColor === color) {
      setSelectedColor(null);
    } else {
      // Sets the current color to the 'selectedColor' state
      setSelectedColor(color);
    };
  };

  // Function that determines the color the button should display
  useEffect(() => {
    selectedColor === color ? setBtnColor(active) : setBtnColor(inactive);
  }, [selectedColor]);

  const styles = {
    button: {
      height: "75px",
      width: "200px",
      fontSize: "30px",
    },
  };
  return (
    <div className="col col-md-4">
      <button
        style={styles.button}
        className={"btnshadow btn my-3 " + btnColor}
        onClick={handleColor}
      >
        {color}
      </button>
    </div>
  );
}

export default ColorOptions;
