import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./Calculator.css";

function Calculator({show, handleClose}) {
  const [number, setNumber] = useState(0);
  const [firstNum, setFirstNum] = useState(0);
  const [secondNum, setSecondNum] = useState("");
  const [operation, setOperation] = useState(""); 

  const getNum = (e) => {
    let num = e.target.value.toString();
    if (!operation) {
      first(num);
    } else {
      second(num);
    }
  };

  const first = (num) => {
    if (number === 0) {
      setNumber(num);
    } else {
      let newNumber = number.toString() + num;
      setNumber(newNumber);
    }
  };

  const second = (num) => {
    if (!secondNum) {
      setNumber(num);
      setSecondNum(num);
    } else {
      let newNumber = number.toString() + num;
      setNumber(newNumber);
      setSecondNum(newNumber);
    }
  };

  const handleOperation = (e) => {
    setFirstNum(number);
    setOperation(e.target.value);
  };

  const handleEqual = () => {
    let result;
    if (!secondNum) {
      result = number;
    } else {
      switch (operation) {
        case "+":
          result = +firstNum + +secondNum;
          break;
        case "-":
          result = +firstNum - +secondNum;
          break;
        case "*":
          result = +firstNum * +secondNum;
          break;
        case "/":
          result = +firstNum / +secondNum;
          break;
        default:
          return;
      }
    }
    setNumber(result);
    setFirstNum(result);
    setSecondNum("");
    setOperation("");
  };

  const handleClear = () => {
    setNumber(0);
    setFirstNum(0);
    setSecondNum("");
    setOperation("");
  };

  const close = () => {
    handleClose();
  };

  const styles = {
    modal: {
      width: "auto"
    },
    infoModal: {
      padding: '1.5'
    }
  }

  return (
    <Modal centered show={show} onHide={handleClose} size="sm">
      <div
        className="calculator-modal d-flex justify-content-center"
        id="calculator-modal"
      >
        <div className="calculator" id="calculator">
          {/* <!-- Display --> */}
          <div className="calculator-display">
            <h1>{number}</h1>
          </div>
          {/* <!-- Buttons --> */}
          <div className="calculator-buttons">
            <button
              className="operator"
              value="+"
              onClick={(e) => handleOperation(e)}
            >
              +
            </button>
            <button
              className="operator"
              value="-"
              onClick={(e) => handleOperation(e)}
            >
              -
            </button>
            <button
              className="operator"
              value="*"
              onClick={(e) => handleOperation(e)}
            >
              ✕
            </button>
            <button
              className="operator"
              value="/"
              onClick={(e) => handleOperation(e)}
            >
              ÷
            </button>
            <button value="7" onClick={(e) => getNum(e)}>
              7
            </button>
            <button value="8" onClick={(e) => getNum(e)}>
              8
            </button>
            <button value="9" onClick={(e) => getNum(e)}>
              9
            </button>
            <button value="4" onClick={(e) => getNum(e)}>
              4
            </button>
            <button value="5" onClick={(e) => getNum(e)}>
              5
            </button>
            <button value="6" onClick={(e) => getNum(e)}>
              6
            </button>
            <button value="1" onClick={(e) => getNum(e)}>
              1
            </button>
            <button value="2" onClick={(e) => getNum(e)}>
              2
            </button>
            <button value="3" onClick={(e) => getNum(e)}>
              3
            </button>
            <button className="decimal" value="." onClick={(e) => getNum(e)}>
              .
            </button>
            <button value="0" onClick={(e) => getNum(e)}>
              0
            </button>
            <button className="clear" id="clear-btn" onClick={handleClear}>
              C
            </button>
            <button
              className="equal-sign operator"
              value="="
              onClick={handleEqual}
            >
              =
            </button>
            <button className="close-calculator" onClick={close}>
              Close Calculator
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default Calculator;
