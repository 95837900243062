import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMetalStyle } from "../redux/actions/MaterialsActions";
import Color from './Color';
import BackButton from "./SubComponent/BackButton";
import StyleOptions from "./SubComponent/StyleOptions";
import Roof from "./Roof";
import { showErrorModal } from "../redux/actions/FormActions";

function MetalStyle({show, roofOptions}) {
    // Connection which allows this component to send information to redux
    const dispatch = useDispatch();

    const { metalStyle } = useSelector(state => state.materials);

    // State that holds the selected style
    const [selectedStyle, setSelectedStyle] = useState(null);

    // State that determines whether the next component should be revealed or not
    const [showNext, setShowNext] = useState(false);
    const [back, setBack] = useState(false);

    useEffect(() => {
        if (metalStyle) {
            setSelectedStyle(metalStyle);
        };
    }, []);

    // Function that sends the selected style to redux
    useEffect(() => {
        if (show) {
            dispatch(setMetalStyle(selectedStyle));
        } else {
            dispatch(setMetalStyle(null));
        }
    }, [selectedStyle]);

    const handleNext = () => {
        if (selectedStyle) {
            setShowNext(true);
        } else {
            dispatch(showErrorModal(true));
        };
    };

    const styles = ["Seamless", "Ribbed", "Simulated"];

    if (back) {
        return <Roof />;
    };

    if (showNext) {
        return <Color roofOptions={roofOptions}/>;
    };

    return (
        <div>
            {/* If show, a prop sent to this component, is true, then this component's contents will be displayed, otherwise this component will move on to the next component */}
          {show ? 
              <div className="container my-5">
                  <h1 className="text-center">Style</h1>
                  <div className="row align-items-center text-center justify-content-center">
                      {/* Maps through the style options and creates a StyleOptions component for each option */}
                  {styles.map(style => (
                      <StyleOptions key={style} style={style} selectedStyle={selectedStyle} setSelectedStyle={setSelectedStyle} setShowNext={setShowNext}/>
                  ))}
                  </div>
                  <BackButton setBack={setBack} handleNext={handleNext}/>
              </div>
            :
            <Color roofOptions={roofOptions}/>
          }
          {/* The Color component will only be shown when the showNext state is set to true */}
          {/* {showNext ? <Color roofOptions={roofOptions}/> : null}   */}
        </div>
    );
};

export default MetalStyle;
