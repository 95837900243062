import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import API from "../utils/API";
import "./EmailForm.css";
import { TextField } from "@material-ui/core";
import ProposalLog from "./ProposalLog";
import spinner from "../img/spinner.gif";

function SendChangeOrder({ handleBack, url }) {
  const formDetails = useSelector((state) => state.formDetails);
  const changeOrder = useSelector((state) => state.formDetails.changeOrder);
  const proposalData = useSelector(
    (state) => state.formDetails.changeOrder.proposalData
  );

  const [log, setLog] = useState(null);
  let token = JSON.parse(atob(localStorage.getItem("token").split(".")[1]));

  // Email preview/edit functionality
  const defaultMessage = `Hi ${proposalData?.firstName},

We have received the changes you requested have created a change order attached down below. As requested the new quote including the scope of work necessary to complete the changes for the project located at ${
    proposalData?.projectAddress
  }. \n\nPlease take a look and review everything, make changes and comments if necessary that are brought upon your concern. \nWhen you are ready to sign the change order, please proceed to -esignature link- \nHope to hear from you soon. Again pleasure meeting with you today! Thank you for your patience and I hope you success and good health in this year of ${
    proposalData?.date.split("-")[0]
  }.\n\n${token.firstName} ${token.lastName}\nSales Estimator`;

  // State that determines to go back
  const [fullName, setFullName] = useState(
    proposalData?.firstName + " " + proposalData?.lastName
  );
  const [email, setEmail] = useState(proposalData?.email);
  const [subject, setSubject] = useState(
    "CHANGE ORDER FOR PROJECT: " + proposalData?.projectAddress
  );
  const [message, setMessage] = useState(defaultMessage);

  const styles = {
    button: {
      height: "75px",
      width: "200px",
      fontSize: "30px",
      boxShadow: "2px 2px 20px -10px black",
    },
  };

  function submitEmail(e) {
    e.preventDefault();
    let data = {
      url: url,
      scopeOfWork: changeOrder.scopeOfWork,
      priceChange: changeOrder.priceChange,
      newContractTotal: changeOrder.newContractTotal,
      add: changeOrder.add,
    };

    API.addChangeOrder(proposalData._id, data).then((res) => {
      let formData = {
        fullName: fullName,
        email: email,
        subject: subject,
        message: message.replace(
          /-esignature link-/g,
          `${process.env.REACT_APP_SERVER_URL}/sign/${res.data.data._id}/${res.data.data.changeOrders.length}`
        ),
        images: formDetails?.images,
      };

      API.sendEmail(formData);
      alert("Change order saved and email sent!");
      setLog(true);
    });
  }

  const marginBottom = {
    marginBottom: "1.2rem",
  };

  const marginBottom2 = {
    marginBottom: "2.2rem",
  };

  if (log) {
    return <ProposalLog />;
  } else {
    return (
      <div className="container my-5">
        <div className="email-form-container">
          <form className="contact-form" onSubmit={submitEmail}>
            <h2 className="email-heading">CHANGE ORDER</h2>

            <TextField
              className="email-input"
              style={marginBottom}
              label="Client Name"
              id="name"
              key="name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />

            <TextField
              className="email-input"
              style={marginBottom}
              label="Client Email"
              id="email"
              type="email"
              key="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <TextField
              className="email-input"
              style={marginBottom2}
              label="Email Subject"
              id="subject"
              type="test"
              key="subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />

            <TextField
              className="email-textarea"
              id="outlined-multiline-static"
              label="Email Body"
              multiline
              rows={10}
              defaultValue="Default Value"
              variant="outlined"
              defaultValue={defaultMessage}
              onChange={(e) => setMessage(e.target.value)}
            />
            {url.endsWith(".pdf") ? (
              <div className="submitDiv">
                <button type="submit" className="submit" value="Send Email">
                  Send Email <i className="fas fa-envelope"></i>
                </button>
              </div>
            ) : (
              <img
                src={spinner}
                alt="loading..."
                style={{ width: "200px", margin: "0 auto -40px" }}
              />
            )}
          </form>
        </div>
        <div className="row d-flex justify-content-around">
          <button
            className="email-back-button mt-5 mb-3"
            style={styles.button}
            onClick={handleBack}
          >
            <i className="fas fa-arrow-left"></i> Back
          </button>
        </div>
      </div>
    );
  }
}

export default SendChangeOrder;
