import React from 'react';
import Modal from "react-bootstrap/Modal";

function ErrorModal({showError, setShowError, message = "Please make a selection before moving on"}) {
    return (
        <Modal centered size="md" show={showError} onHide={() => setShowError(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Southland Roofing</Modal.Title>
            </Modal.Header>
            <h2 className="text-center p-5">{message}</h2>
        </Modal>
    )
}

export default ErrorModal;
