const clientSignatureReducer = (state = {}, action) => {
  let newState;
  switch (action.type) {
    case "SET_CLIENT_SIGNATURE":
      newState = {
        ...state,
        clientSignature: action.payload,
      };
      return newState;
    case "SET_CONTRACT_DETAILS":
      newState = {
        ...state,
        contractDetails: action.payload,
      };
      return newState;
    default:
      return state;
  }
};

export default clientSignatureReducer;
