import React, { useState, useEffect, useRef } from "react";
import RoofMaterial from "./SubComponent/RoofMaterial";
import { useDispatch, useSelector } from "react-redux";
import { setMaterials } from "../redux/actions/MaterialsActions";
import MetalStyle from "./MetalStyle";
import BackButton from "./SubComponent/BackButton";
import BaseMaterial from "./BaseMaterial";
import { showErrorModal } from "../redux/actions/FormActions";

function Options() {
  // Connection which allows this component to send information to redux
  const dispatch = useDispatch();

  // Grabs the current base material from redux
  const { base, roof } = useSelector((state) => state.materials);

  const check = useRef();

  // State that determines if the multiple materials checkbox has been checked or not
  const [checkbox, setCheckbox] = useState(false);

  // State that holds the appropriate materials array
  const [options, setOptions] = useState([]);

  // State that holds the selected roofing material(s)
  const [material, setMaterial] = useState(null);

  // State that holds the color the button should display
  const [showNext, setShowNext] = useState(false);
  const [back, setBack] = useState(false);

  // State that determines if the next component should be revealed or not
  const [showStyles, setShowStyles] = useState(false);

  // The arrays of the potential roofing material options, chosen based on the base material set in redux
  const woodMaterials = ["TPO", "PVC", "Epdm", "Fibertite"];
  const concreteMaterials = ["TPO", "PVC", "Epdm", "Fibertite"];
  const steelMaterials = ["TPO", "PVC", "Epdm", "Fibertite"];
  const shingleMaterials = ["Regular", "Presidential"];
  const tileMaterials = ["S-Tile", "U-Tile", "Flat Tile", "Slate"];
  const metalMaterials = ["Metal Tiles", "Standing Seam", "Corrugated Steel", "Rib Metal"];
  const all = {
    woodMaterials,
    concreteMaterials,
    steelMaterials,
    shingleMaterials,
    tileMaterials,
    metalMaterials,
  };

  useEffect(() => {
    if (roof) {
      if (typeof roof === "object") {
        check.current.checked = true;
        setCheckbox(true);
      }
      setMaterial(roof);
    }
  }, []);

  // Function that determines which of the above arrays to set to the 'options' state
  useEffect(() => {
    switch (base) {
      case "Wood":
        setShowStyles(false);
        return setOptions(woodMaterials);
      case "Concrete":
        setShowStyles(false);
        return setOptions(concreteMaterials);
      case "Steel":
        setShowStyles(false);
        return setOptions(steelMaterials);
      case "Shingle":
        setShowStyles(false);
        return setOptions(shingleMaterials);
      case "Tile":
        setShowStyles(false);
        return setOptions(tileMaterials);
      case "Metal":
        setShowStyles(true);
        return setOptions(metalMaterials);
      default:
        return;
    }
  }, [base]);

  // Function that handles sending the selected material(s) to redux
  useEffect(() => {
    if (checkbox) {
      if (material.length > 0) {
        dispatch(setMaterials(material));
      } else {
        dispatch(setMaterials(null));
      }
    } else {
      dispatch(setMaterials(material));
    }
  }, [material]);

  const handleCheckbox = () => {
    if (!checkbox) {
      if (material) {
        setMaterial([material]);
      } else {
        setMaterial([]);
      }
    } else {
      setMaterial(null);
    }
    setCheckbox(!checkbox);
  };

  const handleNext = () => {
    if (material) {
      if (typeof material === "object") {
        if (material.length > 0) {
          setShowNext(true);
        } else {
          dispatch(showErrorModal(true));
        }
      } else {
        setShowNext(true);
      }
    } else {
      dispatch(showErrorModal(true));
    }
  };

  const styles = {
    checkbox: {
      marginTop: "5px",
    },
  };

  if (back) {
    return <BaseMaterial />;
  }

  if (showNext) {
    return <MetalStyle show={showStyles} roofOptions={all} />;
  }

  return (
    <div className="container my-5">
      <h1 className="text-center">Roofing Material</h1>
      {/* <div className="row d-flex justify-content-center"> */}
        {/* Whenever the checkbox is checked or unchecked, it updates the 'checkbox' state */}
        {/* <input
          type="checkbox"
          className="mr-1"
          style={styles.checkbox}
          ref={check}
          onChange={handleCheckbox}
        />
        <h5 className="text-center">Multiple Materials</h5> */}
      {/* </div> */}
      <div className="row align-items-center text-center justify-content-center">
        {/* Maps through the selected options array and creates a RoofMaterial component for each option */}
        {options.map((mat) => (
          <RoofMaterial
            mat={mat}
            key={mat}
            checked={checkbox}
            material={material}
            setMaterial={setMaterial}
          />
        ))}
      </div>
      <BackButton setBack={setBack} handleNext={handleNext} />
    </div>
  );
}

export default Options;
