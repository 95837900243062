// eslint-disable-next-line import/no-anonymous-default-export
export default {
  proposal: (store, url, title, descriptions) => {
    let token = JSON.parse(atob(localStorage.getItem('token').split('.')[1]));

    let object = {
      firstName: store.formDetails.info.firstName,
      lastName: store.formDetails.info.lastName,
      email: store.formDetails.info.email,
      projectAddress: store.formDetails.info.address,
      phoneNumber: store.formDetails.info.number,
      date: store.formDetails.info.date,
      title: title,
      projectClassification: store.materials.classification,
      roofType: store.materials.roofType,
      baseMaterial: store.materials.base,
      constructionType: {
        projectType: store.constructionOptions.projectType,
        constructionSquares: store.constructionOptions.constructionSquares,
      },
      roofingMaterial: store.materials.roof,
      color: store.materials.color,
      options: store.constructionOptions.allOptions,
      descriptions: descriptions,
      priceInfo: store.constructionOptions.priceInfo,
      contractPDF: url,
      createdBy: token.id,
    };

    if (store.constructionOptions.demoSquares) {
      object.constructionType.projectType = `Demolition & ${object.constructionType.projectType}`;
      object.constructionType.demoSquares =
        store.constructionOptions.demoSquares;
      object.constructionType.demoLayers = store.constructionOptions.layers;
    }
    if (store.constructionOptions.singleOrTorch) {
      object.plyType = store.constructionOptions.singleOrTorch;
    }
    if (store.constructionOptions.area) {
      object.area = store.constructionOptions.area.area;
    }
    if (store.constructionOptions.pitch) {
      object.pitch = store.constructionOptions.pitch.type;
    }
    if (store.materials.metalStyle) {
      object.style = store.materials.metalStyle;
    }

    return object;
  },
};
