import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import API from '../utils/API';
import './LoginForm.css';
import { TextField } from '@material-ui/core';
import ErrorModal from './SubComponent/ErrorModal';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

function LoginForm(props) {
  const [loginForm, setLoginForm] = useState({
    email: '',
    password: '',
  });

  const [showError, setShowError] = useState(false);
  const [message, setMessage] = useState('');

  const [redirect, setRedirect] = useState({
    url: '/login',
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!loginForm.email || !loginForm.password) {
      alert('Please provide all login information.');
    } else {
      API.login(loginForm)
        .then((res) => {
          localStorage.setItem('token', JSON.stringify(res.data.data.token));
          setRedirect({ url: '/' });
        })
        .catch(() => {
          alert('Email or password is incorrect');
        });
    }
  };

  const marginBottom1 = {
    marginBottom: '.8rem',
  };

  const marginBottom2 = {
    marginBottom: '1.1rem',
  };

  const marginBottom3 = {
    marginBottom: '1.3rem',
  };

  const loginDiv = {
    width: '30rem',
  };

  return (
    <div style={loginDiv} className='container mt-5'>
      <div className='login-container'>
        <form className='login-styles'>
          <h1>LOGIN</h1>
          <TextField
            label='Email address'
            style={marginBottom2}
            value={loginForm.email}
            onChange={(e) =>
              setLoginForm({ ...loginForm, email: e.target.value })
            }
          ></TextField>
          <TextField
            label='Password'
            type='password'
            style={marginBottom3}
            value={loginForm.password}
            onChange={(e) =>
              setLoginForm({ ...loginForm, password: e.target.value })
            }
          ></TextField>

          <div className='submit-margin'>
            <button href='#' className='submit-button' onClick={handleSubmit}>
              Submit
            </button>
          </div>

          <small className='form-text'>
            <a href='/signup' className='redirect mt-4'>
              Don't have an account? Sign up here.
            </a>
          </small>
          <small className='form-text'>
            <a href='/reset' className='redirect mt-4'>
              Forgot your password?
            </a>
          </small>
          <Redirect to={redirect.url} />
        </form>
      </div>
    </div>
  );
}

export default LoginForm;
