import API from "../utils/API";

let selections;

API.getSelections().then((data) => {
  selections = data.data.data;
});

const makeSelections = (state, setState, roofType) => {
  const allOptions = [
    {
      option: '2" Perimeter Flashing',
      measurement: "Linear Feet",
      state: state.perimeterState,
      setState: setState.setPerimeter,
      description: selections.perimeterFlashing2,
      price: 4.5,
      prices: {
        tier1Price: 10,
        tier2Price: 20,
        tier3Price: 30,
        tier4Price: 40,
        tier5Price: 50,
        tier6Price: 60,
        tier7Price: 70,
      },
    },
    {
      option: '3" Perimeter Flashing',
      measurement: "Linear Feet",
      state: state.perimeterState,
      setState: setState.setPerimeter,
      description: selections.perimeterFlashing3,
      price: 6,
      prices: {
        tier1Price: 10,
        tier2Price: 20,
        tier3Price: 30,
        tier4Price: 40,
        tier5Price: 50,
        tier6Price: 60,
        tier7Price: 70,
      },
    },
    {
      option: "Custom Perimeter Flashing",
      measurement: "Linear Feet",
      state: state.perimeterState,
      setState: setState.setPerimeter,
      description: selections.perimeterFlashingC,
      price: 8,
      prices: {
        tier1Price: 10,
        tier2Price: 20,
        tier3Price: 30,
        tier4Price: 40,
        tier5Price: 50,
        tier6Price: 60,
        tier7Price: 70,
      },
    },
    {
      option: '1" ISO Insulation',
      measurement: "Area",
      state: state.insulationState,
      setState: setState.setInsulation,
      description: selections.isoInsulation1,
      price: 110,
      prices: {
        tier1Price: 10,
        tier2Price: 20,
        tier3Price: 30,
        tier4Price: 40,
        tier5Price: 50,
        tier6Price: 60,
        tier7Price: 70,
      },
    },
    {
      option: '2" ISO Insulation',
      measurement: "Area",
      state: state.insulationState,
      setState: setState.setInsulation,
      description: selections.isoInsulation2,
      price: 130,
      prices: {
        tier1Price: 10,
        tier2Price: 20,
        tier3Price: 30,
        tier4Price: 40,
        tier5Price: 50,
        tier6Price: 60,
        tier7Price: 70,
      },
    },
    {
      option: '3" ISO Insulation',
      measurement: "Area",
      state: state.insulationState,
      setState: setState.setInsulation,
      description: selections.isoInsulation3,
      price: 150,
      prices: {
        tier1Price: 10,
        tier2Price: 20,
        tier3Price: 30,
        tier4Price: 40,
        tier5Price: 50,
        tier6Price: 60,
        tier7Price: 70,
      },
    },
    {
      option: '4" ISO Insulation',
      measurement: "Area",
      state: state.insulationState,
      setState: setState.setInsulation,
      description: selections.isoInsulation4,
      price: 180,
      prices: {
        tier1Price: 10,
        tier2Price: 20,
        tier3Price: 30,
        tier4Price: 40,
        tier5Price: 50,
        tier6Price: 60,
        tier7Price: 70,
      },
    },
    {
      option: "ACpad 36x36",
      measurement: "Units",
      state: state.acpadState,
      setState: setState.setAcpad,
      description: selections.acPad,
      price: 60,
      prices: {
        tier1Price: 10,
        tier2Price: 20,
        tier3Price: 30,
        tier4Price: 40,
        tier5Price: 50,
        tier6Price: 60,
        tier7Price: 70,
      },
      flat: true,
    },
    {
      option: "ACpad 48x48",
      measurement: "Units",
      state: state.acpadState,
      setState: setState.setAcpad,
      description: selections.acPad,
      price: 80,
      prices: {
        tier1Price: 10,
        tier2Price: 20,
        tier3Price: 30,
        tier4Price: 40,
        tier5Price: 50,
        tier6Price: 60,
        tier7Price: 70,
      },
      flat: true,
    },
    {
      option: "ACpad 48x60",
      measurement: "Units",
      state: state.acpadState,
      setState: setState.setAcpad,
      description: selections.acPad,
      price: 100,
      prices: {
        tier1Price: 10,
        tier2Price: 20,
        tier3Price: 30,
        tier4Price: 40,
        tier5Price: 50,
        tier6Price: 60,
        tier7Price: 70,
      },
      flat: true,
    },
    {
      option: "ACpad 60x60",
      measurement: "Units",
      state: state.acpadState,
      setState: setState.setAcpad,
      description: selections.acPad,
      price: 120,
      prices: {
        tier1Price: 10,
        tier2Price: 20,
        tier3Price: 30,
        tier4Price: 40,
        tier5Price: 50,
        tier6Price: 60,
        tier7Price: 70,
      },
      flat: true,
    },
    {
      option: "Skylight 36x36",
      measurement: "Units",
      state: state.matOp,
      setState: setState.setMatOp,
      description: selections.skylight,
      price: 60,
      prices: {
        tier1Price: 10,
        tier2Price: 20,
        tier3Price: 30,
        tier4Price: 40,
        tier5Price: 50,
        tier6Price: 60,
        tier7Price: 70,
      },
      single: true,
      flat: true,
    },
    {
      option: "Skylight 48x48",
      measurement: "Units",
      state: state.matOp,
      setState: setState.setMatOp,
      description: selections.skylight,
      price: 80,
      prices: {
        tier1Price: 10,
        tier2Price: 20,
        tier3Price: 30,
        tier4Price: 40,
        tier5Price: 50,
        tier6Price: 60,
        tier7Price: 70,
      },
      single: true,
      flat: true,
    },
    {
      option: "Skylight 48x60",
      measurement: "Units",
      state: state.matOp,
      setState: setState.setMatOp,
      description: selections.skylight,
      price: 100,
      prices: {
        tier1Price: 10,
        tier2Price: 20,
        tier3Price: 30,
        tier4Price: 40,
        tier5Price: 50,
        tier6Price: 60,
        tier7Price: 70,
      },
      single: true,
      flat: true,
    },
    {
      option: "Skylight 60x60",
      measurement: "Units",
      state: state.matOp,
      setState: setState.setMatOp,
      description: selections.skylight,
      price: 120,
      prices: {
        tier1Price: 10,
        tier2Price: 20,
        tier3Price: 30,
        tier4Price: 40,
        tier5Price: 50,
        tier6Price: 60,
        tier7Price: 70,
      },
      single: true,
      flat: true,
    },
    {
      option: "Waterproof Flat Drain",
      measurement: "Units",
      state: state.flatdrainState,
      setState: setState.setFlatdrain,
      description: selections.flatDrain,
      price: 60,
      prices: {
        tier1Price: 10,
        tier2Price: 20,
        tier3Price: 30,
        tier4Price: 40,
        tier5Price: 50,
        tier6Price: 60,
        tier7Price: 70,
      },
    },
    {
      option: "Replace Flat Drain",
      measurement: "Units",
      state: state.flatdrainState,
      setState: setState.setFlatdrain,
      description: selections.flatDrain,
      price: 520,
      prices: {
        tier1Price: 10,
        tier2Price: 20,
        tier3Price: 30,
        tier4Price: 40,
        tier5Price: 50,
        tier6Price: 60,
        tier7Price: 70,
      },
    },
    {
      option: "Replace Recessed Drain",
      measurement: "Units",
      state: state.flatdrainState,
      setState: setState.setFlatdrain,
      description: selections.recessedDrain,
      price: 620,
      prices: {
        tier1Price: 10,
        tier2Price: 20,
        tier3Price: 30,
        tier4Price: 40,
        tier5Price: 50,
        tier6Price: 60,
        tier7Price: 70,
      },
    },
    {
      //
      option: "Replace Roof Hatch 32x32",
      measurement: "Units",
      state: state.roofhatchState,
      setState: setState.setRoofhatch,
      description: selections.roofHatch,
      price: 2400,
      prices: {
        tier1Price: 10,
        tier2Price: 20,
        tier3Price: 30,
        tier4Price: 40,
        tier5Price: 50,
        tier6Price: 60,
        tier7Price: 70,
      },
    },
    {
      option: "Replace Roof Hatch 30x96",
      measurement: "Units",
      state: state.roofhatchState,
      setState: setState.setRoofhatch,
      description: selections.roofHatch,
      price: 3400,
      prices: {
        tier1Price: 10,
        tier2Price: 20,
        tier3Price: 30,
        tier4Price: 40,
        tier5Price: 50,
        tier6Price: 60,
        tier7Price: 70,
      },
    },
    {
      option: "Waterproof Roof Hatch 32x32",
      measurement: "Units",
      state: state.wpRoofHatch,
      setState: setState.setWpRoofHatch,
      description: selections.roofHatch,
      price: 80,
      prices: {
        tier1Price: 10,
        tier2Price: 20,
        tier3Price: 30,
        tier4Price: 40,
        tier5Price: 50,
        tier6Price: 60,
        tier7Price: 70,
      },
    },
    {
      option: "Waterproof Roof Hatch 30x96",
      measurement: "Units",
      state: state.wpRoofHatch,
      setState: setState.setWpRoofHatch,
      description: selections.roofHatch,
      price: 120,
      prices: {
        tier1Price: 10,
        tier2Price: 20,
        tier3Price: 30,
        tier4Price: 40,
        tier5Price: 50,
        tier6Price: 60,
        tier7Price: 70,
      },
    },
    {
      option: "Penetrations",
      measurement: "Units",
      state: state.matOp,
      setState: setState.setMatOp,
      single: true,
      description: selections.penetrations,
      price: 50,
      prices: {
        tier1Price: 10,
        tier2Price: 20,
        tier3Price: 30,
        tier4Price: 40,
        tier5Price: 50,
        tier6Price: 60,
        tier7Price: 70,
      },
    },
    {
      option: "Vapor Barrier",
      measurement: "Area",
      state: state.matOp,
      setState: setState.setMatOp,
      single: true,
      description: selections.vaporBarrier,
      price: 140,
      prices: {
        tier1Price: 10,
        tier2Price: 20,
        tier3Price: 30,
        tier4Price: 40,
        tier5Price: 50,
        tier6Price: 60,
        tier7Price: 70,
      },
    },
    {
      option: '1/4" Densglass',
      measurement: "Area",
      state: state.densglass,
      setState: setState.setDensglass,
      description: selections.densglass,
      price: 90,
      prices: {
        tier1Price: 10,
        tier2Price: 20,
        tier3Price: 30,
        tier4Price: 40,
        tier5Price: 50,
        tier6Price: 60,
        tier7Price: 70,
      },
    },
    {
      option: '1/2" Densglass',
      measurement: "Area",
      state: state.densglass,
      setState: setState.setDensglass,
      description: selections.densglass,
      price: 120,
      prices: {
        tier1Price: 10,
        tier2Price: 20,
        tier3Price: 30,
        tier4Price: 40,
        tier5Price: 50,
        tier6Price: 60,
        tier7Price: 70,
      },
    },
    {
      option: "FR10 Underlay",
      measurement: "Area",
      state: state.matOp,
      setState: setState.setMatOp,
      single: true,
      description: selections.underlay,
      price: 30,
      prices: {
        tier1Price: 10,
        tier2Price: 20,
        tier3Price: 30,
        tier4Price: 40,
        tier5Price: 50,
        tier6Price: 60,
        tier7Price: 70,
      },
    },
    {
      option: 'Install Scupper (4"x4")',
      measurement: "Units",
      state: state.scuppers,
      setState: setState.setScuppers,
      description: selections.scupper4x4,
      price: 40,
      prices: {
        tier1Price: 10,
        tier2Price: 20,
        tier3Price: 30,
        tier4Price: 40,
        tier5Price: 50,
        tier6Price: 60,
        tier7Price: 70,
      },
    },
    {
      option: 'Install Scupper (4"x6")',
      measurement: "Units",
      state: state.scuppers,
      setState: setState.setScuppers,
      description: selections.scupper4x6,
      price: 45,
      prices: {
        tier1Price: 10,
        tier2Price: 20,
        tier3Price: 30,
        tier4Price: 40,
        tier5Price: 50,
        tier6Price: 60,
        tier7Price: 70,
      },
    },
    {
      option: 'Install Scupper (6"x6")',
      measurement: "Units",
      state: state.scuppers,
      setState: setState.setScuppers,
      description: selections.scupper6x6,
      price: 52,
      prices: {
        tier1Price: 10,
        tier2Price: 20,
        tier3Price: 30,
        tier4Price: 40,
        tier5Price: 50,
        tier6Price: 60,
        tier7Price: 70,
      },
    },
    {
      option: "Walk Pads",
      measurement: "Linear Feet",
      state: state.matOp,
      setState: setState.setMatOp,
      single: true,
      description: selections.walkPads,
      price: 24,
      prices: {
        tier1Price: 10,
        tier2Price: 20,
        tier3Price: 30,
        tier4Price: 40,
        tier5Price: 50,
        tier6Price: 60,
        tier7Price: 70,
      },
    },
    {
      option: "Liquid TPO",
      measurement: "Locations",
      state: state.matOp,
      setState: setState.setMatOp,
      single: true,
      description: selections.liquidTPO,
      price: 60,
      prices: {
        tier1Price: 10,
        tier2Price: 20,
        tier3Price: 30,
        tier4Price: 40,
        tier5Price: 50,
        tier6Price: 60,
        tier7Price: 70,
      },
    },
    {
      option: "Chimney",
      measurement: "Units",
      state: state.matOp,
      setState: setState.setMatOp,
      single: true,
      description: selections.chimney,
      price: 180,
      prices: {
        tier1Price: 10,
        tier2Price: 20,
        tier3Price: 30,
        tier4Price: 40,
        tier5Price: 50,
        tier6Price: 60,
        tier7Price: 70,
      },
    },
    {
      option: "Chimney Waterproofing",
      measurement: "Units",
      state: state.matOp,
      setState: setState.setMatOp,
      single: true,
      description: selections.chimneyWaterproofing,
      price: 180,
      prices: {
        tier1Price: 10,
        tier2Price: 20,
        tier3Price: 30,
        tier4Price: 40,
        tier5Price: 50,
        tier6Price: 60,
        tier7Price: 70,
      },
    },
    {
      option: "Metal Valley",
      measurement: "Linear Feet",
      state: state.matOp,
      setState: setState.setMatOp,
      single: true,
      description: selections.valley,
      price: 100,
      prices: {
        tier1Price: 10,
        tier2Price: 20,
        tier3Price: 30,
        tier4Price: 40,
        tier5Price: 50,
        tier6Price: 60,
        tier7Price: 70,
      },
    },
    {
      option: "Regular Valley",
      measurement: "Units",
      state: state.matOp,
      setState: setState.setMatOp,
      single: true,
      description: selections.valley,
      price: 80,
      prices: {
        tier1Price: 10,
        tier2Price: 20,
        tier3Price: 30,
        tier4Price: 40,
        tier5Price: 50,
        tier6Price: 60,
        tier7Price: 70,
      },
    },
    {
      option: "Ridge",
      measurement: "Linear Feet",
      state: state.matOp,
      setState: setState.setMatOp,
      single: true,
      description: selections.ridge,
      price: 120,
      prices: {
        tier1Price: 10,
        tier2Price: 20,
        tier3Price: 30,
        tier4Price: 40,
        tier5Price: 50,
        tier6Price: 60,
        tier7Price: 70,
      },
    },
    {
      option: "Plywood",
      measurement: "Area",
      state: state.matOp,
      setState: setState.setMatOp,
      single: true,
      description: selections.plywood,
      price: 40,
      prices: {
        tier1Price: 10,
        tier2Price: 20,
        tier3Price: 30,
        tier4Price: 40,
        tier5Price: 50,
        tier6Price: 60,
        tier7Price: 70,
      },
    },
    {
      option: '1" FIBER Insulation',
      measurement: "Area",
      state: state.fiberState,
      setState: setState.setFiberState,
      description: selections.fiberInsulation1,
      price: 110,
    },
    {
      option: '2" FIBER Insulation',
      measurement: "Area",
      state: state.fiberState,
      setState: setState.setFiberState,
      description: selections.fiberInsulation2,
      price: 130,
    },
    {
      option: '3" FIBER Insulation',
      measurement: "Area",
      state: state.fiberState,
      setState: setState.setFiberState,
      description: selections.fiberInsulation3,
      price: 150,
    },
    {
      option: '4" FIBER Insulation',
      measurement: "Area",
      state: state.fiberState,
      setState: setState.setFiberState,
      description: selections.fiberInsulation4,
      price: 180,
    },
    {
      option: "Tar Paper Underlay",
      measurement: "Area",
      state: state.matOp,
      setState: setState.setMatOp,
      single: true,
      description: selections.tarPaperUnderlay,
      price: 30,
    },
    {
      option: "UV Coating",
      measurement: "Area",
      state: state.matOp,
      setState: setState.setMatOp,
      single: true,
      description: selections.uvCoating,
      price: 80,
    },
    {
      option: "1GL Silicone Coating",
      measurement: "Area",
      state: state.siliconeCoatingState,
      setState: setState.setSiliconeCoatingState,
      description: selections.siliconeCoating1GL,
      price: 180,
    },
    {
      option: "2GL Silicone Coating",
      measurement: "Area",
      state: state.siliconeCoatingState,
      setState: setState.setSiliconeCoatingState,
      description: selections.siliconeCoating2GL,
      price: 220,
    },
    {
      option: "3GL Silicone Coating",
      measurement: "Area",
      state: state.siliconeCoatingState,
      setState: setState.setSiliconeCoatingState,
      description: selections.siliconeCoating3GL,
      price: 280,
    },
  ];
  const flat = [
    "Penetrations",
    '2" Perimeter Flashing',
    '3" Perimeter Flashing',
    "Custom Perimeter Flashing",
    "Vapor Barrier",
    '1/4" Densglass',
    '1/2" Densglass',
    '1" ISO Insulation',
    '2" ISO Insulation',
    '3" ISO Insulation',
    '4" ISO Insulation',
    "FR10 Underlay",
    "ACpad 36x36",
    "ACpad 48x48",
    "ACpad 48x60",
    "ACpad 60x60",
    "Skylight 36x36",
    "Skylight 48x48",
    "Skylight 48x60",
    "Skylight 60x60",
    "Waterproof Flat Drain",
    "Replace Flat Drain",
    ,
    "Replace Recessed Drain",
    'Install Scupper (4"x4")',
    'Install Scupper (4"x6")',
    'Install Scupper (6"x6")',
    "Replace Roof Hatch 32x32",
    "Replace Roof Hatch 30x96",
    "Waterproof Roof Hatch 32x32",
    "Waterproof Roof Hatch 30x96",
    "Walk Pads",
    "Liquid TPO",
    "Chimney Waterproofing",
  ];
  const slope = [
    "Penetrations",
    '2" Perimeter Flashing',
    '3" Perimeter Flashing',
    "Custom Perimeter Flashing",
    "Chimney",
    "Metal Valley",
    "Regular Valley",
    "Ridge",
    "Plywood",
    "ACpad 36x36",
    "Skylight 36x36",
    "Skylight 48x48",
    "Skylight 48x60",
    "Skylight 60x60",
  ];
  const torchDown = [
    "Penetrations",
    '2" Perimeter Flashing',
    '3" Perimeter Flashing',
    "Custom Perimeter Flashing",
    '1" ISO Insulation',
    '2" ISO Insulation',
    '3" ISO Insulation',
    '4" ISO Insulation',
    '1" FIBER Insulation',
    '2" FIBER Insulation',
    '3" FIBER Insulation',
    '4" FIBER Insulation',
    "Tar Paper Underlay",
    "ACpad 36x36",
    "ACpad 48x48",
    "ACpad 48x60",
    "ACpad 60x60",
    "Skylight 36x36",
    "Skylight 48x48",
    "Skylight 48x60",
    "Skylight 60x60",
    'Install Scupper (4"x4")',
    'Install Scupper (4"x6")',
    'Install Scupper (6"x6")',
    "Waterproof Flat Drain",
    "Replace Flat Drain",
    "Replace Recessed Drain",
    "Replace Roof Hatch 32x32",
    "Replace Roof Hatch 30x96",
    "Waterproof Roof Hatch 32x32",
    "Waterproof Roof Hatch 30x96",
    "Walk Pads",
    "UV Coating",
    "1GL Silicone Coating",
    "2GL Silicone Coating",
    "3GL Silicone Coating",
    "Chimney Waterproofing",
  ];
  let options;
  if (roofType === "Single Ply") {
    options = allOptions.filter((option) => {
      if (flat.includes(option.option)) {
        if (option.flat) {
          option.single = true;
        }
        return option;
      }
    });
  } else if (roofType === "Torch Down") {
    options = allOptions.filter((option) => {
      if (torchDown.includes(option.option)) {
        return option;
      }
    });
  } else {
    options = allOptions.filter((option) => {
      if (slope.includes(option.option)) {
        return option;
      }
    });
  }
  return options;
};

export default makeSelections;
