import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./component/Global.css";
import "./App.css";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import Everything from "./component/Everything";
import GlobalFooter from "./component/GlobalFooter";
import ClientSignature from "./component/ClientSignature";
import ClientChangeOrder from "./component/ClientChangeOrder";
import ProtectedRoute from "./component/ProtectedRoute";
import ResetRequest from "./component/ResetRequest";
import ResetPassword from "./component/ResetPassword";
import EditSelections from "./component/EditSelections";

function App() {
  return (
    <div>
      <div>
        <Router>
          <Switch>
            <Route exact path="/">
              <ProtectedRoute component={Everything} />
            </Route>
            <Route exact path="/signup" component={Signup}></Route>
            <Route exact path="/login" component={Login}></Route>
            <Route exact path="/reset" component={ResetRequest}></Route>
            <Route exact path="/reset/:id" component={ResetPassword}></Route>
            <Route
              path="/sign/:id/:index"
              component={ClientChangeOrder}
            ></Route>
            <Route path="/sign/:id" component={ClientSignature}></Route>
            <Route exact path="/edit" component={EditSelections}></Route>
          </Switch>
        </Router>
      </div>
      <GlobalFooter />
    </div>
  );
}

export default App;
