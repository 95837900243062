import React, { useState } from 'react';
import { Redirect, useParams } from 'react-router';
import { TextField } from '@material-ui/core';
import API from '../utils/API';
import ErrorModal from './SubComponent/ErrorModal';

function ResetPassword() {
  const params = useParams();

  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');

  const [showError, setShowError] = useState(false);
  const [message, setMessage] = useState('');
  const [redirect, setRedirect] = useState({ url: `/reset/${params.id}` });

  const handleReset = () => {
    if (!password || !confirm) {
      setMessage('Please fill out all information');
      setShowError(true);
    } else if (password != confirm) {
      setMessage('Please enter the same password twice');
      setShowError(true);
    } else {
      API.userDetails(params.id).then((info) => {
        const userData = {
          firstName: info.data.data.firstName,
          lastName: info.data.data.lastName,
          email: info.data.data.email,
          newPassword: password,
        };
        API.updatePassword(params.id, userData).then(() => {
          alert('Password successfully update!');
          setRedirect({ url: '/login' });
        });
      });
    }
  };

  const styles = {
    marginBottom: {
      marginBottom: '1rem',
    },
    marginBottom2: {
      marginBottom: '1.3rem',
    },
    form: {
      width: '80vw',
      maxWidth: '800px',
      margin: '0 auto',
      borderRadius: '10px'
    },
  };

  return (
    <div className="container mt-5">
      <ErrorModal
        showError={showError}
        setShowError={setShowError}
        message={message}
      />
      <form className="proposal-container" style={styles.form}>
        <h1>Reset Password</h1>
        <div className="row" style={{ width: '105%' }}>
          <div className="col-12">
            <TextField
              className="input-width"
              label="New Password"
              type="password"
              style={styles.marginBottom2}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></TextField>
            <TextField
              className="input-width"
              label="Confirm New Password"
              type="password"
              style={styles.marginBottom2}
              value={confirm}
              onChange={(e) => setConfirm(e.target.value)}
            ></TextField>
          </div>
        </div>
      </form>
      <div className="row d-flex justify-content-around">
        <button className="change-order mb-4" onClick={handleReset}>
          Reset Password
        </button>
      </div>
      <Redirect to={redirect.url} />
    </div>
  );
}

export default ResetPassword;
