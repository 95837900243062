import axios from "axios";

const SERVER_URL = "https://southlandroofing.herokuapp.com";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createUser: function (userData) {
    return axios.post(SERVER_URL + "/api/user/add", userData);
  },

  login: function (userData) {
    return axios.post(SERVER_URL + "/api/user/login", userData);
  },

  createProposal: function (proposalData) {
    return axios.post(SERVER_URL + "/api/proposal/add", proposalData);
  },

  addChangeOrder: function (id, data) {
    return axios.post(`${SERVER_URL}/api/proposal/order/${id}`, data);
  },

  sendEmail: function (emailData) {
    return axios.post(SERVER_URL + "/api/email/send", emailData);
  },

  uploadImage: function (imageData) {
    return axios.post(SERVER_URL + "/api/image/upload", imageData);
  },

  userSearch: function (query) {
    return axios.get(`${SERVER_URL}/api/user/list/search/${query}`);
  },

  userDetails: function (id) {
    return axios.get(`${SERVER_URL}/api/user/${id}`);
  },

  updatePassword: function (id, data) {
    return axios.put(`${SERVER_URL}/api/user/${id}`, data);
  },

  proposalDetails: function (id) {
    return axios.get(`${SERVER_URL}/api/proposal/${id}`);
  },

  getProposals: function (id) {
    return axios.get(`${SERVER_URL}/api/proposal/list/${id}`);
  },

  updateProposalContract: function (id, data) {
    return axios.put(`${SERVER_URL}/api/proposal/contract/${id}`, data);
  },

  updateChangeOrder: function (params, data) {
    return axios.put(
      `${SERVER_URL}/api/proposal/contract/${params.id}/${params.index}`,
      data
    );
  },

  getSelections: function () {
    return axios.get(
      `${SERVER_URL}/api/selections/list/60f86cc16cc28300158099ad`
    );
  },

  updateSelections: function (selections) {
    return axios.put(
      `${SERVER_URL}/api/selections/update/60f86cc16cc28300158099ad`,
      selections
    );
  },
};
