import React, { useState, useEffect } from 'react';

import logo from '../img/southlandLogo.png';

import isUrl from 'is-url';

import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Font,
  PDFViewer,
} from '@react-pdf/renderer';

import ReactPDF from '@react-pdf/renderer';

import AlexBrush from '../fonts/AlexBrush-Regular.ttf';

import './ComponentToPrint.css';

function ComponentToPrint({ store, materials, dataMaterials, phone, title }) {
  const { firstName, lastName, email, address, date } = store.formDetails.info;
  const { data } = store.formDetails;
  const { Total, PriceOverride } = store.constructionOptions.priceInfo;
  let token = JSON.parse(atob(localStorage.getItem('token').split('.')[1]));

  Font.register({ family: 'AlexBrush', format: 'truetype', src: AlexBrush });

  const styles = StyleSheet.create({
    page: {
      fontSize: 13,
      backgroundColor: '#FFF',
      width: '100%',
      orientation: 'portrait',
      padding: '30rem 30rem 50rem 30rem',
    },
    initialsHeader: {
      fontSize: 15,
      marginBottom: 20,
      marginRight: 20,
      textAlign: 'right',
    },
    initialsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    contactSection: {
      marginTop: '2rem',
    },
    southlandIMG: {
      width: '215rem',
      height: 'auto',
    },

    // -------- Margins --------
    margin10: {
      marginTop: '10rem',
    },
    margin15: {
      marginTop: '15rem',
    },

    // -------- Heading/Labels/Descriptions --------
    mainHeading: {
      textAlign: 'center',
      fontFamily: 'Helvetica-Bold',
      fontSize: 30,
    },
    materialHeading: {
      textDecoration: 'underline',
      fontWeight: 'bold',
      fontFamily: 'Helvetica-Bold',
    },
    acceptanceHeading: {
      textAlign: 'center',
      textDecoration: 'underline',
      fontFamily: 'Helvetica-Bold',
    },
    acceptanceDescription: {
      marginTop: '10rem',
    },
    // -------- Containers --------
    topContainer: {
      flexDirection: 'row',
      marginTop: '10rem',
    },
    projectInfoContainer: {
      flexDirection: 'column',
      marginTop: '57rem',
      marginLeft: '10rem',
      fontWeight: 'bold',
    },
    nameDateContainer: {
      flexDirection: 'row',
      marginTop: '15rem',
    },
    projectAddressContainer: {
      marginTop: '15rem',
    },
    emailPhoneContainer: {
      flexDirection: 'row',
      marginTop: '15rem',
    },
    totalHeadingContainer: {
      flexDirection: 'row',
      marginTop: '15rem',
      width: '75%',
    },
    materialHeadingContainer: {
      // backgroundColor: "aqua",
    },
    totalLabelContainer: {
      // backgroundColor: "orange",
      marginLeft: '100rem',
    },
    materialDescriptionsContainer: {
      marginTop: '15rem',
    },
    termsAgreementContainer: {
      marginTop: '15rem',
    },
    bottomSignatureContainer: {
      width: '75%',
      flexDirection: 'row',
      marginTop: '15rem',
    },
    southlandSignatureContainer: {
      marginRight: '120rem',
    },

    // -------- Project Info --------
    dottedLine: {
      border: 'none',
      borderTop: '2px dotted #000',
      color: '#fff',
      backgroundColor: '#fff',
      height: '30rem',
      width: '4rem',
      display: 'flex',
      alignSelf: 'flex-end',
    },
    footer: {
      position: 'absolute',
      fontSize: 10,
      bottom: 30,
      left: 550,
      right: 20,
      marginTop: 20,
    },
    footerText: {
      position: 'absolute',
      fontSize: 10,
      bottom: 15,
      left: 30,
      textAlign: 'center',
    },
    map: {
      flexDirection: 'column',
    },
    bold: {
      fontFamily: 'Helvetica-Bold',
    },
    underline: {
      textDecoration: 'underline',
    },
    signature: {
      textDecoration: 'underline',
      fontFamily: 'AlexBrush',
    },

    // ------------- CHANGE ORDER STYLES --------------

    topHeader: {
      textDecoration: 'underline',
      textAlign: 'center',
      fontSize: 30,
      fontFamily: 'Helvetica-Bold',
      marginBottom: '15rem',
    },
    columnContainer: {
      flexDirection: 'column',
    },
    nameMargin: {
      marginBottom: '6rem',
    },
    changeOrderHeading: {
      fontFamily: 'Helvetica-Bold',
      marginRight: '300rem',
    },
    margin25: {
      marginBottom: '25rem',
    },
    acceptanceOfChangeHeading: {
      textAlign: 'center',
    },
  });
  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <Text style={styles.initialsHeader} fixed>
          Initials:………
        </Text>
        <Text style={styles.mainHeading}>WORK PROPOSAL</Text>

        <View style={styles.topContainer}>
          <Image
            src='https://static.wixstatic.com/media/fab652_37f5534b9d804a84bcca95752dfecb56~mv2_d_1920_1784_s_2.jpg/v1/fill/w_1920,h_1784,al_c/fab652_37f5534b9d804a84bcca95752dfecb56~mv2_d_1920_1784_s_2.jpg'
            style={styles.southlandIMG}
          ></Image>
          <View style={styles.projectInfoContainer}>
            <Text
              style={{
                fontFamily: 'Helvetica-Bold',
                textDecoration: 'underline',
                fontSize: 25,
                marginBottom: '3.5rem',
              }}
            >
              PROJECT INFO
            </Text>
            <View style={styles.nameDateContainer}>
              <Text>
                Name:{' '}
                <Text style={styles.underline}>
                  {' '}
                  {firstName} {lastName}{' '}
                </Text>
              </Text>
              <Text>
                Date: <Text style={styles.underline}>{date} </Text>
              </Text>
            </View>
            <View style={styles.projectAddressContainer}>
              <Text>
                Project Address: <Text style={styles.underline}>{address}</Text>{' '}
              </Text>
            </View>
            <View style={styles.emailPhoneContainer}>
              <Text>
                Email: <Text style={styles.underline}>{email}</Text>{' '}
              </Text>
              <Text>
                Tel: <Text style={styles.underline}>{phone}</Text>{' '}
              </Text>
            </View>
          </View>
        </View>
        <View>
          <Text>
            I/WE, the Owner(s) of the premises described above authorize
            Southland Roofing hereinafter referred to as “Contractor”, to
            furnish all materials and labor necessary to roof and/or improve
            these premises in a good, workmanlike and substantial manner
            according to the following terms, specifications and provisions.
            Color: <Text style={styles.bold}>{store.materials.color}</Text>
          </Text>
        </View>
        <View style={styles.totalHeadingContainer}>
          <View style={styles.materialHeadingContainer}>
            <Text style={styles.materialHeading}>{title}</Text>
          </View>
          <View style={styles.totalLabelContainer}>
            <Text>
              ${PriceOverride ? PriceOverride.toFixed(2) : Total.toFixed(2)}
            </Text>
          </View>
        </View>
        <View style={styles.materialDescriptionsContainer}>
          {materials.map((description, i) => (
            <View
              style={{ flexDirection: 'row', fontFamily: 'Helvetica-Bold' }}
              key={i}
            >
              <Text>
                {i < 9 ? ' ' : null}
                {i + 1}. {i < 9 ? ' ' : null}
              </Text>
              <Text>{description}</Text>
            </View>
          ))}
        </View>
        {data
          ? data.map((proposal, index) => (
              <View>
                <View style={styles.totalHeadingContainer}>
                  <View style={styles.materialHeadingContainer}>
                    <Text style={styles.materialHeading}>
                      {dataMaterials[index].dataTitle}
                    </Text>
                  </View>
                  <View style={styles.totalLabelContainer}>
                    <Text>
                      $
                      {proposal.formDetails.PriceOverride
                        ? proposal.formDetails.PriceOverride.toFixed(2)
                        : proposal.constructionOptions.priceInfo.Total.toFixed(
                            2
                          )}
                    </Text>
                  </View>
                </View>
                <View style={styles.materialDescriptionsContainer}>
                  {dataMaterials[index].descriptions.map((description, i) => (
                    <View
                      style={{
                        flexDirection: 'row',
                        fontFamily: 'Helvetica-Bold',
                      }}
                      key={i}
                    >
                      <Text>
                        {i < 9 ? ' ' : null}
                        {i + 1}. {i < 9 ? ' ' : null}
                      </Text>
                      <Text>{description}</Text>
                    </View>
                  ))}
                </View>
              </View>
            ))
          : null}
        <View style={{ marginTop: '15rem', fontFamily: 'Helvetica-Bold' }}>
          <Text>
            All GAF material and work completed to be in compliance with
            Title24, State and city regulations.{' '}
          </Text>
        </View>
        <View style={styles.termsAgreementContainer}>
          <Text>
            CITY FEES AND/OR ANY OTHER WORK/ MATERIAL NOT LISTED HERE SUCH AS
            ELECTRICAL, PLUMBING OR AC WORK IS NOT INCLUDED IN THIS ESTIMATE.
          </Text>
          <Text style={styles.margin15}>
            <Text style={styles.bold}>REPRESENTATIONS AND WARRANTIES:</Text>{' '}
            Southland Roofing Inc. represents and warrants to the Owner that all
            the labor performed by Southland Roofing Inc. includes a ten year
            labor warranty for all new roof projects, and that is a California
            licensed contractor for the specialty for which the Work is being
            performed, such license is not suspended or revoked and such
            suspension or revocation is not likely based on current
            investigations or censures, and Southland Roofing Inc. is fully
            qualified, experienced, bondable, insured and able to complete the
            Work in accordance with the terms of the Contract Documents.
            Southland Roofing Inc. has sufficient employees, working capital and
            equipment to accomplish the Work in a timely manner, notwithstanding
            Contractor’s other jobs or obligations. A timely manner maintenance
            is recommended to keep a valid manufacturer warranty.
          </Text>
        </View>
        <View style={styles.margin15}>
          <Text>
            <Text style={styles.bold}>DELAY AND LIABILITY:</Text> Southland
            Roofing Inc. shall be excused for damages and/or delay in completion
            of this contract caused by acts of God, acts of others, acts of
            customer or customer ‘s agents or representatives poor weather,
            labor disputes, acts of public utilities, public entities or
            inspectors, extra work, failure of prompt payment by customer or
            other unforeseen contingencies beyond the reasonable control of
            Southland Roofing Inc. Southland Roofing Inc. shall not be liable
            for any delay or damages resulting from existing structural defects,
            for settling and/or subsidence of the customer’s structure or for
            the work of other contractors or subcontractors. Southland Roofing
            Inc. shall not be liable for any interior damage whatsoever.
            customer assumes and excludes Southland Roofing Inc. from
            responsibility on smoke alarm installations and/or safety glass
            installations as required by building code. Any specific
            contractor’s guarantee shall extend only to roof failure due to
            fault labor. Guarantee shall not extend to damage caused to the roof
            system by acts of God, any installations mode or objects placed on
            roof, any penetrations made through the roof, malicious mischief,
            vandalism, falling objects, foot traffic, accumulations of debris,
            structural defects of building or roof deck, blockage of drain or
            gutters, or for any other reason involving using the roof for
            purpose other than originally intended. contractor guarantees for a
            period of 10 years following completion of re-roof work done by him
            at the entire building at the above address, he will repair free of
            charge any water leaks occurring in the roof area on which work was
            performed under said Contract, provided it can be shown after
            investigation that said leaks were caused by faulty workmanship or
            installation. This Guarantee shall cover only faulty workmanship or
            installation, and shall not extend to damage to the roofing caused
            by materials, fire, wind, lightning, hail, earthquakes, or
            distortion, warping, settlement or defects in the roof deck upon
            which it is applied or rests; or injury caused by said roof to other
            parties by their actions which are beyond the control of the
            contractor, or to leaks from flashing or parapet walls unless same
            were installed, applied or waterproofed by contractor. It is further
            understood that this guarantee shall not cover leaks through
            skylights, air conditioning or fan units, vents, chimneys, gutters,
            carpentry work or other steel metal fan units, vents, carpentry work
            or other steel metal installations, unless said units have been
            installed or applied by contractor. Warranty is limited to roof
            installation only.
          </Text>
        </View>
        <View style={styles.margin15}>
          <Text>
            <Text style={styles.bold}>PAYMENT:</Text> Owner agrees to pay
            Southland Roofing Inc. for the full and faithful performance of the
            Work, upon section and stage completion. Southland Roofing Inc.
            includes one mobilization (additional mobilizations due to other it
            may incur an additional charge of $2500.00 per each mobilization)
            agrees to accept as full compensation for the Work the sum(s) equal
            to the “See Options Above” shown on this Agreement, in accordance
            with the Payment Schedule, as it follows: $1000 due upon signed
            agreement, 20% demolition completed, 50% upon material delivery and
            remaining balance due upon completion. Each request for payment
            shall be accompanied by a Conditional Waiver and Release Upon
            Progress Payment to conform with Civil Code Section 3262(d)if a
            20-day notice is provided to the owner by any vendor and/or
            subcontractor. The request for Final Payment shall be accompanied by
            an Unconditional Waiver and Release upon Final Payment, with “$0.00”
            inserted in the space for the provision therein for disputed claims
            for extra work, executed by a duly authorized employee or officer of
            Southland Roofing Inc. who has furnished services, labor or material
            for the Project, and a Conditional Waiver and Release upon Final
            Payment executed by Owner. Southland Roofing Inc. reserves the right
            to stop the project at any time if payment is not promptly submitted
            as per the agreement, upon access denial, upon client’s change order
            request until a formal approval is signed, if client interferes with
            the work or the labor force and/ or any other situation that may
            cause delays and/ or financial distress, safety disruption that it
            may be a harm to the company.
          </Text>
        </View>
        <View style={styles.margin15}>
          <Text>
            <Text style={styles.bold}>PROSECUTION OF THE WORK:</Text> Time is of
            the essence of this Agreement, and Southland Roofing Inc. agrees to
            keep itself thoroughly informed as to the progress of the Project,
            begin work upon request of Owner, and diligently pursue Work to
            completion as rapidly as conditions of the Project will permit.
            Southland Roofing Inc. shall efficiently coordinate its Work with
            that of Owner and all other Suppliers so as not to interfere with,
            disrupt or delay the progress required to conform to the Project
            completion. Southland Roofing Inc. agrees not to participate in or
            accede to any cessation of work which may occur as a result of any
            labor dispute. If any labor dispute or difficulty is created by or
            results from the operations of Southland Roofing Inc., Owner, at its
            sole option, may terminate and cancel this Agreement under the
            provisions of this agreement, or as otherwise allowable by law.
          </Text>
        </View>
        <View style={styles.margin15}>
          <Text>
            <Text style={styles.bold}>OBJECTS ON ROOF:</Text> If it is necessary
            for Southland Roofing Inc. to raise, move, or lift objects on the
            roof, Southland Roofing Inc. shall not be considered liable for any
            damages to conduits, signs, skylights, air conditioners, ducts, nor
            any other objects on said roof. Southland Roofing Inc. shall use
            reasonable care in working around such items as television antennas,
            guide wires, lead wires, wiring, cables, conduits, intercom and
            security components, fire sprinkling, and detector or other systems
            in and around the roof; nonetheless Southland Roofing Inc. shall not
            be liable for any damage to said items.
          </Text>
        </View>
        <View style={styles.margin15}>
          <Text>
            <Text style={styles.bold}>POWER SUPPLY TO HOUSE:</Text> Southland
            Roofing Inc. is not responsible for connecting, disconnecting or any
            work that may be required to power supply or connections.
          </Text>
        </View>
        <View style={styles.margin15}>
          <Text>
            <Text style={styles.bold}>ATTORNEY’S FEES:</Text> In any litigation
            between the parties regarding the terms of or performance under this
            contract, the prevailing party shall be entitled to an award of
            reasonable attorney’s fees incurred in prosecuting or defending the
            proceeding.
          </Text>
        </View>
        <View style={styles.margin15}>
          <Text>
            <Text style={styles.bold}>NOTICES:</Text> All notices required or
            permitted by this contract or by law may be delivered personally to
            the party addressed; or in lieu of personal delivery may be sent by
            first-class mail, postage-paid addressed to the party as listed on
            the first and last page of this contract.
          </Text>
        </View>
        <View style={styles.margin15}>
          <Text>
            <Text style={styles.bold}>CHANGE ORDERS:</Text> Any alteration or
            deviation from above specifications involving extra costs will be
            executed only upon written, and will become an extra charge over and
            above the estimate. Southland Roofing Inc. is to charge $65 per
            sheet of plywood and $18-25 per linear foot of fascia (depending on
            the access and the type of fascia) including all the material and
            labor to complete all the additional work to meet city codes and
            regulations. An expediting fee of $250 and all permit and city fees
            from the building and safety department of the city where the
            project is located are additional costs that may be applied to this
            contract. All agreements contingent upon strikes, accidents or
            delays beyond our control. Owner to carry fire, tornado and other
            necessary insurance upon above work. Workmen’s Compensations and
            Public Liability Insurance on above work to be taken out by the
            roofing contractor.
          </Text>
        </View>
        <View style={styles.margin15}>
          <Text>
            <Text style={styles.bold}>ACCEPTANCE:</Text> This contract is
            approved and accepted. I (we) understand that there are no oral
            agreements or understandings between the parties of this agreement.
            The written terms, provisions, plans (if any) and specifications in
            this contract is the entire agreement between the parties. Different
            city regulations may apply and require to bring up to code smog &
            gas detectors, gas shut-off valves or any other requests that are
            not part of the roof/ our scope of work when the roofing is ready to
            be finalized, to these situations the client will be held
            responsible to satisfy the inspectors requests. Changes in this
            agreement shall be done by written change order only and with the
            express approval of both parties. Changes may incur additional
            charges. Be aware this proposal is good for 30 days only. Price may
            be subject to change due to labor and material cost increase.
          </Text>
        </View>
        <View style={styles.margin15}>
          <Text style={styles.bold}>
            You, the Buyer, may cancel this transaction at any time prior to
            midnight of the third business day (in the case of disaster repairs,
            seventh business day) after the date of this traction. You, the
            Homeowner (Buyer) or Tenant have the right to require the Contractor
            to have a performance and payment bond, or funding control approved
            by the Registrar of Contractors. The cost of this bond may be paid
            by the Owner.
          </Text>
        </View>
        <View wrap={false}>
          <View style={styles.margin15}>
            <Text style={styles.acceptanceHeading}>
              Acceptance of Proposal:
            </Text>
            <Text style={styles.acceptanceDescription}>
              The above price, specifications and conditions are satisfactory
              and are hereby accepted:
            </Text>
          </View>
          <View style={styles.bottomSignatureContainer}>
            <View style={styles.southlandSignatureContainer}>
              <Text style={styles.margin10}>Southland Roofing Inc.</Text>
              <Text style={styles.margin10}>
                Print Name:{' '}
                <Text style={styles.underline}>
                  {' '}
                  {token.firstName} {token.lastName}{' '}
                </Text>
              </Text>
              <Text style={styles.margin10}>
                Signature:{' '}
                <Text style={styles.signature}>
                  {' '}
                  {token.firstName} {token.lastName}{' '}
                </Text>
              </Text>
              <Text style={styles.margin10}>
                Date: <Text style={styles.underline}> {date} </Text>
              </Text>
            </View>
            <View style={{ marginLeft: -60 }}>
              <Text style={styles.margin10}>Owner / Representative</Text>
              <Text style={styles.margin10}>
                Print Name: ___________________
              </Text>
              <Text style={styles.margin10}>
                Signature:{'   '}___________________
              </Text>
              <Text style={styles.margin10}>
                Date:{'           '}___________________
              </Text>
            </View>
          </View>
        </View>
        <Text
          style={styles.footer}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
        <View style={styles.footerText} fixed>
          <Text>
            145 S Fairfax Ave. Los Angeles CA 90036 – Tel: 310-903-0536 –
            Office@SouthlandRoofinginc.com.
          </Text>
          <Text>CSLB LIC# 1042623</Text>
        </View>
      </Page>
    </Document>
  );
}

export default ComponentToPrint;
