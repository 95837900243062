const PriceTiers = (
  constructionSquares,
  constructionOptions,
  miscComments,
  demoSquares,
  layers,
  pitch,
  area,
  priceAdjustment,
  priceOverride,
  employeeCommission
) => {
  let cost = {
    Total: 0,
  };

  if (constructionOptions) {
    constructionOptions.forEach((option) => {
      if (option.measure) {
        cost[option.type] = +option.measure * +option.price;
        cost.Total += cost[option.type];
      }
    });
  }
  if (miscComments) {
    let Total = 0;
    let unlabeled = 1;
    miscComments.forEach((comment) => {
      if (comment.price) {
        if (comment.comment === "") {
          cost[`Unlabled price addition ${unlabeled}`] = +comment.price;
          console.log(unlabeled);
          unlabeled++;
        } else {
          cost[comment.comment] = +comment.price;
        }
        Total += +comment.price;
      }
    });
    cost["Miscellaneous Total"] = Total;
    cost.Total += Total;
  }
  if (demoSquares && layers) {
    let staticPrice = +demoSquares * 100;
    let x = 1;
    while (x <= layers) {
      staticPrice += staticPrice * 0.35;
      x++;
    }
    cost.demolition = staticPrice;
    cost.Total += cost.demolition;
  }
  if (pitch) {
    cost.pitch = +pitch.value * cost.Total;
    cost.Total += cost.pitch;
  }
  if (constructionSquares) {
    if (area) {
      cost.area = +constructionSquares * +area.value;
      cost.Total += cost.area;
    }
    let percentage;
    if (constructionSquares <= 17) {
      percentage = 0.7;
    } else if (constructionSquares > 17 && constructionSquares <= 24) {
      percentage = 0.6;
    } else if (constructionSquares > 24 && constructionSquares <= 31) {
      percentage = 0.5;
    } else if (constructionSquares > 31 && constructionSquares <= 39) {
      percentage = 0.4;
    } else if (constructionSquares > 39 && constructionSquares <= 45) {
      percentage = 0.3;
    } else {
      percentage = 0.2;
    }
    cost.constructionSquares = cost.Total * +percentage;
    cost.Total += cost.constructionSquares;
  }
  if (priceAdjustment) {
    cost["Manual price adjustment"] = priceAdjustment;
    cost.Total += priceAdjustment;
  }
  if (priceOverride) {
    cost.PriceOverride = priceOverride;
  }
  if (employeeCommission) {
    cost["Employee Commission"] = employeeCommission;
    cost.Total += employeeCommission;
  }
  return cost;
};

export default PriceTiers;
