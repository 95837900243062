import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setClassification } from '../redux/actions/MaterialsActions';
import RoofType from './RoofType';
import './Global.css';
import WorkProposal from './WorkProposal';
import BackButton from './SubComponent/BackButton';
import { showErrorModal } from '../redux/actions/FormActions';

function Classification() {
  // Connection which allows this component to send information to redux
  const dispatch = useDispatch();

  // Grabs the current classification from redux
  const { classification } = useSelector((state) => state.materials);

  // States used to change color of the buttons
  const [resident, setResident] = useState('choice-unclicked-button');
  const [commerce, setCommerce] = useState('choice-unclicked-button');

  // State that determines if the next component is revealed or not
  const [show, setShow] = useState(false);

  const [back, setBack] = useState(false);

  // Function that handles a classification selection
  const setClass = (e) => {
    // If the clicked classification was already set in redux, this removes the selection and hides the roofType component until a classification is selected again
    if (classification === e) {
      dispatch(setClassification(null));
      // setShow(false);
    } else {
      // Sends classification to redux
      dispatch(setClassification(e));
      // Reveals the next component
      // setShow(true);
    }
  };

  const handleNext = () => {
    if (classification) {
      setShow(true);
    } else {
      dispatch(showErrorModal(true));
    }
  };

  // Checks what the current classification is and changes the button colors accordingly every time the classification changes
  useEffect(() => {
    if (classification) {
      if (classification === 'Residential') {
        setResident('btn-primary');
        setCommerce('btn-secondary');
      } else {
        setResident('btn-secondary');
        setCommerce('btn-primary');
      }
    } else {
      setResident('btn-secondary');
      setCommerce('btn-secondary');
    }
  }, [classification]);

  const styles = {
    residential: {
      backgroundImage:
        "url('https://media.istockphoto.com/photos/beautiful-craftsman-home-exterior-on-bright-sunny-day-with-green-and-picture-id1222625117?k=6&m=1222625117&s=612x612&w=0&h=S9aKR6e-4PE_uKunetpeU8phFopmcMtCz7FGhKQHljI=')",
      height: '50vh',
      backgroundSize: 'cover',
      borderRadius: '10px',
      cursor: 'pointer',
    },
    commercial: {
      backgroundImage:
        "url('https://www.crockett-facilities.com/wp-content/uploads/2015/06/Energy-Loss-Commercial-Building.jpg')",
      height: '50vh',
      backgroundSize: 'cover',
      borderRadius: '10px',
      cursor: 'pointer',
    },
    button: {
      height: '75px',
      width: '200px',
      letterSpacing: '.3px',
      fontSize: '30px',
    },
  };

  if (back) {
    return <WorkProposal />;
  }

  if (show) {
    return <RoofType />;
  }

  return (
    <div className="container my-5">
      <h1 className="text-center">Project Classification</h1>
      <div className="row  my-3 d-flex justify-content-center">
        {/* The variables 'resident' and 'commerce' hold the bootstrap code for the color the button should display */}
        <div
          className=" my-2 col-12 col-lg-5 mx-2 d-flex justify-content-center shadowhover image-hover"
          style={styles.residential}
          onClick={() => setClass('Residential')}
        >
        {/* <div
          className=" my-2 col-12 col-lg-5 mx-1 d-flex justify-content-center shadowhover"
          style={styles.residential}
          onClick={() => setClass('Residential')}
        > */}
          <button
            style={styles.button}
            className={'shadowhover align-self-end mb-2 btn mr-3 ' + resident}
            value="Residential"
            onClick={(e) => setClass(e.target.value)}
          >
            Residential
          </button>
        </div>
        <div
          className="my-2 col-12 col-lg-5 mx-2 d-flex justify-content-center shadowhover image-hover"
          style={styles.commercial}
          onClick={() => setClass('Commercial')}
        >
          <button
            style={styles.button}
            className={'shadowhover align-self-end mb-2 btn ' + commerce}
            value="Commercial"
            onClick={(e) => setClass(e.target.value)}
          >
            Commercial
          </button>
        </div>
      </div>
      <BackButton setBack={setBack} handleNext={handleNext} />
    </div>
  );
}

export default Classification;
