const materialsReducer = (state = {}, action) => {
  let newState;
  switch (action.type) {
    case "SET_CLASSIFICATION":
      if (action.payload === null) {
        newState = { ...state };
        delete newState.classification;
      } else {
        newState = {
          ...state,
          classification: action.payload,
        };
      };
      return newState;
    case "SET_ROOF_TYPE":
      if (action.payload === null) {
        newState = { ...state };
        delete newState.roofType;
      } else {
        newState = {
          ...state,
          roofType: action.payload,
        };
      };
      return newState;
    case "SET_MATERIALS":
      if (action.payload === null) {
        newState = { ...state };
        delete newState.roof;
      } else {
        newState = {
          ...state,
          roof: action.payload,
        };
      };
      return newState;
    case "SET_BASE_TYPE":
      if (action.payload === null) {
        newState = { ...state };
        delete newState.base;
      } else {
        newState = {
          ...state,
          base: action.payload,
        };
      };
      return newState;
    case "SET_COLOR":
      if (action.payload === null) {
        newState = { ...state };
        delete newState.color;
      } else {
        newState = {
          ...state,
          color: action.payload,
        };
      };
      return newState;
    case "SET_METAL_STYLE":
      if (action.payload === null) {
        newState = { ...state };
        delete newState.metalStyle;
      } else {
        newState = {
          ...state,
          metalStyle: action.payload,
        };
      };
      return newState;
    case "SET_PERIMETER_FLASHING_MATERIAL":
      if (action.payload === null) {
        newState = { ...state };
        delete newState.perimFlashMaterial;
      } else {
        newState = {
          ...state,
          perimFlashMaterial: action.payload,
        };
      }
      return newState;
    case "SET_PERIMETER_FLASHING_COLOR":
      if (action.payload === null) {
        newState = { ...state };
        delete newState.perimFlashColor;
      } else {
        newState = {
          ...state,
          perimFlashColor: action.payload,
        };
      }
      return newState;
    case "MATERIAL_RESTART":
      return {};
    default:
      return state;
  }
};

export default materialsReducer;
