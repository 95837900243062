import React from 'react';
import Modal from "react-bootstrap/Modal";

function ImageModal({show, handleClose, images}) {
    return (
        <Modal centered show={show} onHide={handleClose}>
            {images.map(image => (
                <div key={image.type}>
                    <p className="text-center mt-3 mb-0">{image.type}</p>
                    <img src={image.image} className="img-fluid" alt="material image"/>
                </div>
            ))}
        </Modal>
    )
}

export default ImageModal;