export const setConstructionOptions = (payload) => {
  return { type: 'SET_CONSTRUCTION_OPTIONS', payload };
};

export const setMiscellaneousComments = (payload) => {
  return { type: 'SET_MISCELLANEOUS_COMMENTS', payload };
};

export const setSingleOrTorch = (payload) => {
  return { type: 'SET_SINGLE_OR_TORCH', payload };
};

export const setPitchType = (payload) => {
  return { type: 'SET_PITCH', payload };
};

export const numDemoSquares = (payload) => {
  return { type: 'SET_DEMO_SQUARES', payload };
};

export const numLayers = (payload) => {
  return { type: 'SET_LAYERS', payload };
};

export const projectType = (payload) => {
  return { type: 'SET_PROJECT_TYPE', payload };
};

export const numConstructionSquares = (payload) => {
  return { type: 'SET_CONSTRUCTION_SQUARES', payload };
};

export const setArea = (payload) => {
  return { type: 'SET_AREA', payload };
};

export const getTotal = (payload) => {
  return { type: 'GET_TOTAL', payload };
};

export const setDescriptions = (payload) => {
  return { type: 'SET_DESCRIPTIONS', payload };
};

export const constructionRestart = () => {
  return { type: 'CONSTRUCTION_RESTART', payload: null };
};
