import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPerimFlashColor } from "../redux/actions/MaterialsActions";
import ColorOptions from "./SubComponent/ColorOptions";

function FlashingColor() {
    const dispatch = useDispatch();
    const [selectedColor, setSelectedColor] = useState(null);

    useEffect(() => {
        dispatch(setPerimFlashColor(selectedColor));
    }, [selectedColor]);

    const colors = ["White", "Black", "Brown"];
    return (
        <div>
            <p className="text-center mt-2 mb-n1">Color</p>
            <div className="row align-items-center text-center justify-content-center">
                {colors.map(color => (
                    <ColorOptions color={color} selectedColor={selectedColor} setSelectedColor={setSelectedColor} small={true} />
                ))}
            </div>
        </div>
    );
};

export default FlashingColor;