import React, { useState, useEffect } from 'react';

function AdminInput({
  itemName,
  initialPrice,
  initialDescription,
  array,
  optionsArray,
}) {
  const [price, setPrice] = useState(initialPrice);
  const [optionDescription, setOptionDescription] =
    useState(initialDescription);
  const [hasMeasurement, setHasMeasurement] = useState(
    initialDescription
      ? initialDescription.description.variableMeasurement
      : false
  );
  const [subtrahend, setSubtrahend] = useState(initialPrice);

  const handleChange = (e) => {
    setPrice(e.target.value);
    if (+e.target.value != array[itemName]) {
      array[itemName] = +e.target.value;
      const difference = +e.target.value - subtrahend;
      console.log(+e.target.value);
      console.log(subtrahend);
      console.log(difference);
      array['Total'] += difference;
      setSubtrahend(+e.target.value);
    }
  };

  const handleDescriptionChange = (e) => {
    var value = e.target.value;
    setOptionDescription({
      ...optionDescription,
      description: {
        variableMeasurement: hasMeasurement,
        description: hasMeasurement
          ? [value, optionDescription.description.description[1]]
          : value,
      },
    });
  };
  const handleVariableDescriptionChange = (e) => {
    var value = e.target.value;
    setOptionDescription({
      ...optionDescription,
      description: {
        variableMeasurement: hasMeasurement,
        description: [optionDescription.description.description[0], value],
      },
    });
  };

  useEffect(() => {
    if (optionDescription) {
      optionsArray[
        optionsArray.findIndex(
          (option) => option.type === optionDescription.type
        )
      ] = optionDescription;
    }
  }, [optionDescription]);

  useEffect(() => {
    if (hasMeasurement) {
      setOptionDescription({
        ...optionDescription,
        description: {
          variableMeasurement: hasMeasurement,
          description: [
            optionDescription.description.description[0],
            Array.isArray(optionDescription.description.description)
              ? optionDescription.description.description[1]
              : '.',
          ],
        },
      });
    }
  }, [hasMeasurement]);

  return (
    <div>
      <br />
      {itemName}: $
      <input
        type='text'
        className='mb-3'
        style={{ width: '20%' }}
        value={price}
        onChange={(e) => handleChange(e)}
      />
      <br />
      {optionDescription && (
        <>
          {itemName} description: &nbsp;
          {hasMeasurement ? (
            <div className='m-2'>
              <input
                type='text'
                value={optionDescription.description.description[0]}
                onChange={(e) => {
                  handleDescriptionChange(e);
                }}
              ></input>
              &nbsp;*measure*&nbsp;
              <input
                type='text'
                value={optionDescription.description.description[1]}
                onChange={(e) => {
                  handleVariableDescriptionChange(e);
                }}
              ></input>
            </div>
          ) : (
            <input
              type='text'
              className='mb-3'
              style={{ width: '90%' }}
              value={optionDescription.description.description}
              onChange={(e) => {
                handleDescriptionChange(e);
              }}
            />
          )}
          <button
            onClick={() => setHasMeasurement(!hasMeasurement)}
            style={{ fontSize: '1rem', marginBottom: '2rem' }}
          >
            {hasMeasurement ? 'Remove' : 'Add'} Measurement
          </button>
        </>
      )}
    </div>
  );
}

export default AdminInput;
