import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TextField } from "@material-ui/core";
import WorkProposal from "./WorkProposal";
import ErrorModal from "./SubComponent/ErrorModal";
import ChangeOrderPDF from "./ChangeOrderPDF";
import {
  setChangeOrder,
  deleteChangeOrder,
  addImage,
} from "../redux/actions/FormActions";
import { PDFViewer, pdf } from "@react-pdf/renderer";
import SendChangeOrder from "./SendChangeOrder";
import API from "../utils/API";

const ChangeOrder = ({ data }) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);

  const [scopeOfWork, setScopeOfWork] = useState("");
  const [priceChange, setPriceChange] = useState("");
  const [newContractTotal, setNewContractTotal] = useState(
    data.priceInfo.Total
  );
  const [number, setNumber] = useState("");

  const [add, setAdd] = useState(false);
  const [subtract, setSubtract] = useState(false);

  const [showError, setShowError] = useState(false);
  const [message, setMessage] = useState("");
  const [pdfAdded, setPDFAdded] = useState(false);
  const [url, setURL] = useState("");

  const [isReady, setIsReady] = useState(false);
  const [back, setBack] = useState(false);
  const [email, setEmail] = useState(false);

  const handlePDF = () => {
    if (
      scopeOfWork === "" ||
      priceChange === "" ||
      (add === false && subtract === false)
    ) {
      setShowError(true);
      setMessage("Please fill out each section of the form before moving on");
    } else if (newContractTotal < 0) {
      setShowError(true);
      setMessage("New contract total can not be negative");
    } else if (isNaN(priceChange)) {
      setShowError(true);
      setMessage("Please enter a numeric value for price change");
    } else if (isNaN(newContractTotal)) {
      setShowError(true);
      setMessage("Please enter a numeric value for new contract total");
    } else {
      const changeOrder = {
        proposalData: data,
        scopeOfWork,
        priceChange,
        newContractTotal,
        add,
        subtract,
      };
      dispatch(setChangeOrder(changeOrder));
      setIsReady(true);
    }
  };

  const handleAdd = (checked) => {
    setAdd(checked);
    if (checked) setSubtract(false);
  };

  const handleSubtract = (checked) => {
    setSubtract(checked);
    if (checked) setAdd(false);
  };

  const handleNewContractTotal = (value) => {
    setNewContractTotal(value);
    if (+value > data.priceInfo.Total) {
      setPriceChange(parseFloat(+value - data.priceInfo.Total).toFixed(2));
      setAdd(true);
      setSubtract(false);
    } else if (+value < data.priceInfo.Total) {
      setPriceChange(parseFloat(data.priceInfo.Total - +value).toFixed(2));
      setSubtract(true);
      setAdd(false);
    } else {
      setSubtract(false);
      setAdd(false);
    }
  };

  const handleBack = () => {
    setIsReady(false);
  };

  const handlePdfBack = () => {
    setEmail(false);
    setIsReady(true);
  };

  const doc = <ChangeOrderPDF store={store} phone={number} />;

  const handlePDFUpload = async () => {
    let fd = new FormData();
    const blob = await pdf(doc).toBlob();
    fd.append("file", blob, "changeorder.pdf");
    API.uploadImage(fd).then((res) => {
      setURL(res.data);
      dispatch(
        addImage({
          description: `${store.formDetails.changeOrder.proposalData.projectAddress.replace(
            / /g,
            "-"
          )}-${store.formDetails.changeOrder.proposalData.date.substring(
            0,
            10
          )}-Change-Order-${
            store.formDetails.changeOrder.proposalData.changeOrders.length + 1
          }`,
          url: res.data,
        })
      );
    });
  };

  const handleSendEmail = () => {
    if (!pdfAdded) {
      handlePDFUpload();
    }
    setIsReady(false);
    setEmail(true);
  };

  useEffect(() => {
    if (!add && !subtract) {
      setNewContractTotal(parseFloat(data.priceInfo.Total).toFixed(2));
      setPriceChange("");
    }
    if (priceChange) {
      if (subtract)
        setNewContractTotal(
          parseFloat(+data.priceInfo.Total - +priceChange).toFixed(2)
        );
      if (add)
        setNewContractTotal(
          parseFloat(+data.priceInfo.Total + +priceChange).toFixed(2)
        );
    }
  }, [add, subtract]);

  useEffect(() => {
    let formatted = data.phoneNumber.split("");
    formatted.splice(0, 2);
    formatted.unshift("(");
    formatted.splice(4, 0, ")");
    formatted.splice(5, 0, " ");
    formatted.splice(9, 0, "-");
    setNumber(formatted.join(""));
  }, [number]);

  const styles = {
    marginBottom: {
      marginBottom: "1rem",
    },
    marginBottom2: {
      marginBottom: "1.3rem",
    },
    marginBottom3: {
      marginBottom: "1.7rem",
    },
    marginBottom4: {
      marginBottom: "2.2rem",
    },
    notice: {
      backgroundColor: "red",
      color: "white",
      padding: "20px",
    },
    form: {
      width: "80vw",
      maxWidth: "800px",
      margin: "0 auto",
      borderRadius: "10px",
    },
    pdf: {
      height: "100vh",
      width: "60vw",
    },
    priceChangeLabel: {
      display: "flex",
      justifyContent: "flex-end",
      marginBottom: "1.3rem",
    },
  };

  if (back) {
    return <WorkProposal />;
  }

  if (isReady) {
    return (
      <div>
        <div className="row d-flex justify-content-center mt-3">
          <PDFViewer style={styles.pdf}>
            <ChangeOrderPDF store={store} phone={number} />
          </PDFViewer>
        </div>
        <div className="row d-flex justify-content-around">
          <button className="email-back-button mb-3" onClick={handleBack}>
            <i className="fas fa-arrow-left mr-2"></i>
            Back
          </button>
          <button className="send-email-button" onClick={handleSendEmail}>
            Send Email <i className="fas fa-envelope"></i>
          </button>
        </div>
      </div>
    );
  }

  if (email) {
    return <SendChangeOrder handleBack={handlePdfBack} url={url} />;
  }

  return (
    <div className="container mt-5">
      <ErrorModal
        showError={showError}
        setShowError={setShowError}
        message={message}
      />
      <form className="proposal-container" style={styles.form}>
        <h1>CHANGE ORDER</h1>
        <TextField
          className="input-width"
          id="outlined-multiline-static"
          label="Email Body"
          multiline
          rows={8}
          variant="outlined"
          label="Scope of Work"
          style={styles.marginBottom2}
          value={scopeOfWork}
          onChange={(e) => setScopeOfWork(e.target.value)}
        ></TextField>
        <div className="row" style={{ width: "105%" }}>
          <div className="col-6">
            <input
              type="checkbox"
              id="add"
              checked={add}
              onChange={(e) => handleAdd(e.target.checked)}
            />
            <label htmlFor="changeOrder" className="ml-2 mt-4">
              <p>Add</p>
            </label>
            &nbsp;&nbsp;
            <input
              type="checkbox"
              id="subtract"
              checked={subtract}
              onChange={(e) => handleSubtract(e.target.checked)}
            />
            <label htmlFor="changeOrder" className="ml-2 mt-4">
              <p>Subtract</p>
            </label>
          </div>
          {add || subtract ? (
            <div className="col-6">
              <TextField
                className="input-width"
                label="Price Change"
                style={styles.marginBottom2}
                value={priceChange}
                onChange={(e) => {
                  setPriceChange(e.target.value);
                  if (add)
                    setNewContractTotal(
                      (+e.target.value + +data.priceInfo.Total).toFixed(2)
                    );
                  if (subtract)
                    setNewContractTotal(
                      (+data.priceInfo.Total - +e.target.value).toFixed(2)
                    );
                }}
              ></TextField>
            </div>
          ) : null}
        </div>
        <TextField
          className="input-width"
          label="New Contract Total"
          style={styles.marginBottom2}
          value={newContractTotal}
          onChange={(e) => handleNewContractTotal(e.target.value)}
        ></TextField>
      </form>
      <div className="row d-flex justify-content-around">
        <button
          className="back-button mt-5 mb-3"
          style={styles.button}
          onClick={() => {
            setBack(true);
            dispatch(deleteChangeOrder());
          }}
        >
          <i className="fas fa-arrow-left"></i> Back
        </button>
        <button className="change-order mt-5 mb-3" onClick={handlePDF}>
          Generate PDF
        </button>
      </div>
    </div>
  );
};

export default ChangeOrder;
