import React, { useState, useEffect } from "react";
import "../Global.css";

function PitchType({
  type,
  value,
  pitch,
  image,
  setPitch,
  setShow,
  handleShow,
}) {
  // Variables that hold the bootstrap code for which color the button should display
  let unClicked = "btn-secondary";
  let clicked = "btn-primary";

  // State that holds one of the two variables above
  const [color, setColor] = useState(unClicked);

  // Function that handles the selection of a pitch type
  const handlePitch = () => {
    // If the clicked pitch was already set to the 'pitch' state, this removes it and hides the next component until a pitch is selected again
    if (pitch?.type === type) {
      // setShow(false);
      setPitch(null);
    } else {
      // Reveals the next component
      // setShow(true);
      // Sets the current pitch to the 'pitch' state
      setPitch({ type, value });
    }
  };

  // Function that determines which color the pitch button should display
  useEffect(() => {
    if (pitch?.type === type) {
      setColor(clicked);
    } else {
      setColor(unClicked);
    }
  }, [pitch]);

  const styles = {
    button: {
      minHeight: "75px",
      height: "auto",
      minWidth: "200px",
      width: "auto",
      fontSize: "30px",
    },
    image: {
      backgroundImage: `url(${image})`,
      height: "40vh",
      backgroundSize: "cover",
      borderRadius: "10px",
      cursor: "pointer",
    },
  };
  return (
    <div
      className="mx-2 my-2 col-12 col-md-5 col-xl-3 d-flex justify-content-center shadowhover image-hover"
      style={styles.image}
      onClick={handlePitch}
    >
      {/* <div className="row d-flex justify-content-center">
      <img src={image} style={styles.image} onClick={handleShow}/>
      </div> */}
      {/* <div className="row d-flex justify-content-center"> */}
      <button
        style={styles.button}
        className={"btnshadow my-3 align-self-end btn " + color}
        onClick={handlePitch}
      >
        {type}
      </button>
      {/* </div> */}
    </div>
  );
}

export default PitchType;
