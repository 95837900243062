import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setRoofType } from "../redux/actions/MaterialsActions";
import ImageModal from "./ImageModal";
import Pitch from "./Pitch";
import SingleOrTorch from "./SingleOrTorch";
import "./Global.css";
import BackButton from "./SubComponent/BackButton";
import Classification from "./Classification";
import { showErrorModal } from "../redux/actions/FormActions";

function RoofType() {
  // Connection which allows this component to send information to redux
  const dispatch = useDispatch();
  const { roofType } = useSelector(state => state.materials);

  // States used to change color of the buttons
  const [flat, setFlat] = useState("btn-secondary");
  const [slope, setSlope] = useState("btn-secondary");

  // State that holds the roof type selection
  const [type, setType] = useState(roofType ? roofType : null);

  // State that determines which component to show next
  const [show, setShow] = useState(null);
  const [next, setNext] = useState(false);

  const [back, setBack] = useState(false);

  // Array which holds the images for both roofTypes
  const images = [
    {
      type: "Flat",
      image: "https://www.skyluxeroofing.com/wp-content/uploads/2018/05/4.jpg",
    },
    {
      type: "Sloped",
      image:
        "https://www.roofmaster.net/wp-content/uploads/2017/01/flat-vs-sloped-roofing.jpg",
    },
  ];

  useEffect(() => {
    setNext(false);
  }, []);

  // Function that handles a roofType selection
  const handleType = (e) => {
    // If the clicked roofType was already set, this removes the selection and hides the next component until a roofType is selected again
    if (type === e) {
      setType(null);
      setShow(null);
    } else {
      // Sets selection to the 'type' state
      setType(e);
    }
  };

  // Sends the current type to redux and changes the button colors accordingly every time the type changes
  useEffect(() => {
    dispatch(setRoofType(type));
    if (type) {
      if (type === "Flat") {
        setFlat("btn-primary");
        setSlope("btn-secondary");
        setShow(<SingleOrTorch />);
      } else {
        setFlat("btn-secondary");
        setSlope("btn-primary");
        setShow(<Pitch />);
      }
    } else {
      setFlat("btn-secondary");
      setSlope("btn-secondary");
      setShow(null);
    }
  }, [type]);

  const handleNext = () => {
    if (show) {
      setNext(true);
    } else {
      dispatch (showErrorModal(true));
    };
  };

  const styles = {
    flatImage: {
      backgroundImage: `url(${images[0].image})`,
      height: "50vh",
      backgroundSize: "cover",
      borderRadius: "10px",
      cursor: "pointer"
    },
    slopeImage: {
      backgroundImage: `url(${images[1].image})`,
      height: "50vh",
      backgroundSize: "cover",
      borderRadius: "10px",
      cursor: "pointer"
    },
    button: {
      height: "75px",
      width: "200px",
      fontSize: "30px"
    }
  }

  if (back) {
    return <Classification />
  };

  if (next) {
    return show;
  };

  return (
    <div className="container my-5">
      <h1 className="text-center">
        Roof Type{" "}
      </h1>
      <div className="row my-3 d-flex justify-content-center">
        {/* The variables 'flat' and 'slope' hold the bootstrap code for the color the button should display */}
        <div className=" my-2 col-12 col-lg-5 mx-2 d-flex justify-content-center shadowhover image-hover" style={styles.flatImage} onClick={() => handleType("Flat")}>
        <button
          className={"btnshadow align-self-end mb-2 btn mr-3 " + flat}
          style={styles.button}
          value="Flat"
          onClick={(e) => handleType(e.target.value)}
        >
          Flat Roof
        </button>
        </div>
        <div className="my-2 col-12 col-lg-5 mx-2 d-flex justify-content-center shadowhover image-hover" style={styles.slopeImage} onClick={() => handleType("Sloped")}>
        <button
          className={"btnshadow align-self-end mb-2 btn " + slope}
          style={styles.button}
          value="Sloped"
          onClick={(e) => handleType(e.target.value)}
        >
          Sloped Roof
        </button>
        </div>
      </div>
      <BackButton setBack={setBack} handleNext={handleNext}/>
    </div>
  );
}

export default RoofType;
