import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SendToDB from "../utils/SendToDB";
import API from "../utils/API";
import "./EmailForm.css";
import { TextField } from "@material-ui/core";
import ProposalLog from "./ProposalLog";
import spinner from "../img/spinner.gif";

function EmailForm({ handleBack, url, title, descriptions }) {
  // Connection which allows this component to send information to redux
  const store = useSelector((state) => state);
  const formDetails = useSelector((state) => state.formDetails);

  const [log, setLog] = useState(null);
  let token = JSON.parse(atob(localStorage.getItem("token").split(".")[1]));

  // Email preview/edit functionality
  const defaultMessage = `Hi ${formDetails?.info?.firstName},

It was a pleasure talking with you earlier today. I quickly built a proposal attached down below. As requested our quote including all the tools, equipment, material, and labor necessary to complete the project located at ${
    formDetails?.info?.address
  }. Our proposed roofing system comes with the following:\n\n1) Ten (10) Year labor and twenty (20) manufacturer warranty\n2) Class A fire rated \n3) Cool roof (energy efficient = lowers the energy bill by 18% more or less depending on the sun exposure) \n4) No yearly maintenance \n5) Easy repair if ever needed to be repaired. \n\nPlease take a look and review everything, make changes and comments if necessary that are brought upon your concern. \n<strong>When you are ready to sign the contract, please proceed to -esignature link- </strong>\nHope to hear from you soon. Again pleasure meeting with you today! Thank you for your patience and I hope you success and good health in this year of ${
    formDetails?.info?.date.split("-")[0]
  }.\n\n${token.firstName} ${token.lastName}\nSales Estimator\n`;

  // State that determines to go back
  const [fullName, setFullName] = useState(
    formDetails?.info?.firstName + " " + formDetails?.info?.lastName
  );
  const [email, setEmail] = useState(formDetails?.info?.email);
  const [subject, setSubject] = useState(
    "ROOF ESTIMATE PROJECT: " + formDetails?.info?.address
  );
  const [message, setMessage] = useState(defaultMessage);

  const styles = {
    button: {
      height: "75px",
      width: "200px",
      fontSize: "30px",
      boxShadow: "2px 2px 20px -10px black",
    },
  };

  const formatNumber = (number) => {
    let formatted = number.split("");
    formatted.splice(0, 2);
    formatted.unshift("(");
    formatted.splice(4, 0, ")");
    formatted.splice(5, 0, " ");
    formatted.splice(9, 0, "-");
    return formatted.join("");
  };

  function submitEmail(e) {
    e.preventDefault();

    var attachedImages = formDetails?.images;
    if (formDetails.pdf) {
      formDetails.images[formDetails.images.length - 1].url =
        formDetails.pdf.url;
    }

    const proposalData = SendToDB.proposal(store, url, title, descriptions);

    API.createProposal(proposalData).then((res) => {
      let formData = {
        fullName: fullName,
        email: email,
        subject: subject,
        message:
          message.replace(
            /-esignature link-/g,
            `${process.env.REACT_APP_SERVER_URL}/sign/${res.data.data._id}`
          ) +
          `<p>
  <strong>SOUTHLAND ROOFING INC.</strong>
  C: ${formatNumber(token.phoneNumber)} | O: (310) 903-0536
  ${token.email}
  <a>www.southladnroofinginc.com</a>
  CSLB Lic#1042623 | Classification:C39</p>`,
        images: attachedImages,
      };

      API.sendEmail(formData);
      alert("Proposal saved and email sent!");
      setLog(true);
    });
  }

  const marginBottom = {
    marginBottom: "1.2rem",
  };

  const marginBottom2 = {
    marginBottom: "2.2rem",
  };

  if (log) return <ProposalLog token={token} />;
  else {
    return (
      <div className="container my-5">
        <div className="email-form-container">
          <form className="contact-form" onSubmit={submitEmail}>
            <h2 className="email-heading">EMAIL</h2>

            <TextField
              className="email-input"
              style={marginBottom}
              label="Client Name"
              id="name"
              key="name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />

            <TextField
              className="email-input"
              style={marginBottom}
              label="Client Email"
              id="email"
              type="email"
              key="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <TextField
              className="email-input"
              style={marginBottom2}
              label="Email Subject"
              id="subject"
              type="test"
              key="subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />

            <TextField
              className="email-textarea"
              id="outlined-multiline-static"
              label="Email Body"
              multiline
              rows={10}
              variant="outlined"
              defaultValue={defaultMessage}
              onChange={(e) => setMessage(e.target.value)}
            />
            {url.endsWith(".pdf") ? (
              <div className="submitDiv">
                <button
                  type="submit"
                  className="send-email-button"
                  value="Send Email"
                >
                  Send Email <i className="fas fa-envelope"></i>
                </button>
              </div>
            ) : (
              <img
                src={spinner}
                alt="loading..."
                style={{ width: "200px", margin: "0 auto -40px" }}
              />
            )}
          </form>
        </div>
        <div className="row d-flex justify-content-around">
          <button
            className="email-back-button mt-5 mb-3"
            style={styles.button}
            onClick={handleBack}
          >
            <i className="fas fa-arrow-left"></i> Back
          </button>
        </div>
      </div>
    );
  }
}

export default EmailForm;
