import React, { useState, useEffect, useRef } from "react";
import OptionInputs from "./SubComponent/OptionInputs";
import SingleOption from "./SubComponent/SingleOption";
import { useDispatch, useSelector } from "react-redux";
import {
  constructionRestart,
  getTotal,
  setConstructionOptions,
} from "../redux/actions/ConstructionOptions";
import makeSelections from "../Hooks/Selections";
import PriceTiers from "../Hooks/PriceTiers";
import API from "../utils/API";

import "./Options.css";
import { addImage, deleteImage, addPDF } from "../redux/actions/FormActions";

import { PDFDownloadLink, PDFViewer, pdf } from "@react-pdf/renderer";
import PDFDownload from "./SubComponent/PDFDownload";
import ComponentToPrint from "./ComponentToPrint";

import Area from "./Area";
import Color from "./Color";
import {
  addAnotherProposal,
  formRestart,
  showErrorModal,
} from "../redux/actions/FormActions";
import { materialRestart } from "../redux/actions/MaterialsActions";
import WorkProposal from "./WorkProposal";
import Miscellaneous from "./Miscellaneous";
import EmailForm from "./EmailForm";

function Options({ roofType, goBack }) {
  // Connection which allows this component to send information to redux
  const dispatch = useDispatch();

  const componentRef = useRef();

  // Grabs information from redux
  const store = useSelector((state) => state);
  const {
    projectType,
    constructionSquares,
    allOptions,
    miscComments,
    demoSquares,
    layers,
    pitch,
    area,
    singleOrTorch,
  } = store.constructionOptions;
  const { roof, base } = store.materials;

  const {
    priceAdjustment,
    priceOverride,
    employeeCommission,
    data,
    customTitle,
    pdf: uploadedPdf,
  } = store.formDetails;

  // States that hold the information from the options that get selected
  const [matOp, setMatOp] = useState([]);
  const [perimeterState, setPerimeter] = useState(null);
  const [insulationState, setInsulation] = useState(null);
  const [acpadState, setAcpad] = useState(null);
  const [skylightState, setSkylight] = useState(null);
  const [flatdrainState, setFlatdrain] = useState(null);
  const [roofhatchState, setRoofhatch] = useState(null);
  const [wpRoofHatch, setWpRoofHatch] = useState(null);
  const [densglass, setDensglass] = useState(null);
  const [scuppers, setScuppers] = useState(null);
  const [fiberState, setFiberState] = useState(null);
  const [siliconeCoatingState, setSiliconeCoatingState] = useState(null);

  const [isReady, setIsReady] = useState(false);
  const [back, setBack] = useState(null);
  const [restart, setRestart] = useState(false);
  const [email, setEmail] = useState(null);

  const [materials, setMaterials] = useState([]);
  const [dataMaterials, setDataMaterials] = useState([]);
  const [number, setNumber] = useState(null);
  const [title, setTitle] = useState(null);

  const [url, setURL] = useState("");
  const [pdfAdded, setPDFAdded] = useState(false);
  const [pdfIsUploading, setPdfIsUploading] = useState(false);

  useEffect(() => {
    let newTitle;
    if (customTitle) {
      newTitle = customTitle;
    } else {
      if (projectType === "New Roof") {
        newTitle = `New ${base} over ${roof} decking Roofing System`;
      } else if (projectType === "Roof Re-cover") {
        newTitle = `${roof} Re-cover over ${base} decking Roofing System`;
      } else {
        newTitle = `${base} over ${roof} decking Roofing System`;
      }
      newTitle = newTitle.toUpperCase();
    }
    setTitle(newTitle);
  }, [projectType, roof, base, customTitle]);

  useEffect(() => {
    let formatted = store.formDetails.info.number.split("");
    formatted.splice(0, 2);
    formatted.unshift("(");
    formatted.splice(4, 0, ")");
    formatted.splice(5, 0, " ");
    formatted.splice(9, 0, "-");
    setNumber(formatted.join(""));
  }, []);

  const allMaterials = [
    ...matOp,
    perimeterState,
    insulationState,
    acpadState,
    skylightState,
    flatdrainState,
    roofhatchState,
    wpRoofHatch,
    densglass,
    scuppers,
    fiberState,
    siliconeCoatingState,
  ];

  useEffect(() => {
    let text = [
      "Clean, patch and prep roof base prior to any material installation.",
    ];
    if (layers) {
      text.unshift(
        `Demolish and haul all ${layers} layers of the existing roof and any other related roofing material.`
      );
    }
    // if (printOptions) {
    //   printOptions.forEach((option) => {
    //     if (option.description.variableMeasurement) {
    //       text.push(
    //         option.description.description[0] +
    //           option.measure +
    //           option.description.description[1]
    //       );
    //     } else {
    //       text.push(option.description.description);
    //     }
    //   });
    // }
    if (miscComments) {
      miscComments.forEach((comment) => {
        if (comment.showOnPdf) {
          text.push(comment.comment);
        }
      });
    }
    text.push(
      "Remove and dispose all roofing related debris including a magnetic cleanup of any possible nails. Job site to have a clean neat, clean appearance at project completion."
    );
    setMaterials(text);
  }, [miscComments]);

  // useEffect(() => {
  //   setPrintOptions(allOptions);
  //   console.log(allOptions);
  // }, [allOptions]);

  useEffect(() => {
    if (data) {
      let dataOptions = [];
      data.forEach((proposal) => {
        let dataTitle;
        if (proposal.customTitle) {
          dataTitle = proposal.customTitle;
        } else {
          if (proposal.projectType === "New Roof") {
            dataTitle = `New ${proposal.materials.base} over ${proposal.materials.roof} decking Roofing System`;
          } else if (proposal.projectType === "Roof Re-cover") {
            dataTitle = `${proposal.materials.roof} Re-cover over ${proposal.materials.base} deching Roofing System`;
          } else {
            dataTitle = `${proposal.materials.base} over ${proposal.materials.roof} decking Roofing System`;
          }
          dataTitle = dataTitle.toUpperCase();
        }
        let text = [
          "Clean, patch and prep roof base prior to any material installation.",
        ];
        if (proposal.constructionOptions.layers) {
          text.unshift(
            `Demolish and haul all ${proposal.constructionOptions.layers} layers of the existing roof and any other related roofing material.`
          );
        }
        // if (proposal.constructionOptions.allOptions) {
        //   proposal.constructionOptions.allOptions.forEach((option) => {
        //     if (option.description.variableMeasurement) {
        //       text.push(
        //         option.description.description[0] +
        //           option.measure +
        //           option.description.description[1]
        //       );
        //     } else {
        //       text.push(option.description.description);
        //     }
        //   });
        // }
        if (proposal.constructionOptions.miscComments) {
          proposal.constructionOptions.miscComments.forEach((comment) => {
            if (comment.showOnPdf) {
              text.push(comment.comment);
            }
          });
        }
        text.push(
          "Remove and dispose all roofing related debris including a magnetic cleanup of any possible nails. Job site to have a clean neat, clean appearance at project completion."
        );
        let proposalData = { descriptions: text, dataTitle };
        dataOptions.push(proposalData);
      });
      setDataMaterials(dataOptions);
    }
  }, []);

  useEffect(() => {}, [dataMaterials]);

  let optionType;
  if (roofType === "Flat") {
    optionType = singleOrTorch;
  } else {
    optionType = roofType;
  }

  // Function that sends the price information to redux
  useEffect(() => {
    if (allOptions) {
      dispatch(
        getTotal(
          PriceTiers(
            constructionSquares,
            allOptions,
            miscComments,
            demoSquares,
            layers,
            pitch,
            area,
            priceAdjustment,
            priceOverride,
            employeeCommission
          )
        )
      );
    }
  }, [
    constructionSquares,
    allOptions,
    miscComments,
    demoSquares,
    layers,
    pitch,
    area,
    singleOrTorch,
    priceAdjustment,
    priceOverride,
    employeeCommission,
  ]);

  // Function that sends the selected options to redux
  useEffect(() => {
    let constructOptions = [
      ...matOp,
      perimeterState,
      insulationState,
      acpadState,
      skylightState,
      flatdrainState,
      roofhatchState,
      wpRoofHatch,
      densglass,
      scuppers,
      fiberState,
      siliconeCoatingState,
    ];
    constructOptions = constructOptions.filter((x) => x !== null || "");
    dispatch(setConstructionOptions(constructOptions));
  }, [
    matOp,
    perimeterState,
    insulationState,
    acpadState,
    skylightState,
    flatdrainState,
    wpRoofHatch,
    roofhatchState,
    densglass,
    scuppers,
    fiberState,
    siliconeCoatingState,
  ]);

  // Variable that holds the array of selected options from the Selections file
  const options = makeSelections(
    {
      matOp,
      perimeterState,
      insulationState,
      acpadState,
      skylightState,
      flatdrainState,
      roofhatchState,
      wpRoofHatch,
      densglass,
      scuppers,
      fiberState,
      siliconeCoatingState,
    },
    {
      setMatOp,
      setPerimeter,
      setInsulation,
      setAcpad,
      setSkylight,
      setFlatdrain,
      setRoofhatch,
      setWpRoofHatch,
      setDensglass,
      setScuppers,
      setFiberState,
      setSiliconeCoatingState,
    },
    optionType
  );

  const handleNewProposal = () => {
    setIsReady(false);
    setRestart(true);
    dispatch(constructionRestart());
    dispatch(materialRestart());
    dispatch(formRestart());
  };

  const handleRestart = async () => {
    setIsReady(false);
    setRestart(true);
    await dispatch(addAnotherProposal(store));
    dispatch(constructionRestart());
    dispatch(materialRestart());
  };

  const handleSave = (proposalData) => {
    API.createProposal(proposalData).then((res) => {
      if (res.data.data.status === 1) {
        alert("Proposal saved!");
      }
    });
  };

  const handlePDF = () => {
    let ready = true;
    let unfinished = [];
    let descriptions = [];
    let updatedMaterials = materials;
    allOptions.forEach((option) => {
      if (option.measure === "" || option.measure <= 0) {
        ready = false;
        unfinished.push(option.type);
      }
      descriptions.push(option);
    });

    if (ready) {
      if (allOptions.length > 0) {
        allOptions.forEach((option) => {
          if (option.description.variableMeasurement) {
            // console.log(option.description.description);
            updatedMaterials.splice(
              1,
              0,
              option.description.description[0].trim() +
                " " +
                option.measure +
                " " +
                option.description.description[1].trim()
            );
          } else {
            updatedMaterials.splice(
              1,
              0,
              option.description.description.trim()
            );
          }
        });
        setMaterials(updatedMaterials);
        setIsReady(true);
      } else {
        dispatch(showErrorModal(true));
      }
    } else {
      dispatch(
        showErrorModal({
          message: (
            <div>
              <p>Missing input from the following fields:</p>
              <ul>
                {unfinished.map((option) => (
                  <li>{option}</li>
                ))}
              </ul>
            </div>
          ),
        })
      );
    }
  };

  const handleBack = () => {
    if (store.materials.roofType === "Flat") {
      setBack(<Area type={store.materials.roofType} />);
    } else {
      setBack(<Color />);
    }
  };

  const handlePdfBack = () => {
    setMaterials([materials[0], materials[materials.length - 1]]);
    setIsReady(false);
    if (url.endsWith(".pdf")) {
      dispatch(deleteImage(url));
    }
    setPDFAdded(false);
  };

  const styles = {
    pdf: {
      height: "100vh",
      width: "60vw",
    },
    button: {
      height: "100px",
      width: "200px",
      fontSize: "25px",
      boxShadow: "2px 2px 20px -10px black",
    },
  };

  const doc = (
    <ComponentToPrint
      store={store}
      materials={materials}
      dataMaterials={dataMaterials}
      phone={number}
      title={title}
    />
  );

  const handlePDFUpload = async () => {
    let fd = new FormData();
    const blob = await pdf(doc).toBlob();
    fd.append("file", blob, "proposal.pdf");
    API.uploadImage(fd).then((res) => {
      setURL(res.data);
      dispatch(
        addImage({
          description: `${store.formDetails.info.address.replace(/ /g, "-")}-${
            store.formDetails.info.date
          }`,
          url: res.data,
        })
      );
    });
  };

  const handleSendEmail = () => {
    if (!pdfAdded) {
      handlePDFUpload();
    }
    setIsReady(false);
    setEmail(true);
  };

  const handleEmailBack = () => {
    setIsReady(true);
    setEmail(false);
  };

  const handleUpload = (newPDF) => {
    if (newPDF) {
      setPdfIsUploading(true);
      let fd = new FormData();
      fd.append("file", newPDF);
      API.uploadImage(fd).then((res) => {
        setPdfIsUploading(false);
        const index = res.data.lastIndexOf(".");
        const pdf = res.data.substr(0, index) + ".pdf";
        setURL(res.data);
        dispatch(
          addPDF({
            url: pdf,
          })
        );
        setTimeout(() => {}, 2000);
      });
    }
  };

  if (back) {
    return back;
  }

  if (isReady) {
    return (
      <div>
        <div className="row d-flex justify-content-around my-5">
          <button className="email-back-button mb-3" onClick={handlePdfBack}>
            <i className="fas fa-arrow-left mr-2"></i>
            Back
          </button>
          <button
            className="restart-proposal-button"
            onClick={handleNewProposal}
          >
            Restart Proposal <i className="fas fa-redo"></i>
          </button>
          <button className="combine-projects-button" onClick={handleRestart}>
            Combine <br></br> Projects{" "}
            <i className="fas fa-plus-square ml-1"></i>
          </button>
        </div>
        <div className="row d-flex justify-content-center mt-3">
          {!uploadedPdf ? (
            <PDFViewer style={styles.pdf}>
              <ComponentToPrint
                store={store}
                materials={materials}
                dataMaterials={dataMaterials}
                phone={number}
                title={title}
              />
            </PDFViewer>
          ) : (
            <iframe
              title="Custom PDF"
              src={uploadedPdf?.url.replace("http", "https")}
              style={{ height: "100vh", width: "60vw" }}
            ></iframe>
          )}
        </div>
        <div className="row d-flex justify-content-center my-3">
          <button
            // className="btn btn-lg btn-info ml-5"
            className="send-email-button"
            // style={styles.button}
            onClick={handleSendEmail}
          >
            Send Email <i className="fas fa-envelope"></i>
          </button>

          <PDFDownload PDF={doc} store={store} />

          <label htmlFor="file-upload" className="upload-pdf-label ml-5">
            {pdfIsUploading ? (
              <span>Loading...</span>
            ) : (
              <span>
                Upload Custom PDF <i className="fas fa-upload"></i>
              </span>
            )}
            <input
              hidden={true}
              id="file-upload"
              name="file-upload"
              type="file"
              accept="application/pdf"
              onChange={(e) => {
                handleUpload(e.target.files[0]);
              }}
            ></input>
          </label>

          {/* <input
            hidden={true}
            id='file-upload'
            name='file-upload'
            type='file'
            accept='application/pdf'
            onChange={(e) => {
              handleUpload(e.target.files[0]);
            }}
          ></input> */}
        </div>
      </div>
    );
  }

  if (email) {
    return (
      <>
        {uploadedPdf ? (
          uploadedPdf?.url && (
            <EmailForm
              handleBack={handleEmailBack}
              url={uploadedPdf?.url}
              title={title}
              descriptions={materials}
            />
          )
        ) : (
          <EmailForm
            handleBack={handleEmailBack}
            url={url}
            title={title}
            descriptions={materials}
          />
        )}
      </>
    );
  }

  if (restart) {
    return <WorkProposal />;
  }

  return (
    <div>
      <div className="container my-5">
        <h1 className="text-center">Options</h1>
        <div>
          {options.map((option, i) =>
            option.single ? (
              <div>
                <SingleOption
                  key={option.option}
                  option={option.option}
                  measurement={option.measurement}
                  description={option.description}
                  state={matOp}
                  setState={setMatOp}
                  i={i}
                  price={option.price}
                />
              </div>
            ) : (
              <div>
                <OptionInputs
                  key={option.option}
                  option={option.option}
                  measurement={option.measurement}
                  description={option.description}
                  state={option.state}
                  setState={option.setState}
                  i={i}
                  price={option.price}
                />
              </div>
            )
          )}
          <Miscellaneous />
        </div>
      </div>
      <div className="row d-flex justify-content-around">
        <button className="PDF-back-button mb-3" onClick={handleBack}>
          <i className="fas fa-arrow-left mr-2"></i>
          Back
        </button>
        <button className="PDF-generate-button mb-3" onClick={handlePDF}>
          Generate PDF
        </button>
      </div>
    </div>
  );
}

export default Options;
