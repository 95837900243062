import React from 'react';

function ClientDone() {
  return (
    <div className='m-5' style={{ textAlign: 'center' }}>
      <h2>Thank you! You may now leave the site.</h2>
    </div>
  );
}

export default ClientDone;
