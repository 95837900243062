import React, { useState, useEffect } from "react";
import "../Global.css";

function BaseMaterialType({
  type,
  material,
  image,
  setMaterial,
}) {
  // Variables set to the bootstrap code for the color that the button should display
  let unClicked = "btn-secondary";
  let clicked = "btn-primary";

  // State that holds one of the two variables above
  const [color, setColor] = useState(unClicked);

  // Function that handles the selection of a base material
  const handleType = () => {
    // If the clicked material is already set to the 'material' state, it will remove the material and hide the next component until another material is selected
    if (material === type) {
      setMaterial(null);
    } else {
      // Sets the current material to the 'material' state
      setMaterial(type);
    }
  };

  // Function that determines what color the current button should display
  useEffect(() => {
    if (material === type) {
      setColor(clicked);
    } else {
      setColor(unClicked);
    }
  }, [material]);

  const styles = {
    button: {
      height: "75px",
      width: "200px",
      fontSize: "30px",
    },
    image: {
      backgroundImage: `url(${image})`,
      height: "40vh",
      backgroundSize: "cover",
      borderRadius: "10px",
      cursor: "pointer",
    },
  };

  return (
    <div
      className="col-12 col-lg-5 mx-2 my-2 d-flex justify-content-center shadowhover image-hover"
      style={styles.image}
      onClick={handleType}
    >
      <button
        style={styles.button}
        className={"btnshadow my-3 align-self-end btn " + color}
        onClick={handleType}
      >
        {type}
      </button>
    </div>
  );
}

export default BaseMaterialType;
