import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TextField } from "@material-ui/core";
import { updateSelection } from "../../redux/actions/CurrentSelections";
import API from "../../utils/API";

function EditDescription({ title, selection, objKey }) {
  const [description, setDescription] = useState(selection.description);
  const [variableMeasurement, setVariableMeasurement] = useState(
    selection.variableMeasurement
  );
  const dispatch = useDispatch();
  const selections = useSelector((state) => state.currentSelections.selections);

  const setBeginning = (value) => {
    let temp = [...description];
    temp[0] = value;
    setDescription(temp);
  };

  const setEnd = (value) => {
    let temp = [...description];
    temp[1] = value;
    setDescription(temp);
  };

  const updateReduxSelection = () => {
    if (description.length > 2 && Array.isArray(description)) {
      setDescription(description.slice(0, 2));
    }
    if (!variableMeasurement && Array.isArray(description)) {
      description.join("");
    }
    const payload = {
      variableMeasurement: variableMeasurement,
      description: description,
      target: objKey,
    };
    dispatch(updateSelection(payload));
    API.updateSelections(selections);
  };

  useEffect(() => {
    if (description.length > 2 && Array.isArray(description)) {
      setDescription(description.slice(0, 2));
    }
  }, [description]);

  return (
    <div>
      <h1>{title}</h1>
      {variableMeasurement === true ? (
        <div className="container">
          <div className="row">
            <TextField
              className="col-5"
              variant="outlined"
              value={description[0]}
              multiline
              onChange={(e) => setBeginning(e.target.value)}
            />
            <div className="col-2 text-center">*measurement*</div>
            <TextField
              className="col-5"
              variant="outlined"
              value={description[1]}
              multiline
              onChange={(e) => setEnd(e.target.value)}
            />
          </div>
          <div className="row">
            <button
              className="btn-danger col-6"
              onClick={() => setVariableMeasurement(false)}
            >
              Remove Variable Measurement
            </button>
            <button
              className="btn-primary col-6"
              onClick={() => updateReduxSelection()}
            >
              Update {title}
            </button>
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="row">
            <TextField
              className="col-12"
              variant="outlined"
              value={description}
              multiline
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div className="row">
            <button
              className="btn-success col-6"
              onClick={() => setVariableMeasurement(true)}
            >
              Add Variable Measurement
            </button>
            <button
              className="btn-primary col-6"
              onClick={() => updateReduxSelection()}
            >
              Update {title}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default EditDescription;
