export const setFormDetails = (payload) => {
  return { type: 'SET_FORM_DETAILS', payload };
};

export const setChangeOrder = (payload) => {
  return { type: 'SET_CHANGE_ORDER', payload };
};

export const deleteChangeOrder = () => {
  return { type: 'DELETE_CHANGE_ORDER' };
};

export const addPrivateComments = (payload) => {
  return { type: 'ADD_PRIVATE_COMMENTS', payload };
};

export const addImage = (payload) => {
  return { type: 'ADD_IMAGE', payload };
};

export const addPDF = (payload) => {
  return { type: 'ADD_PDF', payload };
};

export const deleteImage = (payload) => {
  return { type: 'DELETE_IMAGE', payload };
};

export const adjustPrice = (payload) => {
  return { type: 'ADJUST_PRICE', payload };
};

export const addCommission = (payload) => {
  return { type: 'ADD_COMMISSION', payload };
};

export const overridePrice = (payload) => {
  return { type: 'OVERRIDE_PRICE', payload };
};

export const addCustomTitle = (payload) => {
  return { type: 'ADD_CUSTOM_TITLE', payload };
};

export const showErrorModal = (payload) => {
  return { type: 'SHOW_ERROR', payload };
};

export const addAnotherProposal = (payload) => {
  return { type: 'ADD_ANOTHER_PROPOSAL', payload };
};

export const formRestart = (payload) => {
  return { type: 'FORM_RESTART', payload: null };
};
