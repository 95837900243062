import React from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";

function PDFDownload({ PDF, store }) {
  return (
    <PDFDownloadLink
      document={PDF}
      fileName={`${store.formDetails.info.address.replace(/ /g, "-")}-${
        store.formDetails.info.date
      }`}
    >
      {({ blob, url, loading, error }) =>
        loading ? (
          <button className="download-pdf-button ml-5" disabled>
            Loading... <i className="fas fa-download"></i>
          </button>
        ) : (
          <button className="download-pdf-button ml-5">
            Download Generated PDF <i className="fas fa-download"></i>
          </button>
        )
      }
    </PDFDownloadLink>
  );
}

export default PDFDownload;
