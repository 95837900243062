import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import API from '../../utils/API';
import { setChangeOrder } from '../../redux/actions/FormActions';

const LogInfoModal = ({ show, handleClose, proposal }) => {
  const dispatch = useDispatch();
  const proposalId = proposal._id;

  const handleChangeOrder = () => {
    API.proposalDetails(proposalId).then((res) => {
      const proposalData = { proposalData: res.data.data };
      dispatch(setChangeOrder(proposalData));
    });
  };

  return (
    <Modal centered size='md' show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {proposal.firstName} {proposal.lastName} / {proposal.email}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Project Classification: {proposal.projectClassification}</p>
        <p>Roof Type: {proposal.roofType}</p>
        <p>Base Material: {proposal.baseMaterial}</p>
        <p>Roofing Material: {proposal.roofingMaterial}</p>
        <p>Color: {proposal.color}</p>
        <div style={{ textAlign: 'center' }}>
          <br />
          <a href={proposal.contractPDF} target='_blank' rel='noreferrer'>
            View PDF
          </a>
          <br />
          <img
            src={
              proposal.contractPDF.substr(
                0,
                proposal.contractPDF.lastIndexOf('.')
              ) + '.png'
            }
            alt='estimate.pdf'
            width='90%'
          />
          {proposal.changeOrders
            ? proposal.changeOrders.map((order) => (
                <div>
                  <a href={order.url} target='_blank' rel='noreferrer'>
                    View PDF
                  </a>
                  <br />
                  <img
                    src={order.url.substr(0, order.url.lastIndexOf('.')) + '.png'}
                    alt='estimate.pdf'
                    width='90%'
                  />
                </div>
              ))
            : null}
          <button className='mt-3' onClick={handleChangeOrder}>
            Create Change Order
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LogInfoModal;
