import React, { useState, useEffect, useRef } from "react";
import FlashingMetal from "../FlashingMetal";
import { useDispatch, useSelector } from "react-redux";
import { setPerimFlashMaterial } from "../../redux/actions/MaterialsActions";
import InputModal from "./InputModal";

function OptionInputs({
  option,
  measurement,
  description,
  state,
  setState,
  i,
  price,
}) {
  // Connection which allows this component to send information to redux
  const dispatch = useDispatch();

  const { allOptions, constructionSquares } = useSelector((state) => state.constructionOptions);
  const { error } = useSelector((state) => state.formDetails);

  // State that holds the current options information
  const [input, setInput] = useState({
    type: option,
    measurementType: measurement,
    measure: "",
    description,
    price,
  });

  // States that holds the color, font-weight, and background color of this option
  const [color, setColor] = useState("black");
  const [fontweight, setFontweight] = useState("normal");
  const [bgcolor, setBgcolor] = useState("none");

  // State that determines if the FlashingMetal component should be revealed or not
  const [flashMetal, setFlashMetal] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [inputColor, setInputColor] = useState("white");

  // Refs to both the checkbox and text input for this option
  const checkbox = useRef();
  const textInput = useRef();

  useEffect(() => {
    if (allOptions) {
      allOptions.forEach((material) => {
        if (material.type === option) {
          setInput(material);
          checkbox.current.checked = true;
        }
      });
    }
  }, []);

  // Function that handles selecting an option
  const handleCheck = () => {
    if (!state || state.type !== option) {
      setState(input);
    } else {
      setState(null);
    };
    // checkbox.current.checked = !checkbox.current.checked;
  };

  // Function that handles disabling options if necessary
  useEffect(() => {
    if (state) {
      if (state.type !== option) {
        setColor("gray");
        setFontweight("normal");
        checkbox.current.checked = false;
        checkbox.current.disabled = true;
        textInput.current.disabled = true;
        setInput({ ...input, measure: "" });
      } else {
        setColor("black");
        setFontweight("bold");
        textInput.current.disabled = false;
        checkbox.current.disabled = false;
      }
    } else {
      setColor("black");
      setFontweight("normal");
      textInput.current.disabled = false;
      checkbox.current.disabled = false;
    }
  }, [state]);

  // Function that updates the state whenever typing into the textbox
  useEffect(() => {
    if (input.measure !== "") {
      checkbox.current.checked = true;
      handleCheck();
      if (inputColor === "red") {
        setInputColor("white");
      }
    }
    if (state) {
      if (state.type === option) {
        setState(input);
      }
    }
  }, [input]);

  // Function that checks if the index of this option is even or not, and changes the background color accordingly
  useEffect(() => {
    if (i % 2 === 0) {
      setBgcolor("#DEDEDE");
    }
  }, []);

  useEffect(() => {
    if (error) {
      if (state) {
        if (state.type === option) {
          if (input.measure === "" || input.measure <= 0) {
            setInputColor("red");
          }
        }
      }
    }
  }, [error]);

  // Function that determines if the FlashingMetal component should be revealed or not
  useEffect(() => {
    const flash = [
      '2" Perimeter Flashing',
      '3" Perimeter Flashing',
      "Custom Perimeter Flashing",
    ];
    if (checkbox.current.checked === true) {
      if (flash.includes(option)) {
        setFlashMetal(true);
      }
      if (measurement === "Area") {
        setInput({ ...input, measure: +constructionSquares });
      }
      // handleShow();
    } else {
      setFlashMetal(false);
      dispatch(setPerimFlashMaterial(null));
      setInput({ ...input, measure: "" });
      if (inputColor === "red") {
        setInputColor("white");
      }
    }
  }, [checkbox?.current?.checked]);

  const styles = {
    check: {
      marginTop: "34px",
      zIndex: 2
    },
    text: {
      color: color,
      fontWeight: `${fontweight}`,
      backgroundColor: `${bgcolor}`,
      fontSize: "30px",
    },
  };

  return (
    <div className="row align-items-center pl-lg-1" style={styles.text}>
      <div className="col-12 col-lg-7">
        <div className="row d-flex justify-content-center justify-content-lg-start">
          {/* <div className="col-1 d-flex justify-content-start"> */}
            <input
              type="checkbox"
              className="mr-2"
              ref={checkbox}
              style={styles.check}
              onChange={handleCheck}
            />
          {/* </div> */}
          <div className="col-11 ml-n3 d-flex justify-content-start">
            <p className="mt-3 mr-lg-5">{option}</p>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-5">
        <div className="row d-flex justify-content-center justify-content-lg-end">
          <label className="mt-1 mt-lg-2 mr-1">{measurement}: </label>
          <input
            type="number"
            min="0"
            className="mb-3 mb-lg-0"
            style={{ backgroundColor: inputColor }}
            ref={textInput}
            value={input.measure}
            onChange={(e) => setInput({ ...input, measure: e.target.value })}
          />
        </div>
      </div>
      <InputModal
        show={show}
        handleClose={handleClose}
        title={option}
        input={input}
        setInput={setInput}
        label={measurement}
      />
      {/* The FlashingMetal component is only revealed if the flashMetal state is set to true */}
      {flashMetal ? <FlashingMetal checked={flashMetal} /> : null}
    </div>
  );
}

export default OptionInputs;
