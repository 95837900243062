import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { useSelector, useDispatch } from 'react-redux';
import PriceTiers from '../Hooks/PriceTiers';
import {
  addCommission,
  addCustomTitle,
  addPrivateComments,
  adjustPrice,
  deleteImage,
  overridePrice,
} from '../redux/actions/FormActions';
import {
  getTotal,
  setDescriptions,
} from '../redux/actions/ConstructionOptions';
import AdminInput from './SubComponent/AdminInput';

function AdminModal({ show, handleClose }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    privateComments,
    priceAdjustment,
    priceOverride,
    employeeCommission,
    images,
    customTitle,
  } = useSelector((state) => state.formDetails);
  const {
    constructionSquares,
    allOptions,
    miscComments,
    demoSquares,
    layers,
    pitch,
    area,
  } = useSelector((state) => state.constructionOptions);

  const [priceInfo, setPriceInfo] = useState(null);
  const [priceLabels, setPriceLabels] = useState([]);
  const [optionsInfo, setOptionsInfo] = useState(null);
  const [adjust, setAdjust] = useState(0);
  const [override, setOverride] = useState(0);
  const [commission, setCommission] = useState(0);
  const [commissionPreview, setCommissionPreview] = useState(0);
  const [title, setTitle] = useState('');
  const [superAdmin, setSuperAdmin] = useState(false);

  useEffect(() => {
    if (priceInfo) {
      let percent = +commission * 0.01;
      let commissionTotal = +priceInfo.Total * percent;
      setCommissionPreview(commissionTotal.toFixed(2));
    }
  }, [commission, priceInfo]);

  useEffect(() => {
    const prices = PriceTiers(
      +constructionSquares,
      allOptions,
      miscComments,
      demoSquares,
      layers,
      pitch,
      area,
      priceAdjustment,
      priceOverride,
      employeeCommission
    );
    const labels = Object.keys(prices).filter((item) => !isNaN(prices[item]));
    setPriceInfo(prices);
    //     const descriptions = allOptions?.map((option) => ({
    //       description: option.description,
    //       type: option.type,
    //     }));
    setOptionsInfo(allOptions);
    setPriceLabels(labels);
  }, [
    constructionSquares,
    allOptions,
    miscComments,
    demoSquares,
    layers,
    pitch,
    area,
    priceAdjustment,
    priceOverride,
    employeeCommission,
  ]);

  const handleDelete = (comment) => {
    const newComments = privateComments.filter(
      (current) => current !== comment
    );
    if (newComments.length > 0) {
      dispatch(addPrivateComments(newComments));
    } else {
      dispatch(addPrivateComments(null));
    }
  };

  const handleUpdateTotal = () => {
    dispatch(getTotal(priceInfo));
    dispatch(setDescriptions(optionsInfo));
    alert('Proposal Updated.');
  };

  const handlePriceAdjust = () => {
    dispatch(adjustPrice(+adjust));
    setAdjust(0);
  };

  const handleOverride = () => {
    dispatch(overridePrice(+override));
    setOverride(0);
  };

  const handleCommission = () => {
    if (commissionPreview > 0) {
      dispatch(addCommission(+commissionPreview));
      setCommission(0);
    }
  };

  const handleCustomTitle = () => {
    dispatch(addCustomTitle(title.toUpperCase()));
    setTitle('');
  };

  // const checkSuperAdmin = () => {
  //   let token = JSON.parse(atob(localStorage.getItem('token').split('.')[1]));

  //   if (token.role === 1) {
  //     setSuperAdmin(true);
  //   } else {
  //     setSuperAdmin(false);
  //   }
  // };

  // useEffect(() => {
  //   checkSuperAdmin();
  // }, []);

  const styles = {
    delete: {
      cursor: 'pointer',
    },
    input: {
      width: '100px',
      height: '40px',
      fontSize: '20px',
    },
  };

  return (
    <Modal centered show={show} onHide={() => handleClose(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Admin</Modal.Title>
      </Modal.Header>
      {privateComments?.length > 0 ? (
        <h4 className='text-center'>Private Comments</h4>
      ) : null}
      <div className='m-2'>
        <ul className='text-center list-unstyled'>
          {privateComments?.map((comment) => (
            <li key={comment}>
              {comment}{' '}
              <span
                className='float-right fas fa-times-circle delete'
                style={styles.delete}
                onClick={() => handleDelete(comment)}
              />
            </li>
          ))}
        </ul>
      </div>
      {priceInfo ? <h4 className='text-center'>Price Info</h4> : null}
      <div className='mx-2'>
        <ul className='text-center list-unstyled'>
          {priceLabels?.map((item) => (
            <li key={item}>
              {item === 'Total' ||
              item === 'PriceOverride' ||
              item === 'Employee Commission' ? (
                `${item}: $${priceInfo[item].toFixed(2)} `
              ) : (
                <AdminInput
                  itemName={item}
                  initialPrice={priceInfo[item].toFixed(2)}
                  initialDescription={optionsInfo?.find(
                    (option) => option.type === item
                  )}
                  array={priceInfo}
                  optionsArray={optionsInfo}
                />
              )}
              {item === 'PriceOverride' ? (
                <span
                  className='float-right fas fa-times-circle delete'
                  style={styles.delete}
                  onClick={() => dispatch(overridePrice(0))}
                />
              ) : item === 'Employee Commission' ? (
                <span
                  className='float-right fas fa-times-circle delete'
                  style={styles.delete}
                  onClick={() => dispatch(addCommission(0))}
                />
              ) : null}
            </li>
          ))}
          <button className='mt-4' onClick={handleUpdateTotal}>
            Update
          </button>
        </ul>
      </div>
      <div className='row d-flex justify-content-center align-items-center my-2 mx-2'>
        <p style={{ fontSize: '20px' }}>Commission Percentage: %</p>
        <input
          type='number'
          className='mb-3'
          style={styles.input}
          value={commission}
          onChange={(e) => setCommission(e.target.value)}
          // onChange={(e) => setOverride(e.target.value)}
        />
        <button className='btn btn-dark ml-3 mb-3' onClick={handleCommission}>
          Submit
        </button>
      </div>
      {commissionPreview > 0 ? (
        <p className='text-center'>Commission: ${commissionPreview}</p>
      ) : null}
      <div className='row d-flex justify-content-center align-items-center'>
        <p style={{ fontSize: '20px' }}>Price Override: $</p>
        <input
          type='number'
          className='mb-3'
          style={styles.input}
          value={override}
          onChange={(e) => setOverride(e.target.value)}
        />
        <button className='btn btn-dark ml-3 mb-3' onClick={handleOverride}>
          Submit
        </button>
      </div>
      <p
        style={{
          fontSize: '20px',
          textAlign: 'center',
          borderTop: '1px solid lightgray',
        }}
        className='pt-2'
      >
        Enter Custom Proposal Title:{' '}
      </p>
      <div className='row d-flex justify-content-center align-items-center'>
        <input
          type='text'
          className='mb-3'
          style={{ width: '50%' }}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <button className='btn btn-dark ml-3 mb-3' onClick={handleCustomTitle}>
          Submit
        </button>
      </div>
      {/* {superAdmin ? (
        <div className='row d-flex justify-content-center align-items-center'>
          <button
            className='btn btn-danger ml-3 mb-3'
            onClick={handleAdminMode}
          >
            Admin Mode
          </button>
        </div>
      ) : null} */}
      {customTitle ? (
        <div style={{ width: '90%', margin: '0 auto' }}>
          <p style={{ fontSize: '16px', textAlign: 'center' }}>Custom Title:</p>
          <p className='text-center'>
            {customTitle}
            <span
              className='float-right fas fa-times-circle delete'
              style={styles.delete}
              onClick={() => dispatch(addCustomTitle(''))}
            />
          </p>
        </div>
      ) : null}
      {images ? (
        <h4
          className='text-center mb-3 pt-2'
          style={{ borderTop: '1px solid lightgray' }}
        >
          Images
        </h4>
      ) : null}
      {images
        ? images.map((image) => (
            <div key={image}>
              <p className='text-center mb-1'>{image.description}</p>
              <div className='row d-flex justify-content-center mb-1'>
                <img
                  src={image.url.substr(0, image.url.lastIndexOf('.')) + '.png'}
                  style={{ width: '80%' }}
                  alt='uploaded_img'
                />
              </div>
              <div className='row d-flex justify-content-center'>
                <button
                  className='btn btn-danger'
                  style={{ transform: 'scale(.8)' }}
                  onClick={() => dispatch(deleteImage(image.url))}
                >
                  Delete Image
                </button>
              </div>
              <hr />
            </div>
          ))
        : null}
    </Modal>
  );
}

export default AdminModal;
